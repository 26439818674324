import { ProductCards } from "components";
import { BottomShutter } from "core";
import CarComparisonCardStyle from "css/components/CarComparisonCard.module.css";
import { useAppContext, useIsMounted, useUsedCarList } from "hooks";
import useNewCarList from "hooks/useNewCarList";
import CarSearchSection from "pages/compareCars/CarSearchSection";
import { useCallback, useEffect, useState } from "react";

const CarComparisonCard = ({
  selectionCarType,
  data,
  comparePosition,
  onSelect,
  compareCarsData,
}) => {
  const [openShutter, setOpenShutter] = useState(false);
  const IsMounted = useIsMounted();
  const [carList, setCarList] = useState(null);
  const { setAlertMessage } = useAppContext();
  const { newCarList } = useNewCarList();
  const { usedCarList } = useUsedCarList();

  const handelCarSelection = () => {
    if (selectionCarType) {
      IsMounted.current && setOpenShutter(true);
    } else {
      IsMounted.current &&
        setAlertMessage({
          type: "info",
          message: "Please Select Car Comparison Type!",
        });
    }
  };

  //get new Car List From DataBase
  const getNewCarList = useCallback(async () => {
    if (selectionCarType?.toUpperCase() === "NEW") {
      IsMounted.current && setCarList(newCarList);
    } else if (selectionCarType?.toUpperCase() === "USED") {
      IsMounted.current && setCarList(usedCarList);
    }
  }, [IsMounted, newCarList, selectionCarType, usedCarList]);

  useEffect(() => {
    IsMounted.current && getNewCarList();
  }, [IsMounted, getNewCarList]);

  if (compareCarsData) {
    return (
      <ProductCards
        type={2}
        carType={selectionCarType?.toLowerCase()}
        id={compareCarsData?.id}
        name={`${
          compareCarsData?.CarInformationData?.brand ||
          compareCarsData?.carDetails?.brand
        } ${
          compareCarsData?.CarInformationData?.model ||
          compareCarsData?.carDetails?.model
        } ${
          compareCarsData?.CarInformationData?.variant ||
          compareCarsData?.carDetails?.variant
        }`}
        image={
          compareCarsData?.CarImagesFormData?.rightFrontCornerImage?.url ||
          compareCarsData?.CarImagesFormData?.rightFrontCornerImage?.uri ||
          compareCarsData?.evaluateFrontImages?.uri
        }
        price={
          compareCarsData?.PriceFormData?.price ||
          compareCarsData?.priceDetails?.salesPrice
        }
        mileage={compareCarsData?.FuelAndPerformanceFormData?.mileage}
        fuelType={compareCarsData?.FuelAndPerformanceFormData?.fuelType}
        transmission={
          compareCarsData?.EngineAndTransmissionFormData?.transmissionType
        }
        carHubLocation={compareCarsData?.priceDetails?.carHubLocation}
      />
    );
  } else {
    return (
      <>
        <div
          className={CarComparisonCardStyle.container}
          onClick={handelCarSelection}
        >
          <span className="FCGP05 TCP">
            <span
              className={`material-icons-outlined ${CarComparisonCardStyle.addIcon}`}
            >
              add
            </span>
            Add {selectionCarType} Car
          </span>
          <button className={CarComparisonCardStyle.BtnSkeleton}>
            Select Car
          </button>
        </div>
        <BottomShutter
          IsOpen={openShutter}
          closeFC={() => setOpenShutter(false)}
        >
          <CarSearchSection
            data={carList}
            comparePosition={comparePosition}
            onSelect={onSelect}
          />
        </BottomShutter>
      </>
    );
  }
};

export default CarComparisonCard;
