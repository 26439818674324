import { useEffect, useRef } from "react";

const useIsMounted = () => {
  const IsMounted = useRef(false);
  useEffect(() => {
    IsMounted.current = true;
    return () => (IsMounted.current = false);
  }, []);
  return IsMounted;
};

export default useIsMounted;
