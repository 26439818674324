import EmptyPageStyle from "css/core/EmptyPage.module.css";

const EmptyPage = ({ image, children, link }) => {
  return (
    <div className={EmptyPageStyle.container}>
      <div
        className={EmptyPageStyle.ImageSection}
        style={{ backgroundImage: `url(${image})` }}
      ></div>
      {children}
    </div>
  );
};

export default EmptyPage;
