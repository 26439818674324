import { Base } from "layout";
import RegistrationPageStyle from "css/pages/financePage/FinancePage.module.css";
import { InfoGatherForm } from "forms";

const RegistrationPage = () => {
  return (
    <Base>
      <div className={RegistrationPageStyle.Warper}>
        <div className={RegistrationPageStyle.container}>
          <div
            className={RegistrationPageStyle.imageSection}
            style={{
              backgroundImage: `url(https://images.unsplash.com/photo-1626630067884-85a126adb6b6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80)`,
            }}
          >
            <h1 className={`${RegistrationPageStyle.title}`}>
              Registration <br /> Enquiry
            </h1>
          </div>
          <div className={RegistrationPageStyle.formSection}>
            <div className={RegistrationPageStyle.formWarper}>
              <h1 className={`STUL ${RegistrationPageStyle.formTitle}`}>
                Registration Enquiry
              </h1>
              <InfoGatherForm />
            </div>
          </div>
        </div>
      </div>
    </Base>
  );
};

export default RegistrationPage;
