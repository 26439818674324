import { WalkDreaming } from "assets";
import WishlistCard from "components/WishlistCard";
import { database } from "config";
import { EmptyPage } from "core";
import WishlistStyle from "css/pages/account/Wishlist.module.css";
import { useAllCars, useAppContext, useIsMounted } from "hooks";
import { useEffect, useState } from "react";
import AccountDetailViewSection from "../AccountDetailViewSection";

const BuyBooking = () => {
  const { user } = useAppContext();
  const { allCars } = useAllCars();
  const [isLoading, setIsLoading] = useState(true);
  const IsMounted = useIsMounted();
  const [myBookings, setMyBookings] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        await database.ref(`Users/${user.uid}`).once("value", (snapshot) => {
          const data = snapshot.val();
          if (data) {
            if (data?.Bookings) {
              const Bookings = Object.entries(data?.Bookings).map(
                (item) => item[1].carId
              );
              setMyBookings([]);
              Bookings.map((carsId) =>
                setMyBookings((prev) => [
                  ...prev,
                  ...allCars?.filter(
                    (item) => parseInt(carsId) === parseInt(item?.id)
                  ),
                ])
              );
            }
          }
        });
      } catch (error) {
        console.log(error);
      } finally {
        IsMounted.current && setIsLoading(false);
      }
    })();
    document.querySelector("#AccountDetailViewSection").scrollIntoView(true);
  }, [IsMounted, allCars, user]);
  return (
    <AccountDetailViewSection title="Buy Booking">
      {isLoading ? (
        <span className="loaderOneBlue"></span>
      ) : myBookings && myBookings.length ? (
        <div className={WishlistStyle.container}>
          {myBookings?.map((cars, index) => {
            return (
              <WishlistCard
                noAction={true}
                key={index}
                carType={cars?.type.toLowerCase()}
                id={cars.id}
                mobile={true}
                image={
                  cars?.CarImagesFormData?.rightFrontCornerImage?.url ||
                  cars?.CarImagesFormData?.rightFrontCornerImage?.uri ||
                  cars?.evaluateFrontImages?.uri
                }
                name={`${
                  cars?.CarInformationData?.brand || cars?.carDetails?.brand
                } ${
                  cars?.CarInformationData?.model || cars?.carDetails?.model
                } ${
                  cars?.CarInformationData?.variant || cars?.carDetails?.variant
                }`}
                price={
                  cars?.PriceFormData?.price || cars?.priceDetails?.salesPrice
                }
                mileage={cars?.FuelAndPerformanceFormData?.mileage}
                kilometers={
                  cars?.carEvaluation && cars?.carEvaluation[0]?.kilometers
                }
                fuelType={cars?.FuelAndPerformanceFormData?.fuelType}
                transmission={
                  cars?.EngineAndTransmissionFormData?.transmissionType
                }
              />
            );
          })}
        </div>
      ) : (
        <EmptyPage image={WalkDreaming}>No Booking Is Done</EmptyPage>
      )}
    </AccountDetailViewSection>
  );
};

export default BuyBooking;
