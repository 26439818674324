import { useEffect } from "react";
import AccountDetailViewSection from "../AccountDetailViewSection";
import { AccountForm } from "forms";

const AccountInformation = () => {
  useEffect(() => {
    document.querySelector("#AccountDetailViewSection").scrollIntoView(true);
  });
  return (
    <AccountDetailViewSection title="Account Information">
      <AccountForm />
    </AccountDetailViewSection>
  );
};

export default AccountInformation;
