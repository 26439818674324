import { useCallback, useEffect } from "react";

const useMetaData = ({ title, description, image, url }) => {
  const setMetaInfo = useCallback((key, value) => {
    if (document.querySelector(`meta[property="og:${key}"]`)) {
      document.querySelector(`meta[property="og:${key}"]`).content = value;
    } else {
      const meta = document.createElement("meta");
      meta.property = `og:${key}`;
      meta.content = value;
      document.head.appendChild(meta);
    }
  }, []);

  useEffect(() => {
    title && (document.querySelector("title").innerText = title);
    description && setMetaInfo("description", description);
    image && setMetaInfo("image", image);
    url && setMetaInfo("url", url);
    //TODO: remove all meta on unMounted
    return () => {
      document.querySelector("title").innerText = "CarKarobar";
    };
  }, [description, image, setMetaInfo, title, url]);

  return { setMetaInfo };
};

export default useMetaData;
