import DashboardStyle from "css/pages/admin/dashboard/Dashboard.module.css";
import BuySellCars from "./BuySellCars";
import CardSection from "./CardSection";
import StatusCardSection from "./StatusCardSection";

const Dashboard = () => {
  return (
    <div className={DashboardStyle.container}>
      <CardSection />
      <StatusCardSection />
      <BuySellCars />
    </div>
  );
};

export default Dashboard;
