import { database } from "config";
import { useEffect, useRef, useState } from "react";

const useNewCarList = (ref) => {
  const IsMounted = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [newCarList, setNewCarList] = useState(null);

  useEffect(() => {
    IsMounted.current = true;
    IsMounted.current &&
      database.ref(ref || `NewCars`).once("value", (snapshot) => {
        const data = snapshot.val();
        if (data && IsMounted.current) {
          setNewCarList(Object.entries(data).map((item) => item[1]));
          setIsLoading(false);
        }
      });
    return () => (IsMounted.current = false);
  }, [ref]);

  return { newCarList, isLoading };
};

export default useNewCarList;
