import { database } from "config";
import { Button, InputField } from "core";
import CarBookFormStyle from "css/components/Signin.module.css";
import { ErrorMessage, Field, Formik } from "formik";
import { useAppContext, useIsMounted, useMail } from "hooks";
import { useEffect, useState } from "react";
import * as Yup from "yup";

const CarBookForm = ({ postSubmit, carId, storeId }) => {
  const { user, setAlertMessage } = useAppContext();
  const IsMounted = useIsMounted();
  const { sendMail } = useMail();
  const [carInfo, setCarInfo] = useState(null);
  const initialState = {
    name: user?.name || "",
    email: user?.email || "",
    phoneNumber: user?.phoneNumber || "",
    address: "",
    quotedPrice: "",
  };

  const [isLoading, setIsLoading] = useState(false);

  const fieldList = [
    {
      name: "name",
      fieldName: "name",
      type: "text",
      title: "please enter your name",
      placeholder: "Enter name",
    },
    {
      name: "email",
      fieldName: "email",
      type: "email",
      title: "please enter your email",
      placeholder: "Enter email",
    },
    {
      name: "phone Number",
      fieldName: "phoneNumber",
      type: "number",
      title: "please enter your phone Number",
      placeholder: "Enter phone Number",
    },
    {
      name: "address",
      fieldName: "address",
      type: "textfield",
      title: "please enter your address",
      placeholder: "Enter address",
    },
    {
      name: "Quoted Price",
      fieldName: "quotedPrice",
      type: "number",
      title: "please enter a quoted price",
      placeholder: "Enter a quoted price",
    },
  ];

  const CarBookFormSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "name must be more then 51 characters.")
      .max(60, "name must be less then2550 characters.")
      .required("name is required"),
    email: Yup.string()
      .email("please enter a valid email")
      .required("email is required"),
    phoneNumber: Yup.number("phone number must be type of number")
      .test(
        "minlength",
        "phone number must be 10 digits",
        (val) => !val || (val && val.toString().length === 10)
      )
      .required("Phone number is required"),
    address: Yup.string()
      .min(11, "address must be more then 11 characters.")
      .max(250, "address must be less then2550 characters.")
      .required("address is required"),
  });

  useEffect(() => {
    (async () => {
      IsMounted.current &&
        database
          .ref(`EvaluatedCars/${storeId}/${carId}`)
          .once("value", async (snapshot) => {
            const data = await snapshot.val();
            if (data) {
              IsMounted.current && setCarInfo(data);
            }
          });
    })();
  }, [IsMounted, carId, storeId]);

  const SubmitHandler = async (values, { resetForm }) => {
    IsMounted.current && setIsLoading(true);
    try {
      const ID = Date.now();
      const role = "B2C";
      const newBuyer = {
        name: values.name,
        email: values.email,
        phone: values.phoneNumber,
        car: carInfo?.carDetails?.brand,
        model: carInfo?.carDetails?.model,
        address: values?.address,
        quotedPrice: values?.quotedPrice,
        selectedCar: {
          brand: carInfo?.carDetails?.brand,
          id: carId,
          storeId: storeId,
          lastKms: {
            inventoryLastKms: carInfo?.carEvaluation[0]?.kilometers,
          },
          model: carInfo?.carDetails?.model,
          ownerDetails: { inventoryData: carInfo },
          // slno:,
          timestamp: new Date().getTime(),
          vehicleNumber: carInfo?.carDetails?.vehicleNumber,
        },
        role,
        id: ID,
        timeStamp: new Date().getTime(),
      };
      await database
        .ref(`Enquiry/${storeId}/${new Date().getTime()}`)
        .update({ newBuyer });
      const BookingID = Date.now();
      await database.ref(`Users/${user.uid}/Bookings/${BookingID}`).set({
        carId: carId,
        storeId: storeId || "",
        status: "INFO SEND",
        timestamp: Date(),
        id: BookingID,
      });
      IsMounted.current &&
        sendMail({
          to: user.email,
          subject: `Enquire #${ID} Is confirmed`,
          content: `
        your enquire is confirmed to view your enquires visit https://carkarobar.netlify.app/account/booking`,
        });
      IsMounted.current &&
        setAlertMessage({
          type: "info",
          message: "form submitted successfully",
        });
      IsMounted.current && postSubmit && postSubmit();
    } catch (error) {
      console.log(error);
      IsMounted.current && setAlertMessage({ type: "error", message: error });
    } finally {
      resetForm(initialState);
      IsMounted.current && setIsLoading(false);
    }
  };

  return (
    <div className={CarBookFormStyle.container}>
      <Formik
        initialValues={initialState}
        validationSchema={CarBookFormSchema}
        onSubmit={SubmitHandler}
      >
        {({
          values,
          errors,
          touched,
          setTouched,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <h1 className={CarBookFormStyle.title}>Enter details</h1>
            {fieldList.map((field, index) => {
              return (
                <Field name={field.fieldName} key={index}>
                  {(props) => (
                    <InputField
                      {...props.field}
                      name={field.name}
                      title={field.title}
                      type={field.type}
                      onError={
                        touched[field.fieldName] && errors[field.fieldName]
                      }
                      fieldName={field.fieldName}
                      Value={values[field.fieldName]}
                      onChange={(e) => {
                        handleChange(e);
                        setTouched({ ...touched, [field.fieldName]: true });
                      }}
                      placeholder={field.placeholder}
                      errorMessage={
                        <ErrorMessage
                          name={field.fieldName}
                          render={(msg) => <p className="EMS">{msg}</p>}
                        />
                      }
                    />
                  )}
                </Field>
              );
            })}
            <Button
              loading={isLoading}
              type="submit"
              style={{ width: "100%", margin: "0px" }}
            >
              Submit
            </Button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default CarBookForm;
