import { defaultAvatar } from "assets";
import { ProfileCard } from "components";
import AccountMenuSectionStyle from "css/pages/account/AccountMenuSection.module.css";
import { useAppContext } from "hooks";
import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

const AccountMenuSection = () => {
  const { user } = useAppContext();
  const location = useLocation();
  const [menuItems] = useState([
    {
      title: "Account Information",
      link: "account-information",
      logo: "account_circle",
    },
    { title: "Wishlist", link: "wishlist", logo: "favorite" },
    { title: "Sell Cars", link: "sell-cars", logo: "car_rental" },
    { title: "Booking", link: "booking", logo: "drive_eta" },
  ]);

  return (
    <div className={AccountMenuSectionStyle.container}>
      <ProfileCard
        avatar={user?.avatar?.imageUrl || defaultAvatar}
        name={user.name}
      />
      {menuItems.map((item, index) => (
        <NavLink
          to={item.link}
          key={index}
          className={({ isActive }) =>
            `${AccountMenuSectionStyle.menuItem} ${
              isActive && AccountMenuSectionStyle.active
            }`
          }
        >
          <span
            className={`material-icons-round ${AccountMenuSectionStyle.Icon}`}
          >
            {item.logo}
          </span>
          {item.title}
          <span
            className={`material-icons-round ${AccountMenuSectionStyle.Icon} ${
              location.pathname === `/account/${item.link}` &&
              AccountMenuSectionStyle.active
            }`}
          >
            expand_more
          </span>
        </NavLink>
      ))}
    </div>
  );
};

export default AccountMenuSection;
