import { ProgressIndication, Stepper } from "core";
import { useState } from "react";
import EditInventoryStyle from "css/pages/admin/inventory/Inventory.module.css";
import SellCarFormStyle from "css/pages/sellCar/SellCarForm.module.css";
import {
  DimensionsAndCapacityForm,
  FuelAndPerformanceForm,
  SuspensionAndSteeringAndBrakesForm,
  EngineAndTransmissionForm,
  ExteriorForm,
  SafetyForm,
  CarImagesForm,
  PriceForm,
  CarInformationForm,
} from "forms/sellCarForms";
import { useAppContext } from "hooks";
import { UploadImage } from "config";
import { database } from "config";

const EditInventory = ({ data, postSubmit }) => {
  const [presentStep, setPresentStep] = useState(0);

  const [formData, setFormData] = useState(data);

  const [IsLoading, setIsLoading] = useState(false);
  const { setAlertMessage } = useAppContext();
  const [progress, setProgress] = useState(0);

  const uploadForm = async (data) => {
    try {
      setIsLoading(true);
      const Id = data?.id;
      const locationRef = `NewCars/${Id}`;

      let CarImagesData = {};

      //Car Image Uploads 📷
      for (const image of Object.entries(data?.CarImagesFormData)) {
        if (!image[1]?.url) {
          let imageData = await UploadImage({
            dbReference: locationRef,
            file: image[1],
          });
          CarImagesData = {
            ...CarImagesData,
            [image[0]]: {
              imageRef: imageData.imageRef,
              url: imageData.imageUrl,
            },
          };
        } else {
          CarImagesData = {
            ...CarImagesData,
            [image[0]]: image[1],
          };
        }
        setProgress((prev) => prev + 1);
      }

      //storing Form Data On Database
      await database.ref(locationRef).update({
        DimensionsAndCapacityFormData: formData?.DimensionsAndCapacityFormData,
        EngineAndTransmissionFormData: formData?.EngineAndTransmissionFormData,
        ExteriorFormData: formData?.ExteriorFormData,
        FuelAndPerformanceFormData: formData?.FuelAndPerformanceFormData,
        PriceFormData: formData?.PriceFormData,
        SafetyFormData: formData?.SafetyFormData,
        SuspensionAndSteeringAndBrakesFormData:
          formData?.SuspensionAndSteeringAndBrakesFormData,
        CarInformationData: {
          ...formData?.CarInformationData,
          car_color: formData?.ExteriorFormData?.color || "",
        },
        carDetails: {
          brand: formData?.CarInformationData?.brand || "",
          color: formData?.ExteriorFormData?.color || "",
          model: formData?.CarInformationData?.model || "",
          variant: formData?.CarInformationData?.variant || "",
        },
        CarImagesFormData: CarImagesData || "",
        updatedAt: Date(),
      });

      setAlertMessage({
        type: "success",
        message: "Form Updated SuccessFully",
      });
      setPresentStep(0);
      setProgress(0);
    } catch (error) {
      console.log(error);
      setAlertMessage({ type: "error", message: error.message });
    } finally {
      setIsLoading(false);
      postSubmit && postSubmit();
    }
  };

  return (
    <div className={EditInventoryStyle.container}>
      <ProgressIndication totalStage={8} currentStage={presentStep} />
      <Stepper step={presentStep}>
        <CarInformationForm
          setPresentStep={setPresentStep}
          setFormData={setFormData}
          formData={formData}
          onNext={() => setPresentStep(1)}
        />
        <DimensionsAndCapacityForm
          setPresentStep={setPresentStep}
          setFormData={setFormData}
          formData={formData}
          onNext={() => setPresentStep(2)}
          onBack={() => setPresentStep(0)}
        />
        <FuelAndPerformanceForm
          setPresentStep={setPresentStep}
          setFormData={setFormData}
          formData={formData}
          onNext={() => setPresentStep(3)}
          onBack={() => setPresentStep(1)}
        />
        <SuspensionAndSteeringAndBrakesForm
          setPresentStep={setPresentStep}
          setFormData={setFormData}
          formData={formData}
          onNext={() => setPresentStep(4)}
          onBack={() => setPresentStep(2)}
        />
        <EngineAndTransmissionForm
          setPresentStep={setPresentStep}
          setFormData={setFormData}
          formData={formData}
          onNext={() => setPresentStep(5)}
          onBack={() => setPresentStep(3)}
        />
        <ExteriorForm
          setPresentStep={setPresentStep}
          setFormData={setFormData}
          formData={formData}
          onNext={() => setPresentStep(6)}
          onBack={() => setPresentStep(4)}
        />
        <SafetyForm
          setPresentStep={setPresentStep}
          setFormData={setFormData}
          formData={formData}
          onNext={() => setPresentStep(7)}
          onBack={() => setPresentStep(5)}
        />
        <PriceForm
          setPresentStep={setPresentStep}
          setFormData={setFormData}
          formData={formData}
          onNext={() => setPresentStep(8)}
          onBack={() => setPresentStep(6)}
        />
        <CarImagesForm
          setPresentStep={setPresentStep}
          setFormData={setFormData}
          formData={formData}
          IsLoading={IsLoading}
          onNext={(data) => {
            setPresentStep(9);
            uploadForm(data);
          }}
          onBack={() => setPresentStep(7)}
        />
      </Stepper>
      {!!progress && (
        <div className={SellCarFormStyle.LoadingProgress}>
          {parseInt((progress / 8) * 100)}%
        </div>
      )}
    </div>
  );
};

export default EditInventory;
