import { lazy } from "react";

export const SellCar = lazy(() => import("./sellCar"));
export const BuyCar = lazy(() => import("./buyCar"));
export const BuyCarByFilter = lazy(() =>
  import("./filterBuyCarPage/BuyCarByFilter")
);
export const NotFound = lazy(() => import("./NotFound"));
export const Faqs = lazy(() => import("./Faqs"));
export const Support = lazy(() => import("./Support"));
export const CompareCars = lazy(() => import("./compareCars/CompareCars"));
