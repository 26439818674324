export const hatchback = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 32">
    <use href="#bodyType_hatchback_icon">
      <symbol
        id="bodyType_hatchback_icon"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.69 15.49a6.11 6.11 0 00-6.11 6.11 6.11 6.11 0 006.11 6.11c3.38 0 6.11-2.73 6.11-6.11 0-3.38-2.73-6.11-6.11-6.11zm0 10.16c-2.24 0-4.06-1.82-4.06-4.06 0-2.24 1.82-4.06 4.06-4.06 2.24 0 4.06 1.82 4.06 4.06a4.05 4.05 0 01-4.06 4.06zM56.76 15.48a6.11 6.11 0 00-6.11 6.11 6.11 6.11 0 006.11 6.11c3.38 0 6.11-2.73 6.11-6.11a6.11 6.11 0 00-6.11-6.11zm0 10.16c-2.24 0-4.06-1.82-4.06-4.06 0-2.24 1.82-4.06 4.06-4.06 2.24 0 4.06 1.82 4.06 4.06 0 2.25-1.82 4.06-4.06 4.06z"
          fill="#DEDFE0"
        ></path>
        <path
          d="M32.17 29.13c16.25 0 29.43-.65 29.43-1.44 0-.8-13.17-1.44-29.43-1.44-16.25 0-29.43.65-29.43 1.44 0 .8 13.18 1.44 29.43 1.44z"
          fill="#DEDFE0"
        ></path>
        <path
          d="M50.93 23.4H20.38M4.8 23.17C.9 23.2.68 18.85.68 18.85.14 9.23 3.42 7.78 3.42 7.78 4.2 6.76 7.81 3.72 8.5 3.09c0 0 1.01-.95 2.97-1.26 1.64-.26 5.51-.61 5.51-.61C23 .65 31.26.89 34.75.99c3.22.09 6.2 1.59 6.2 1.59s6.15 3.26 10.6 5.66l3.52.55c12.95 2.38 13.82 4.58 13.98 6.01.76 1.37 0 6.72 0 6.72s.03 1.64-6.33 1.64"
          stroke="var(--colorA)"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M50.03 9.45s-.7.59-3.68.37c-2.98-.22-6.69-.55-10.09-.77-3.4-.22-24.97-1.36-24.97-1.36s3.11-3.46 4.8-4.3c1.69-.84 5.23-1.01 11.58-1.23 6.35-.22 9.77.47 12.15 1.53 2.37 1.06 7.28 3.74 10.21 5.76z"
          fill="#DEDFE0"
          stroke="var(--colorA)"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M27.67 1.88l1.05 6.7s.87 4.48.79 9.93"
          stroke="var(--colorA)"
          strokeWidth=".5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        ></path>
        <path
          d="M48.59 8.96s.74.51.45 1.36c-.29.85-1.81.69-2.21.67-.39-.02-2.01-.35-1.85-1.74.16-1.39 2.26-1.34 3.61-.29z"
          fill="var(--colorA)"
        ></path>
        <path
          d="M56.76 27.7a6.11 6.11 0 100-12.22 6.11 6.11 0 000 12.22z"
          stroke="var(--colorA)"
          strokeMiterlimit="10"
        ></path>
        <path
          d="M56.76 25.65a4.06 4.06 0 100-8.12 4.06 4.06 0 000 8.12z"
          stroke="var(--colorA)"
          strokeWidth=".5"
          strokeMiterlimit="10"
        ></path>
        <path
          d="M66.78 12.13h-6.16s-1.22.11-.24.76 1.36.91 1.36.91.93.68 1.79.66c.86-.02 5.46 0 5.46 0s-.21-1.3-2.21-2.33zM68.24 21.59h-4.31M50.66 21.59H16.8"
          stroke="var(--colorA)"
          strokeWidth=".5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M10.69 27.7a6.11 6.11 0 100-12.22 6.11 6.11 0 000 12.22z"
          stroke="var(--colorA)"
          strokeMiterlimit="10"
        ></path>
        <path
          d="M10.69 25.65a4.06 4.06 0 100-8.12 4.06 4.06 0 000 8.12z"
          stroke="var(--colorA)"
          strokeWidth=".5"
          strokeMiterlimit="10"
        ></path>
        <path
          d="M2.63 8.47h4.13s1.19.53.22 1.18l-1.36.91s-.93.68-1.79.66c-.36-.01-1.36-.01-2.41-.01"
          stroke="var(--colorA)"
          strokeWidth=".5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M13.16 11.1l2.78.18c.2.01.38-.14.4-.35.01-.2-.14-.38-.35-.4l-2.78-.18c-.2-.01-.38.14-.4.35-.01.21.15.39.35.4zM30.76 11.92l2.78.18c.2.01.38-.14.4-.35.01-.2-.14-.38-.35-.4l-2.78-.18c-.2-.01-.38.14-.4.35-.02.2.14.38.35.4z"
          fill="var(--colorA)"
        ></path>
      </symbol>
    </use>
  </svg>
);
export const sedan = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 32">
    <use href="#bodyType_sedan_icon">
      <symbol
        id="bodyType_sedan_icon"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.95 14.63c-2.94 0-5.32 2.38-5.32 5.32 0 2.94 2.38 5.32 5.32 5.32 2.94 0 5.32-2.38 5.32-5.32.01-2.94-2.38-5.32-5.32-5.32zm0 8.86a3.54 3.54 0 110-7.08 3.54 3.54 0 010 7.08zM56.14 14.63c-2.94 0-5.32 2.38-5.32 5.32 0 2.94 2.38 5.32 5.32 5.32 2.94 0 5.32-2.38 5.32-5.32.01-2.94-2.38-5.32-5.32-5.32zm0 8.86a3.54 3.54 0 110-7.08 3.54 3.54 0 010 7.08z"
          fill="#DEDFE0"
        ></path>
        <path
          d="M34.38 26.53c16.25 0 29.43-.65 29.43-1.44 0-.8-13.17-1.44-29.43-1.44-16.25 0-29.43.65-29.43 1.44 0 .79 13.18 1.44 29.43 1.44z"
          fill="#DEDFE0"
        ></path>
        <path
          d="M61.2 21.63s5.9.16 8.19-1.32c-.84-.42-1.15-.56-1.15-.56s.97-1.88.91-2.55c-.05-.67-.02-1.26-1.24-1.86.16-1.11 2.54-4.33-15.07-6.92-4.59-2.34-11.31-6.13-19.44-6.76C28.1 1.37 20.21.61 6.8 6.99 1 7.42.85 6.95 1.33 9.45c-.26.77-.13 1.43.33 2.23C1 12.63.64 13.07.64 14.02s-.02 3.66 0 4.04c.02.38.11 1.68 1.56 1.92s6.56 1.03 6.56 1.03"
          stroke="var(--colorA)"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        ></path>
        <path
          d="M50.9 21.57H23.7"
          stroke="var(--colorA)"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M49.59 9.73s-5.11.09-7.37 0S16.6 8.16 16.6 8.16s-1.22-.02-2.01-.77c-.54-.48-1.02-.76-.2-1.2.82-.43 3.66-2.48 11.48-3.22 5.54-.48 14.42.33 23.73 6.02-.01.01 1.28.75-.01.74z"
          fill="#DEDFE0"
          stroke="var(--colorA)"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        ></path>
        <path
          d="M67.52 12.88s-4.61-.76-6-.79c-.8-.02-.68.21-.14.39.53.19 2.66 1.27 3.06 1.55.4.28.91.62 1.32.72.41.1 2.16.57 2.16.57"
          stroke="var(--colorA)"
          strokeWidth=".5"
          strokeMiterlimit="10"
        ></path>
        <path
          d="M30.08 2.64l1.06 6.62s.57 2.57.57 4.37c0 1.8-.15 3.4 0 4.45"
          stroke="var(--colorA)"
          strokeWidth=".5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        ></path>
        <path
          d="M1.32 9.45c.34-.22.72-1.12.72-1.12l4.94 1.55s.06.21-.17.43-.58.75-1.74.92c-1.16.17-3.42.46-3.42.46"
          stroke="var(--colorA)"
          strokeWidth=".5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        ></path>
        <path
          d="M56.14 25.27a5.32 5.32 0 100-10.64 5.32 5.32 0 000 10.64z"
          stroke="var(--colorA)"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        ></path>
        <path
          d="M56.14 23.49a3.54 3.54 0 100-7.08 3.54 3.54 0 000 7.08z"
          stroke="var(--colorA)"
          strokeWidth=".5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        ></path>
        <path
          d="M13.95 25.27a5.32 5.32 0 100-10.64 5.32 5.32 0 000 10.64z"
          stroke="var(--colorA)"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        ></path>
        <path
          d="M13.95 23.49a3.54 3.54 0 100-7.08 3.54 3.54 0 000 7.08z"
          stroke="var(--colorA)"
          strokeWidth=".5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        ></path>
        <path
          d="M19.948 10.337c.007-.133-.958-.29-2.154-.353-1.197-.062-2.173-.005-2.18.127-.007.133.958.29 2.155.353 1.197.062 2.172.005 2.18-.127zM37.678 11.421c.007-.132-.958-.29-2.155-.352-1.197-.062-2.173-.005-2.18.127-.007.133.958.29 2.155.352 1.197.063 2.173.006 2.18-.127z"
          stroke="var(--colorA)"
          strokeWidth=".5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        ></path>
        <path
          d="M50.81 19.63H19.25"
          stroke="var(--colorA)"
          strokeWidth=".5"
          strokeMiterlimit="10"
        ></path>
        <path
          d="M61.45 19.63h5.47"
          stroke="var(--colorA)"
          strokeWidth=".5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        ></path>
        <path
          d="M20.28 2.3s-1.2-.64-2.6-.56c-.61.02-.54.28-.07 1.18"
          stroke="var(--colorA)"
          strokeWidth=".5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        ></path>
        <path
          d="M46.58 8.69s.41-.64 1.34-.51c.94.13 1.77.35 1.63 1.41-.14 1.06-.96 1.24-1.65 1.22-.69-.02-2.09-.35-1.32-2.12z"
          fill="var(--colorA)"
        ></path>
      </symbol>
    </use>
  </svg>
);
export const suv = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 32">
    <use href="#bodyType_suv_icon">
      <symbol
        id="bodyType_suv_icon"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M62.69 25.16l5.73-1.07s.81-.1.87-.96c.06-.86.1-2.75.1-2.75l-1.36-3.77s-.02-1.44-.66-1.99c-.1-.68-1.66-2.74-15.53-4.04C48.1 8 40.27 3.86 40.27 3.86s-1.58-.87-3.97-1.05c-1.48-.54-4.1-.56-6.45-.59-2.35-.03-15.63.03-18.64 1.19-2.2.31-4.1-.08-2.96 1.37-.97.84-5.28 4.54-6.05 5.36-.56.58-.85.77-.73 2.53.11 1.75.04 1.97-.3 2.43-.05.43-.09 3.14-.09 3.14s-.54-.03-.46 1.23c.08 1.26.05 2.4.53 3.07.47.67 1.3 1.73 6.07 1.54M22.86 24.82h28.28"
          stroke="var(--colorA)"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M35.87 4.03c-3.33.03-13.85.11-15.39.17-1.82.07-2.43.38-3.2.81-.77.44-3.68 2.54-3.97 2.79-.29.25-.45.42 0 .61 1.03.47 3.08.73 3.08.73s28.6 1.78 29.26 1.8c.67.01 1.03-.34 1.12-.47.09-.13.35-.47.08-.7-2.32-1.76-6.4-4.05-8.22-5.05.01.01-1.06-.7-2.76-.69z"
          fill="#DEDFE0"
          stroke="var(--colorA)"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M46.07 11.1s-.47-2.22-2.65-1.92c-.72.08-.89 1.06-.86 2.23 0 .34.58.53 1.29.5.71-.03 1.37-.05 2.22-.81z"
          fill="var(--colorA)"
        ></path>
        <path
          d="M67.46 14.85s-7.9-3.61-9.44-1.49c1.12.11 2.54.17 3.43.85.89.68 2.39 1.84 2.39 1.84h4.13s-.21-.8-.51-1.2zM2.2 10.14s1.25-.74 5.33-.57c.92.04 3.06 1.33 1.6 1.35-1.46.02-1.81-.04-2.5.42-.69.46-1.98 1.37-1.98 1.37H2.38s-.71.09-.94-.27"
          stroke="var(--colorA)"
          strokeWidth=".5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        ></path>
        <path
          d="M34.23 31.3c16.25 0 29.43-.65 29.43-1.44 0-.8-13.17-1.44-29.43-1.44-16.25 0-29.43.65-29.43 1.44 0 .79 13.17 1.44 29.43 1.44z"
          fill="#DEDFE0"
        ></path>
        <path
          d="M15.77 12.03s2.57.24 3.22.34c.65.1.3.59-.1.59h-.68s-.79-.44-1.71-.11c-1.11-.01-1.37-.24-1.36-.52.01-.28.37-.33.63-.3z"
          fill="var(--colorA)"
        ></path>
        <path
          d="M56.95 18.41c-3.21 0-5.81 2.6-5.81 5.81 0 3.21 2.6 5.81 5.81 5.81 3.21 0 5.81-2.6 5.81-5.81.01-3.21-2.6-5.81-5.81-5.81zm0 9.68c-2.13 0-3.86-1.73-3.86-3.86s1.73-3.86 3.86-3.86 3.86 1.73 3.86 3.86a3.85 3.85 0 01-3.86 3.86z"
          fill="#DEDFE0"
        ></path>
        <path
          d="M33.64 13.07s2.57.24 3.22.34c.65.1.3.59-.1.59h-.68s-.79-.44-1.71-.11c-1.11-.01-1.37-.24-1.36-.52.01-.28.37-.32.63-.3z"
          fill="var(--colorA)"
        ></path>
        <path
          d="M29.68 3.87l1.12 6.33s1 4.68.47 11.47"
          stroke="var(--colorA)"
          strokeWidth=".5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M50.9 23.28H18.85"
          stroke="var(--colorA)"
          strokeWidth=".5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        ></path>
        <path
          d="M56.95 30.03a5.81 5.81 0 100-11.62 5.81 5.81 0 000 11.62z"
          stroke="var(--colorA)"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M13.03 18.35c-3.21 0-5.81 2.6-5.81 5.81 0 3.21 2.6 5.81 5.81 5.81 3.21 0 5.81-2.6 5.81-5.81.01-3.21-2.6-5.81-5.81-5.81zm0 9.67c-2.13 0-3.86-1.73-3.86-3.86s1.73-3.86 3.86-3.86 3.86 1.73 3.86 3.86a3.85 3.85 0 01-3.86 3.86z"
          fill="#DEDFE0"
        ></path>
        <path
          d="M56.95 28.08a3.86 3.86 0 100-7.72 3.86 3.86 0 000 7.72z"
          stroke="var(--colorA)"
          strokeWidth=".5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        ></path>
        <path
          d="M13.03 30.03a5.81 5.81 0 100-11.62 5.81 5.81 0 000 11.62z"
          stroke="var(--colorA)"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M13.03 28.08a3.86 3.86 0 100-7.72 3.86 3.86 0 000 7.72z"
          stroke="var(--colorA)"
          strokeWidth=".5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        ></path>
        <path
          d="M13.31 2.95L11.42.7M62.76 23.28h4.21M3.28 20.16h2.88s1.85-4.14 6.87-4.18c5.02-.05 7.31 4.75 7.31 4.75M49.68 21.02c.75-1.9 2.63-4.8 7.08-4.8 3.81-.01 6.34 2.54 7.38 4.73"
          stroke="var(--colorA)"
          strokeWidth=".5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </symbol>
    </use>
  </svg>
);

export const FaceBook = (
  <svg
    fill="#ffffff"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24px"
    id="Icons"
    height="24px"
    className="TwitterIcon"
  >
    <path d="M17.525,9H14V7c0-1.032,0.084-1.682,1.563-1.682h1.868v-3.18C16.522,2.044,15.608,1.998,14.693,2 C11.98,2,10,3.657,10,6.699V9H7v4l3-0.001V22h4v-9.003l3.066-0.001L17.525,9z" />
  </svg>
);
export const Twitter = (
  <svg
    fill="#ffffff"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24px"
    id="Icons"
    height="24px"
  >
    <path d="M 24 4.300781 C 23.101563 4.699219 22.199219 5 21.199219 5.101563 C 22.199219 4.5 23 3.5 23.398438 2.398438 C 22.398438 3 21.398438 3.398438 20.300781 3.601563 C 19.300781 2.601563 18 2 16.601563 2 C 13.898438 2 11.699219 4.199219 11.699219 6.898438 C 11.699219 7.300781 11.699219 7.699219 11.800781 8 C 7.699219 7.800781 4.101563 5.898438 1.699219 2.898438 C 1.199219 3.601563 1 4.5 1 5.398438 C 1 7.101563 1.898438 8.601563 3.199219 9.5 C 2.398438 9.398438 1.601563 9.199219 1 8.898438 C 1 8.898438 1 8.898438 1 9 C 1 11.398438 2.699219 13.398438 4.898438 13.800781 C 4.5 13.898438 4.101563 14 3.601563 14 C 3.300781 14 3 14 2.699219 13.898438 C 3.300781 15.898438 5.101563 17.300781 7.300781 17.300781 C 5.601563 18.601563 3.5 19.398438 1.199219 19.398438 C 0.800781 19.398438 0.398438 19.398438 0 19.300781 C 2.199219 20.699219 4.800781 21.5 7.5 21.5 C 16.601563 21.5 21.5 14 21.5 7.5 C 21.5 7.300781 21.5 7.101563 21.5 6.898438 C 22.5 6.199219 23.300781 5.300781 24 4.300781" />
  </svg>
);
export const Youtube = (
  <svg
    fill="#ffffff"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24px"
    id="Icons"
    height="24px"
  >
    <path d="M21.582,6.186c-0.23-0.86-0.908-1.538-1.768-1.768C18.254,4,12,4,12,4S5.746,4,4.186,4.418 c-0.86,0.23-1.538,0.908-1.768,1.768C2,7.746,2,12,2,12s0,4.254,0.418,5.814c0.23,0.86,0.908,1.538,1.768,1.768 C5.746,20,12,20,12,20s6.254,0,7.814-0.418c0.861-0.23,1.538-0.908,1.768-1.768C22,16.254,22,12,22,12S22,7.746,21.582,6.186z M10,14.598V9.402c0-0.385,0.417-0.625,0.75-0.433l4.5,2.598c0.333,0.192,0.333,0.674,0,0.866l-4.5,2.598 C10.417,15.224,10,14.983,10,14.598z" />
  </svg>
);
