import { Button, InputField } from "core";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import SellCarFormStyle from "css/pages/sellCar/SellCarForm.module.css";

const EngineAndTransmissionForm = ({
  setPresentStep,
  setFormData,
  formData,
  onNext,
  onBack,
}) => {
  const [sellCareValues, setSellCareValues] = useState({
    noOfGears: "",
    transmissionType: "",
    drivetrain: "",
    engineType: "",
    valveOrCylinder: "",
    turbochargerOrSupercharger: "",
    cylinders: "",
    turbochargerType: "",
    displacement: "",
    dualClutch: "",
    limitedSlipDifferential: "",
  });
  //step:1
  const sellCarFormFieldList = [
    {
      name: "no Of Gears",
      fieldName: "noOfGears",
      type: "select",
      options: ["", 4, 5, 6, 7, 9],
      title: "please enter no Of Gears",
      placeholder: "Enter no Of Gears",
    },
    {
      name: "transmission Type",
      fieldName: "transmissionType",
      type: "select",
      options: ["", "Manual", "Automatic"],
      title: "please enter transmission Type",
      placeholder: "Enter transmission Type",
    },
    {
      name: "drivetrain",
      fieldName: "drivetrain",
      type: "select",
      options: ["", "Rear Wheel Drive", "Front Wheel Drive", "AWD", "4WD"],
      title: "please enter drivetrain",
      placeholder: "Enter drivetrain",
    },
    {
      name: "engine Type",
      fieldName: "engineType",
      type: "text",
      title: "please enter engine Type",
      placeholder: "Enter engine Type",
    },
    {
      name: "valve/Cylinder",
      fieldName: "valveOrCylinder",
      type: "text",
      title: "please enter valve/Cylinder",
      placeholder: "Enter valve/Cylinder",
    },
    {
      name: "turbocharger/Supercharger",
      fieldName: "turbochargerOrSupercharger",
      type: "text",
      title: "please enter turbocharger/Supercharger",
      placeholder: "Enter turbocharger/Supercharger",
    },
    {
      name: "cylinders",
      fieldName: "cylinders",
      type: "text",
      title: "please enter cylinders",
      placeholder: "Enter cylinders",
    },
    {
      name: "turbocharger Type",
      fieldName: "turbochargerType",
      type: "text",
      title: "please enter turbocharger Type",
      placeholder: "Enter turbocharger Type",
    },
    {
      name: "displacement",
      fieldName: "displacement",
      type: "number",
      title: "please enter displacement",
      placeholder: "Enter displacement",
    },
    {
      name: "dual Clutch",
      fieldName: "dualClutch",
      type: "text",
      title: "please enter dual Clutch",
      placeholder: "Enter dual Clutch",
    },
    {
      name: "limited Slip Differential",
      fieldName: "limitedSlipDifferential",
      type: "text",
      title: "please enter limited Slip Differential",
      placeholder: "Enter limited Slip Differential",
    },
  ];

  const SellCarFormSchema = Yup.object().shape({
    //step:1 car info
    noOfGears: Yup.number(),
    transmissionType: Yup.string(),
    drivetrain: Yup.string(),
    engineType: Yup.string(),
    valveOrCylinder: Yup.string(),
    turbochargerOrSupercharger: Yup.string(),
    cylinders: Yup.string(),
    turbochargerType: Yup.string(),
    displacement: Yup.number(),
    dualClutch: Yup.string(),
    limitedSlipDifferential: Yup.string(),
  });

  useEffect(() => {
    if (formData.EngineAndTransmissionFormData) {
      setSellCareValues(formData.EngineAndTransmissionFormData);
    }
  }, [formData]);

  const submitForm = async (values) => {
    await setFormData((prev) => ({
      ...prev,
      EngineAndTransmissionFormData: values,
    }));
    onNext && onNext();
    // setPresentStep((prev) => prev + 1);
  };
  return (
    <div style={{ width: "100%" }}>
      <Formik
        initialValues={sellCareValues}
        enableReinitialize
        onSubmit={submitForm}
        validationSchema={SellCarFormSchema}
      >
        {({
          touched,
          errors,
          values,
          setTouched,
          handleChange,
          setFieldValue,
        }) => (
          <Form>
            <span className="FRSB">
              <h1 className="STUL">Engine And Transmission</h1>
            </span>
            <div className={SellCarFormStyle.inputWarper}>
              {sellCarFormFieldList.map((field, index) => {
                return (
                  <Field name={field.fieldName} key={index}>
                    {(props) => (
                      <InputField
                        {...props.field}
                        name={field.name}
                        title={field.title}
                        options={field.options}
                        onError={
                          touched[field.fieldName] && errors[field.fieldName]
                        }
                        type={field.type}
                        Icon={field.Icon}
                        fieldName={field.fieldName}
                        Value={values[field.fieldName]}
                        setFieldValue={setFieldValue}
                        onChange={handleChange}
                        placeholder={field.placeholder}
                        inputClassName={SellCarFormStyle.inputClassName}
                        errorMessage={
                          <ErrorMessage
                            name={field.fieldName}
                            render={(msg) => <p className="EMS">{msg}</p>}
                          />
                        }
                      />
                    )}
                  </Field>
                );
              })}
            </div>
            <div className={SellCarFormStyle.btnWarper}>
              {onBack && (
                <Button
                  type="button"
                  style={{ margin: "1em", backgroundColor: "var(--colorA)" }}
                  onClick={onBack}
                  // setPresentStep((prev) => prev - 1)
                >
                  back
                </Button>
              )}
              <Button
                type="submit"
                style={{ margin: "1em" }}
                LastIcon={
                  <span className="material-icons-round">arrow_forward</span>
                }
              >
                save and continue
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EngineAndTransmissionForm;
