import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import SellCarFormStyle from "css/pages/sellCar/SellCarForm.module.css";
import { useEffect, useState } from "react";
import { Button, InputField } from "core";

const CarImagesForm = ({
  setPresentStep,
  totalStage,
  setFormData,
  formData,
  onNext,
  onBack,
  IsLoading,
  isLastItem,
}) => {
  const [sellCareValues, setSellCareValues] = useState({
    leftFrontCornerImage: null,
    leftSideViewImage: null,
    leftRearCornerImage: null,
    backViewImage: null,
    rightRearCornerImage: null,
    rightSideCornerImage: null,
    rightFrontCornerImage: null,
    frontViewImage: null,
  });

  useEffect(() => {
    if (formData.CarImagesFormData) {
      setSellCareValues(formData.CarImagesFormData);
    }
  }, [formData]);

  //step:3
  const sellCarImages = [
    {
      name: "left Front Corner Image",
      fieldName: "leftFrontCornerImage",
      type: "file",
      title: "please add left Front Corner Image",
      placeholder: "Left Front Corner Image",
    },
    {
      name: "left side view Image",
      fieldName: "leftSideViewImage",
      type: "file",
      title: "please add left side view Image",
      placeholder: "left side view Image",
    },
    {
      name: "left rear corner Image",
      fieldName: "leftRearCornerImage",
      type: "file",
      title: "please add left rear corner Image",
      placeholder: "left rear corner Image",
    },
    {
      name: "back view Image",
      fieldName: "backViewImage",
      type: "file",
      title: "please add back view Image",
      placeholder: "back view Image",
    },
    {
      name: "right rear corner Image",
      fieldName: "rightRearCornerImage",
      type: "file",
      title: "please add right rear corner Image",
      placeholder: "right rear corner Image",
    },
    {
      name: "right side Image",
      fieldName: "rightSideCornerImage",
      type: "file",
      title: "please add right side corner Image",
      placeholder: "right side corner Image",
    },
    {
      name: "right front corner Image",
      fieldName: "rightFrontCornerImage",
      type: "file",
      title: "please add right front corner Image",
      placeholder: "right front corner Image",
    },
    {
      name: "front view Image",
      fieldName: "frontViewImage",
      type: "file",
      title: "please add front view Image",
      placeholder: "front view Image",
    },
  ];

  const SellCarFormSchema = Yup.object().shape({
    // step:3 images
    leftFrontCornerImage: Yup.mixed().required(
      "left Front Corner Image is required"
    ),
    leftSideViewImage: Yup.mixed().required("left Side View Image is required"),
    leftRearCornerImage: Yup.mixed().required(
      "left Rear Corner Image is required"
    ),
    backViewImage: Yup.mixed().required("back View Image is required"),
    rightRearCornerImage: Yup.mixed().required(
      "right Rear Corner Image is required"
    ),
    rightSideCornerImage: Yup.mixed().required(
      "right Side Corner Image is required"
    ),
    rightFrontCornerImage: Yup.mixed().required(
      "right Front Corner Image is required"
    ),
    frontViewImage: Yup.mixed().required("front View Image is required"),
  });

  const submitForm = async (values) => {
    await setFormData((prev) => ({
      ...prev,
      CarImagesFormData: values,
    }));
    onNext &&
      onNext({
        ...formData,
        CarImagesFormData: values,
      });
    // setPresentStep(8);
  };

  return (
    <div style={{ width: "100%" }}>
      <Formik
        initialValues={sellCareValues}
        enableReinitialize
        onSubmit={submitForm}
        validationSchema={SellCarFormSchema}
      >
        {({
          handleSubmit,
          touched,
          errors,
          values,
          setTouched,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <span className="FRSB">
              <h1 className="STUL">Add Car Images</h1>
            </span>
            <div className={SellCarFormStyle.inputWarper}>
              {sellCarImages.map((field, index) => {
                return (
                  <InputField
                    key={index}
                    name={field.name}
                    title={field.title}
                    initialImage={
                      formData?.CarImagesFormData &&
                      formData?.CarImagesFormData[field.fieldName] &&
                      (formData?.CarImagesFormData[field.fieldName]?.url ||
                        URL.createObjectURL(
                          formData.CarImagesFormData[field.fieldName]
                        ) ||
                        "")
                    }
                    onError={
                      touched[field.fieldName] && errors[field.fieldName]
                    }
                    type={field.type}
                    Icon={field.Icon}
                    fieldName={field.fieldName}
                    Value={values[field.fieldName]}
                    setFieldValue={setFieldValue}
                    onChange={handleChange}
                    placeholder={field.placeholder}
                    inputClassName={SellCarFormStyle.inputClassName}
                    errorMessage={
                      <ErrorMessage
                        name={field.fieldName}
                        render={(msg) => <p className="EMS">{msg}</p>}
                      />
                    }
                  />
                );
              })}
            </div>
            <div className={SellCarFormStyle.btnWarper}>
              {onBack && (
                <Button
                  loading={IsLoading}
                  type="button"
                  style={{ margin: "1em", backgroundColor: "var(--colorA)" }}
                  onClick={onBack}
                  // setPresentStep((prev) => prev - 2)
                >
                  back
                </Button>
              )}
              {isLastItem ? (
                <Button
                  loading={IsLoading}
                  type="submit"
                  style={{ margin: "1em" }}
                >
                  save
                </Button>
              ) : (
                <Button
                  loading={IsLoading}
                  type="submit"
                  style={{ margin: "1em" }}
                  LastIcon={
                    <span className="material-icons-round">arrow_forward</span>
                  }
                >
                  save and continue
                </Button>
              )}
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default CarImagesForm;
