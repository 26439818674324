import { Footer } from "components/footer";
import { Header, QueriesHeader } from "components/header";

const Base = ({ children }) => {
  return (
    <>
      <Header />
      <QueriesHeader />
      {children}
      <Footer />
    </>
  );
};

export default Base;
