import { useAppContext } from "hooks";
import { Navigate } from "react-router-dom";

const AdminRoute = ({ children }) => {
  const { user } = useAppContext();

  if (user?.role === "SUPERADMIN") {
    return children;
  } else {
    return <Navigate to="/" />;
  }
};

export default AdminRoute;
