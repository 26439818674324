import { carSkeleton } from "assets";
import ProductCardStyle from "css/components/ProductCards.module.css";
import { useAppContext, useImageState, useScrollReveal } from "hooks";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";

const ProductCard = ({
  type,
  id,
  image,
  name,
  price,
  kilometers,
  fuelType,
  transmission,
  withWishlist,
  mileage,
  carType,
  carHubLocation,
}) => {
  const [IsImageLoading] = useImageState(image);
  let navigate = useNavigate();
  const { setWishlist, wishlist } = useAppContext();
  const target = useRef();
  const { IsReveal } = useScrollReveal({
    target: target.current,
  });

  const handelCardClick = (e) => {
    if (!e.target.matches("[data-wishlist]")) {
      navigate(
        `/buy-car/${name?.replaceAll("/", " or ")}?carType=${carType}&id=${id}`
      );
    } else if (e.target.matches("[data-wishlist]")) {
      wishlist?.find((item) => item === id)
        ? setWishlist((prev) => prev.filter((item) => item !== id))
        : setWishlist((prev) => [...prev, id]);
    }
  };

  switch (type) {
    case 1:
      return (
        <div
          className={`${ProductCardStyle.conrtainer}  ${
            IsReveal ? "reveal-active" : "reveal"
          }`}
          onClick={handelCardClick}
          ref={target}
          title={name}
        >
          <div
            className={`${ProductCardStyle.ImageSection} ${
              IsImageLoading && "animateSkeleton"
            }`}
            style={{
              backgroundImage: `url(${image})`,
            }}
          >
            {IsImageLoading ? (
              <img
                src={carSkeleton}
                alt="car skeleton"
                className={ProductCardStyle.carSkeletonImg}
              ></img>
            ) : (
              <img src={image} alt="" loading="lazy" />
            )}
          </div>
          <div className={ProductCardStyle.DetailsSection}>
            <span className={ProductCardStyle.cardTitleWarper}>
              <b>{name}</b>
              {withWishlist && (
                <span
                  className={`material-icons-round ${
                    ProductCardStyle.wishlistIcon
                  } ${
                    wishlist?.find((item) => item === id) &&
                    ProductCardStyle.active
                  }`}
                  data-wishlist
                >
                  {wishlist?.find((item) => item === id)
                    ? "favorite"
                    : "favorite_border"}
                </span>
              )}
            </span>
            <p className={ProductCardStyle.info}>{`${
              (kilometers || mileage) && kilometers
                ? `${kilometers?.toLocaleString()} Kms .`
                : `${mileage?.toLocaleString()} Kmpl .`
            } ${fuelType} .
          ${transmission}`}</p>
            {/* <b className="TMC">₹ {price?.toLocaleString()}</b> */}
            <span className={ProductCardStyle.BrandTitleWarper}>
              <span className="material-icons">location_on</span>
              <p>Carkarobar hub {carHubLocation && `, ${carHubLocation}`}</p>
            </span>
          </div>
        </div>
      );
    case 2:
      return (
        <div
          className={`${ProductCardStyle.conrtainerTwo}  ${
            IsReveal ? "reveal-active" : "reveal"
          }`}
          onClick={handelCardClick}
          ref={target}
          title={name}
        >
          <div
            className={`${ProductCardStyle.ImageSectionTwo} ${
              IsImageLoading && "animateSkeleton"
            }`}
            style={{
              backgroundImage: `url(${image})`,
            }}
          >
            {IsImageLoading ? (
              <img
                src={carSkeleton}
                alt="car skeleton"
                className={ProductCardStyle.carSkeletonImg}
              ></img>
            ) : (
              <img src={image} alt="" loading="lazy" />
            )}
          </div>
          <div className={ProductCardStyle.DetailsSectionTwo}>
            <span className={ProductCardStyle.cardTitleWarper}>
              <b>{name}</b>
              {withWishlist && (
                <span
                  className={`material-icons-round ${
                    ProductCardStyle.wishlistIcon
                  } ${
                    wishlist?.find((item) => item === id) &&
                    ProductCardStyle.active
                  }`}
                  data-wishlist
                >
                  {wishlist?.find((item) => item === id)
                    ? "favorite"
                    : "favorite_border"}
                </span>
              )}
            </span>

            <p className={ProductCardStyle.info}>{`${
              kilometers
                ? `${kilometers?.toLocaleString()} Kms .`
                : `${mileage?.toLocaleString()} Kmpl .`
            } ${fuelType} .
          ${transmission}`}</p>
            {/* <b className="TMC">₹ {price?.toLocaleString()}</b> */}
            <span className={ProductCardStyle.BrandTitleWarper}>
              <span className="material-icons">location_on</span>
              <p>Carkarobar hub {carHubLocation && `, ${carHubLocation}`}</p>
            </span>
          </div>
        </div>
      );
    case 3:
      return (
        <div
          className={`${ProductCardStyle.conrtainerThree}  ${
            IsReveal ? "reveal-active" : "reveal"
          }`}
          onClick={handelCardClick}
          ref={target}
          title={name}
        >
          <div
            className={`${ProductCardStyle.ImageSectionThree} ${
              IsImageLoading && "animateSkeleton"
            }`}
          >
            {IsImageLoading ? (
              <img
                src={carSkeleton}
                alt="car skeleton"
                className={ProductCardStyle.carSkeletonImg}
              ></img>
            ) : (
              <img src={image} alt="" loading="lazy" />
            )}
          </div>
          <div className={ProductCardStyle.DetailsSectionThree}>
            <span className={ProductCardStyle.cardTitleWarper}>
              <b>{name}</b>
              {withWishlist && (
                <span
                  className={`material-icons-round ${
                    ProductCardStyle.wishlistIcon
                  } ${
                    wishlist?.find((item) => item === id) &&
                    ProductCardStyle.active
                  }`}
                  data-wishlist
                >
                  {wishlist?.find((item) => item === id)
                    ? "favorite"
                    : "favorite_border"}
                </span>
              )}
            </span>
            <p className={ProductCardStyle.info}>{`${
              kilometers
                ? `${kilometers?.toLocaleString()} Kms .`
                : `${mileage?.toLocaleString()} Kmpl .`
            } ${fuelType} .
          ${transmission}`}</p>
            {/* <b className="TMC">₹ {price?.toLocaleString()}</b> */}
            <span className={ProductCardStyle.BrandTitleWarper}>
              <span className="material-icons">location_on</span>
              <p>Carkarobar hub {carHubLocation && `, ${carHubLocation}`}</p>
            </span>
          </div>
        </div>
      );
    case 4:
      return (
        <div
          className={`${ProductCardStyle.conrtainerFour}  ${
            IsReveal ? "reveal-active" : "reveal"
          }`}
          onClick={handelCardClick}
          ref={target}
          title={name}
        >
          <div
            className={`${ProductCardStyle.ImageSectionFour} ${
              IsImageLoading && "animateSkeleton"
            }`}
            style={{
              backgroundImage: `url(${image})`,
            }}
          >
            {IsImageLoading ? (
              <img
                src={carSkeleton}
                alt="car skeleton"
                className={ProductCardStyle.carSkeletonImg}
              ></img>
            ) : (
              <img src={image} alt="" loading="lazy" />
            )}
          </div>
          <div className={ProductCardStyle.DetailsSectionFour}>
            <span className={ProductCardStyle.cardTitleWarper}>
              <b>{name}</b>
              {withWishlist && (
                <span
                  className={`material-icons-round ${
                    ProductCardStyle.wishlistIcon
                  } ${
                    wishlist?.find((item) => item === id) &&
                    ProductCardStyle.active
                  }`}
                  data-wishlist
                >
                  {wishlist?.find((item) => item === id)
                    ? "favorite"
                    : "favorite_border"}
                </span>
              )}
            </span>
            <p className={ProductCardStyle.info}>{`${
              kilometers
                ? `${kilometers?.toLocaleString()} Kms .`
                : `${mileage?.toLocaleString()} Kmpl .`
            } ${fuelType} .
          ${transmission}`}</p>
            {/* <b className="TMC">₹ {price?.toLocaleString()}</b> */}
            <span className={ProductCardStyle.BrandTitleWarper}>
              <span className="material-icons">location_on</span>
              <p>Carkarobar hub {carHubLocation && `, ${carHubLocation}`}</p>
            </span>
          </div>
        </div>
      );
    default:
      return (
        <div
          className={`${ProductCardStyle.conrtainer}  ${
            IsReveal ? "reveal-active" : "reveal"
          }`}
          onClick={handelCardClick}
          ref={target}
          title={name}
        >
          <div
            className={`${ProductCardStyle.ImageSection} ${
              IsImageLoading && "animateSkeleton"
            }`}
            style={{
              backgroundImage: `url(${image})`,
            }}
          >
            {IsImageLoading ? (
              <img
                src={carSkeleton}
                alt="car skeleton"
                className={ProductCardStyle.carSkeletonImg}
              ></img>
            ) : (
              <img src={image} alt="" loading="lazy" />
            )}
          </div>
          <div className={ProductCardStyle.DetailsSection}>
            <span className={ProductCardStyle.cardTitleWarper}>
              <b>{name}</b>
              {withWishlist && (
                <span
                  className={`material-icons-round ${
                    ProductCardStyle.wishlistIcon
                  } ${
                    wishlist?.find((item) => item === id) &&
                    ProductCardStyle.active
                  }`}
                  data-wishlist
                >
                  {wishlist?.find((item) => item === id)
                    ? "favorite"
                    : "favorite_border"}
                </span>
              )}
            </span>
            <p className={ProductCardStyle.info}>{`${
              kilometers
                ? `${kilometers?.toLocaleString()} Kms .`
                : `${mileage?.toLocaleString()} Kmpl .`
            } ${fuelType} .
          ${transmission}`}</p>
            {/* <b className="TMC">₹ {price?.toLocaleString()}</b> */}
            <span className={ProductCardStyle.BrandTitleWarper}>
              <span className="material-icons">location_on</span>
              <p>Carkarobar hub {carHubLocation && `, ${carHubLocation}`}</p>
            </span>
          </div>
        </div>
      );
  }
};

export default ProductCard;
