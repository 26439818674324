import { useEffect, useRef, useState } from "react";

const useImageState = (IMAGE) => {
  const IsMounted = useRef();
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    IsMounted.current = true;
    if (IMAGE) {
      IsMounted.current && setLoading(true);
      const imageData = new Image();
      imageData.onload = () => {
        IsMounted.current && setLoading(false);
      };
      imageData.onerror = () => {
        console.error(`404 not found ${IMAGE} 🙅`);
        IsMounted.current && setLoading(false);
      };
      imageData.src = IMAGE;
    }
    return () => {
      IsMounted.current = false;
    };
  }, [IMAGE]);
  return [loading];
};

export default useImageState;
