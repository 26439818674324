import { carSkeleton } from "assets";
import { database } from "config";
import { BottomShutter, Button } from "core";
import WishlistCardStyle from "css/components/WishlistCard.module.css";
import { CarBookForm, NewCarBookForm } from "forms";
import {
  useAppContext,
  useImageState,
  useIsMounted,
  useScrollReveal,
} from "hooks";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";

const WishlistCard = ({ mobile, carType, noAction, ...props }) => {
  const IsMounted = useIsMounted();
  const [IsSubmitFormOpen, setIsSubmitFormOpen] = useState(false);
  const [isWishListLoading, setIsWishListLoading] = useState(false);
  const { wishlist, user } = useAppContext();
  const [IsImageLoading] = useImageState(props?.image);
  const target = useRef();
  const { IsReveal } = useScrollReveal({
    target: target.current,
  });

  const handelWishlist = async () => {
    IsMounted.current && setIsWishListLoading(true);
    //check is Wishlist Already present
    if (
      !wishlist?.find(
        (item) => item.id === props.id && item?.type.toUpperCase() === carType
      )
    ) {
      //checking if car type is NEW 🆕
      if (carType.toUpperCase() === "NEW") {
        await database.ref(`Users/${user?.uid}/wishlist`).push({
          type: carType,
          id: props?.id,
        });
      } else if (carType && props?.storeId && props?.id) {
        //check is USED car have All the required fields 🚗
        await database.ref(`Users/${user?.uid}/wishlist`).push({
          type: carType,
          storeId: props?.storeId,
          id: props?.id,
        });
      }
    } else {
      //deleting wishlist from database
      const wishlistId = await wishlist?.find(
        (item) => item.id === props.id && item?.type.toUpperCase() === carType
      ).wishlistId;
      await database.ref(`Users/${user?.uid}/wishlist/${wishlistId}`).remove();
    }
    IsMounted.current && setIsWishListLoading(false);
    // wishlist?.find((item) => item === props.id)
    //   ? setWishlist((prev) => prev.filter((item) => item !== props.id))
    //   : setWishlist((prev) => [...prev, props.id]);
  };

  return (
    <div
      ref={target}
      className={`${WishlistCardStyle.container} ${
        IsReveal ? "reveal-active" : "reveal"
      }`}
      title={props.name}
    >
      <Link
        to={
          props.doNotNavigate
            ? "/account/sell-cars"
            : `/buy-car/${props.name?.replaceAll(
                "/",
                " or "
              )}?carType=${carType.toLocaleLowerCase()}&id=${props.id}`
        }
        className={`${WishlistCardStyle.ImageSection} ${
          IsImageLoading && "animateSkeleton"
        }`}
        style={{
          backgroundImage: `url(${props?.image})`,
        }}
      >
        {IsImageLoading && (
          <img
            src={carSkeleton}
            alt="car skeleton"
            className={WishlistCardStyle.carSkeletonImg}
          ></img>
        )}
      </Link>
      <div className={`${WishlistCardStyle.contentSection}`}>
        <span
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <h5 className={WishlistCardStyle?.title} title={props.name}>
            {props.name}
          </h5>
          {!noAction && (
            <span
              data-wishlist
              onClick={handelWishlist}
              className={`material-icons-round ${
                WishlistCardStyle.wishlistIcon
              } ${
                wishlist?.find(
                  (item) =>
                    item.id === props.id && item?.type.toUpperCase() === carType
                ) && WishlistCardStyle.active
              }`}
            >
              {isWishListLoading ? (
                <span className="loaderOneBlue"></span>
              ) : wishlist?.find(
                  (item) =>
                    item.id === props.id && item?.type.toUpperCase() === carType
                ) ? (
                "favorite"
              ) : (
                "favorite_border"
              )}
            </span>
          )}
        </span>
        <span className={WishlistCardStyle.metaInfo}>
          {(props?.kilometers || props?.mileage) &&
            (props?.kilometers
              ? `${props?.kilometers?.toLocaleString()} Kms . `
              : `${props?.mileage?.toLocaleString()} Kmpl . `)}
          {props?.fuelType} . {props?.transmission}
        </span>
        <span className="FRSB">
          {/* {props?.price && <b>₹{props?.price?.toLocaleString()}</b>} */}
          {props?.carNumber && <b>{props?.carNumber}</b>}
          <span
            className={`${WishlistCardStyle.carTypeBatch} ${
              carType && WishlistCardStyle[carType.toLocaleLowerCase()]
            }`}
          >
            {carType}
          </span>
        </span>
        {!noAction && (
          <Button
            onClick={() => setIsSubmitFormOpen(true)}
            design={2}
            className={WishlistCardStyle.bookBtn}
          >
            enquire now
          </Button>
        )}
      </div>
      <BottomShutter
        IsOpen={IsSubmitFormOpen}
        closeFC={() => setIsSubmitFormOpen(false)}
      >
        {carType.toUpperCase() === "USED" ? (
          <CarBookForm
            storeId={props?.storeId}
            carId={props?.id}
            postSubmit={() => setIsSubmitFormOpen(false)}
          />
        ) : (
          <NewCarBookForm
            postSubmit={() => setIsSubmitFormOpen(false)}
            carId={props?.id}
          />
        )}
      </BottomShutter>
    </div>
  );
};

export default WishlistCard;
