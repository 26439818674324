import React, { useCallback, useEffect, useRef, useState } from "react";
import DoubleRangeSliderStyle from "css/core/DoubleRangeSlider.module.css";

const DoubleRangeSlider = ({ min, max, onChange, isReset }) => {
  const [minVal, setMinVal] = useState(min);
  const [maxVal, setMaxVal] = useState(max);
  const minValRef = useRef(null);
  const maxValRef = useRef(null);
  const range = useRef(null);

  useEffect(() => {
    if (isReset) {
      setMinVal(min);
      setMaxVal(max);
    }
  }, [isReset, max, min]);

  // Convert to percentage
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  // Set width of the range to decrease from the left side
  useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(minVal);
      const maxPercent = getPercent(+maxValRef.current.value); // Preceding with '+' converts the value from type string to type number

      if (range.current) {
        range.current.style.left = `${minPercent}%`;
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(+minValRef.current.value);
      const maxPercent = getPercent(maxVal);

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [maxVal, getPercent]);

  // Get min and max values when their state changes
  useEffect(() => {
    onChange && onChange({ min: minVal, max: maxVal });
  }, [minVal, maxVal, onChange]);

  return (
    <div className={DoubleRangeSliderStyle.container}>
      <input
        type="range"
        min={min}
        max={max}
        value={minVal}
        ref={minValRef}
        onChange={(event) => {
          const value = Math.min(+event.target.value, maxVal - 1);
          setMinVal(value);
          event.target.value = value.toString();
        }}
        className={`${DoubleRangeSliderStyle.thumb} ${
          DoubleRangeSliderStyle.thumb_zindex_3
        } ${minVal > max - 100 && DoubleRangeSliderStyle.thumb_zindex_5}`}
      />
      <input
        type="range"
        min={min}
        max={max}
        value={maxVal}
        ref={maxValRef}
        onChange={(event) => {
          const value = Math.max(+event.target.value, minVal + 1);
          setMaxVal(value);
          event.target.value = value.toString();
        }}
        className={`${DoubleRangeSliderStyle.thumb} ${DoubleRangeSliderStyle.thumb_zindex_4}`}
      />

      <div className={`${DoubleRangeSliderStyle.slider}`}>
        <div className={`${DoubleRangeSliderStyle.slider__track}`} />
        <div
          ref={range}
          className={`${DoubleRangeSliderStyle.slider__range}`}
        />
        <div className={`${DoubleRangeSliderStyle.slider__left_value}`}>
          ₹{minVal?.toLocaleString()}
        </div>
        <div className={`${DoubleRangeSliderStyle.slider__right_value}`}>
          ₹{maxVal?.toLocaleString()}
        </div>
      </div>
    </div>
  );
};

export default DoubleRangeSlider;
