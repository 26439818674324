import { database } from "config";
import BuySellCarsStyle from "css/pages/admin/dashboard/BuySellCars.module.css";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const BuySellCars = () => {
  const [NewCarsLoading, setNewCarsLoading] = useState(true);

  const [dateRange, setDateRange] = useState([]);

  const [dataSeries, setDataSeries] = useState([
    {
      name: "inventory",
      data: [],
    },
  ]);

  useEffect(() => {
    (async () => {
      try {
        await database.ref("NewCars").once("value", async (snapshot) => {
          const data = await snapshot.val();
          if (data) {
            const allBookings = Object.entries(data).map((item) => item[1]);
            const totalCarCount = await allBookings.reduce((prev, item) => {
              return [...prev, prev.length + 1];
            }, []);
            const dateRangeCount = await allBookings.reduce((prev, item) => {
              return [
                ...prev,
                new Date(item.timestamp).toISOString().split("T")[0],
              ];
            }, []);
            setDataSeries([
              {
                name: "inventory",
                data: totalCarCount,
              },
            ]);
            setDateRange(dateRangeCount);
            setNewCarsLoading(false);
          } else {
            setNewCarsLoading(false);
          }
        });
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const ChatOptions = {
    chart: {
      height: 350,
      type: "area",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories: dateRange,
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  };

  return (
    <div className={BuySellCarsStyle.container}>
      {NewCarsLoading ? (
        <span className="loaderOneBlue"></span>
      ) : (
        <>
          <b>Inventory</b>

          <ReactApexChart
            options={ChatOptions}
            series={dataSeries}
            type="area"
            height={350}
            width="100%"
          />
        </>
      )}
    </div>
  );
};

export default BuySellCars;
