import { VehicleSale } from "assets";
import WishlistCard from "components/WishlistCard";
import { database } from "config";
import { EmptyPage } from "core";
import { useAppContext, useIsMounted } from "hooks";
import { useEffect, useState } from "react";
import AccountDetailViewSection from "../AccountDetailViewSection";
import SellBookingStyle from "css/pages/account/Wishlist.module.css";

const SellBooking = () => {
  const IsMounted = useIsMounted();
  const [mySellCars, setMySellCars] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useAppContext();

  useEffect(() => {
    database.ref("BiddingCars").once("value", (snapshot) => {
      const data = snapshot.val();
      IsMounted.current && setIsLoading(false);
      data &&
        Object.entries(data).map(
          (item) =>
            item[1].sellBy === user.uid &&
            IsMounted.current &&
            setMySellCars((prev) => [...prev, { [item[0]]: data[item[0]] }])
        );
    });
    document.querySelector("#AccountDetailViewSection").scrollIntoView(true);
  }, [IsMounted, user]);
  return (
    <AccountDetailViewSection title="Sell Booking">
      {isLoading ? (
        <EmptyPage>
          <span className="loaderOneBlue"></span>
        </EmptyPage>
      ) : !!mySellCars.length ? (
        <div className={SellBookingStyle.container}>
          {mySellCars.map((item, index) => {
            return (
              <WishlistCard
                noAction={true}
                doNotNavigate={true}
                key={index}
                carType="used"
                id={Object.entries(item)[0][1].id}
                mobile={true}
                image={
                  Object.entries(item)[0][1]?.CarImagesFormData
                    ?.rightFrontCornerImage?.url ||
                  Object.entries(item)[0][1]?.CarImagesFormData
                    ?.rightFrontCornerImage?.uri ||
                  Object.entries(item)[0][1]?.evaluateFrontImages?.uri
                }
                name={`${
                  Object.entries(item)[0][1]?.CarInformationData?.brand ||
                  Object.entries(item)[0][1]?.carDetails?.brand
                } ${
                  Object.entries(item)[0][1]?.CarInformationData?.model ||
                  Object.entries(item)[0][1]?.carDetails?.model
                } ${
                  Object.entries(item)[0][1]?.CarInformationData?.variant ||
                  Object.entries(item)[0][1]?.carDetails?.variant
                }`}
                carNumber={
                  Object.entries(item)[0][1].CarInfoFormData.car_number
                }
                fuelType={
                  Object.entries(item)[0][1]?.FuelAndPerformanceFormData
                    ?.fuelType
                }
                transmission={
                  Object.entries(item)[0][1]?.EngineAndTransmissionFormData
                    ?.transmissionType
                }
              />
            );
          })}
        </div>
      ) : (
        <EmptyPage image={VehicleSale}>No Car Sell Is Done</EmptyPage>
      )}
    </AccountDetailViewSection>
  );
};

export default SellBooking;
