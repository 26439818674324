import { app, auth, database } from "config";
import { createContext, useContext, useEffect, useState } from "react";
import useIsMounted from "./useIsMounted";

const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const [wishlist, setWishlist] = useState([]);
  const [user, setUser] = useState([]);
  const [IsLoading, setIsLoading] = useState(true);
  const [resetCarCompare, setResetCarCompare] = useState(false);
  const [AlertMessage, setAlertMessage] = useState({
    type: "",
    message: "",
  });

  //car Search Result
  const [searchResult, setSearchResult] = useState([]);

  const IsMounted = useIsMounted();

  useEffect(() => {
    //fire base Auth listener
    (async () => {
      try {
        await auth.onAuthStateChanged(async (user) => {
          if (user) {
            await database
              .ref(`Users/${user.uid}`)
              .on("value", async (snapshot) => {
                setUser([]);
                const data = await snapshot.val();
                IsMounted.current &&
                  setUser({
                    uid: user.uid,
                    fullName: user.displayName,
                    ...data,
                  });
                IsMounted.current && setIsLoading(false);
              });
            "Notification" in window &&
              Notification.requestPermission(function (permission) {
                // If the user accepts, let's create a notification
                if (permission === "granted") {
                  // Get FCM Token
                  app
                    .messaging()
                    .requestPermission()
                    .then(() => {
                      return app.messaging().getToken();
                    })
                    .then((fcmToken) => {
                      database
                        .ref(`Users/${user?.uid}/fcmTokenWeb`)
                        .set(fcmToken);
                    });
                }
              });
          } else {
            IsMounted.current && setUser(null);
            IsMounted.current && setIsLoading(false);
          }
        });
      } catch (error) {
        console.log(error);
        IsMounted.current && setIsLoading(false);
      }
    })();
  }, [IsMounted]);

  // Send Notification
  const sendNotification = async ({ notification, FCMToken, onclick }) => {
    const SERVER_KEY =
      "AAAA9WbwY1s:APA91bHLby5xOAoU7tgxTW0wI8bRXpg4Q3g7wQbv0lVmlACEVJIdwHl5mlNz4aqMLZ1oTok0qIk-TslY8DFOk17_7cM6i2VdCeAlskTKOtYhZgg1DWWkCB1Ae6QQ9fzVhsSacHxgc4At";
    const FCM_PUSH_URL = "https://fcm.googleapis.com/fcm/send";

    const body = {
      to: FCMToken,
      notification: {
        ...notification,
        icon: "https://firebasestorage.googleapis.com/v0/b/aditya-group-2021.appspot.com/o/Fill_logo.png?alt=media&token=a06651ab-3728-4eeb-859d-38f96e5509d1",
        click_action: onclick || "https://carkarobar.netlify.app/",
      },
      tag: FCMToken,
      priority: "high",
    };

    try {
      const response = await fetch(FCM_PUSH_URL, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `key=${SERVER_KEY}`,
        },
      });

      const results = await response?.json();
      return results;
    } catch (error) {}
  };

  //load wishlist from user wishlist
  useEffect(() => {
    (async () => {
      if (user) {
        await database
          .ref(`Users/${user.uid}/wishlist`)
          .on("value", async (snapshot) => {
            const wishlistData = await snapshot.val();
            if (wishlistData) {
              IsMounted.current &&
                setWishlist(
                  Object.entries(wishlistData).map((item) => ({
                    ...item[1],
                    wishlistId: item[0],
                  }))
                );
            } else {
              IsMounted.current && setWishlist([]);
            }
          });
      } else {
        IsMounted.current && setWishlist([]);
      }
    })();
  }, [IsMounted, user]);

  return (
    <AppContext.Provider
      value={{
        wishlist,
        setWishlist,
        AlertMessage,
        setAlertMessage,
        user,
        IsLoading,
        searchResult,
        setSearchResult,
        resetCarCompare,
        setResetCarCompare,
        sendNotification,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  const {
    wishlist,
    setWishlist,
    AlertMessage,
    setAlertMessage,
    user,
    IsLoading,
    searchResult,
    setSearchResult,
    resetCarCompare,
    setResetCarCompare,
    sendNotification,
  } = useContext(AppContext);
  return {
    wishlist,
    setWishlist,
    AlertMessage,
    setAlertMessage,
    user,
    IsLoading,
    searchResult,
    setSearchResult,
    resetCarCompare,
    setResetCarCompare,
    sendNotification,
  };
};

export default useAppContext;
