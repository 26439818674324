import Slider from "core/Slider";
import { useState } from "react";
// import HomeStyle from "css//pages/Home.module.css";
import {
  carOne,
  carOneBig,
  carThree,
  carThreeBig,
  carTwo,
  carTwoBig,
} from "assets";
import SliderBanner from "components/SliderBanner";
import useScreenSize from "hooks/useScreenSize";
import Base from "layout/Base";
import { useNavigate } from "react-router-dom";
import BodyType from "./BodyType";
import BrandSection from "./BrandSection";
import FeaturedSectionUsedCars from "./FeaturedSectionUsedCars";
import LifeStyle from "./LifeStyle";
// import FeaturedSectionNewCars from "./FeaturedSectionNewCars";

const Home = () => {
  const navigate = useNavigate();
  const { Width } = useScreenSize();
  const [Content] = useState([
    {
      big: carOneBig,
      small: carOne,
      title: "Car you will love to buy",
      about:
        "trusted by over 20,000 car buyers Choose from 2000+ CarKarobar Assured cars",
      clickText: "see all cars",
      hipperLink: "/sell-car",
    },
    {
      big: carTwoBig,
      small: carTwo,
      title: "Sell Contactless experience with extra care",
      about: "home test drive | online booking | home delivery",
      clickText: "free home test drive",
      hipperLink: "/sell-car",
    },
    {
      big: carThreeBig,
      small: carThree,
      title: "Sell your car in 10 seconds",
      about:
        "get instant online quote | free doorstep  evaluation | same  day payment",
      clickText: "check car valuation",
      hipperLink: "/sell-car",
    },
  ]);

  return (
    <Base>
      {/* <SecondHeader /> */}
      <Slider auto={2500}>
        {Content.map((item, index) => {
          return (
            <SliderBanner
              key={index}
              image={Width > 860 ? item.big : item.small}
              title={item.title}
              about={item.about}
              clickText={item.clickText}
              clickAction={() => navigate(item.hipperLink)}
            />
          );
        })}
      </Slider>
      <LifeStyle />
      <BrandSection />
      <BodyType />
      {/* <FeaturedSectionNewCars /> */}
      <FeaturedSectionUsedCars />
    </Base>
  );
};

export default Home;
