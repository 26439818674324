import LifeStyleCard from "components/LifeStyleCard";
import Section from "components/Section";
import { useState } from "react";

const LifeStyle = () => {
  const [Category] = useState([
    {
      image:
        "https://media.istockphoto.com/photos/multigenerational-family-traveling-picture-id1266302304?b=1&k=20&m=1266302304&s=170667a&w=0&h=wICtS0E42mWeXmB_udok2ZV0UrD5aTzmOpDNJ2f4aF4=",
      title: "FAMILY",
      about: "Take everyone along",
    },
    {
      image:
        "https://images.unsplash.com/photo-1504629879392-188ff00bcc7b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDN8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=600&q=60",
      title: "ADVENTURE",
      about: "Take the longer way",
    },
    {
      image:
        "https://images.unsplash.com/photo-1614026480241-23ba865d1ad0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fHZlaGljbGV8ZW58MHx8MHx8&auto=format&fit=crop&w=600&q=60",
      title: "VALUE",
      about: "Easy economic options",
    },
    {
      image:
        "https://images.unsplash.com/photo-1621360241854-f25c497f3290?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDh8fGNhciUyMGRyaXZpbmd8ZW58MHwwfDB8fA%3D%3D&auto=format&fit=crop&w=600&q=60",
      title: "COMMUTER",
      about: "Your daily companion",
    },
    {
      image:
        "https://images.unsplash.com/photo-1632548260498-b7246fa466ea?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80",
      title: "LUXURY",
      about: "Announce your arrival",
    },
    {
      image:
        "https://images.unsplash.com/photo-1621360241340-ebe26ca98b6b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80",
      title: "FEATURE PACKED",
      about: "Everything you need",
    },
  ]);
  return (
    <Section
      title="cars by lifestyle"
      Style={{
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
      }}
    >
      {Category.map((item, index) => {
        return (
          // <Link key={index} to={`/buy-car/?category=${item.title}`}>
          <LifeStyleCard
            key={index}
            image={item.image}
            title={item.title}
            about={item.about}
          />
          // </Link>
        );
      })}
    </Section>
  );
};

export default LifeStyle;
