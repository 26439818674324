import InputFieldStyle from "css/core/InputField.module.css";
import { useState } from "react";
import { FileInput } from "./inputFields";

const InputField = ({
  name,
  title,
  type,
  Icon,
  fieldName,
  Value,
  onChange,
  placeholder,
  errorMessage,
  handleBlur,
  onError,
  backGroundColor,
  setFieldValue,
  accept,
  resetImage,
  initialImage,
  options,
  ValidateImage,
  Style,
  nameStyleClassName,
  inputClassName,
  onTouched,
  datalist,
  ...props
}) => {
  const [PasswordVisibility, setPasswordVisibility] = useState(false);
  const [ImageError, setImageError] = useState("");

  return (
    <label
      htmlFor={fieldName}
      className={`${InputFieldStyle.lable} `}
      style={Style}
    >
      <h5 className={`${InputFieldStyle.name} ${nameStyleClassName}`}>
        {name}
      </h5>
      {type === "file" ? (
        <FileInput
          initialImage={initialImage}
          placeholder={placeholder}
          setFieldValue={setFieldValue}
          accept={accept}
          type={type}
          fieldName={fieldName}
          ValidateImage={ValidateImage}
          setImageError={setImageError}
          onError={onError}
          inputClassName={inputClassName}
          {...props}
        />
      ) : (
        <span
          style={{ backgroundColor: backGroundColor }}
          className={`${
            type !== "textfield"
              ? InputFieldStyle.inputWarper
              : InputFieldStyle.textareaWarper
          } ${inputClassName} ${onError && InputFieldStyle.WrongInput} ${
            onTouched && InputFieldStyle.active
          }`}
        >
          {Icon}
          {type === "textfield" ? (
            <textarea
              rows="5"
              cols="40"
              id={fieldName}
              className={` ${InputFieldStyle.textarea} `}
              title={title}
              onChange={onChange}
              value={Value}
              name={fieldName}
              placeholder={placeholder}
              {...props}
            ></textarea>
          ) : type === "select" ? (
            <select
              id={fieldName}
              className={`${InputFieldStyle.input} `}
              title={title}
              onChange={onChange}
              value={Value}
              onBlur={handleBlur}
              type={PasswordVisibility ? "text" : type}
              name={fieldName}
              placeholder={placeholder}
              {...props}
            >
              {options?.map((opt, index) => {
                return (
                  <option key={index} value={opt}>
                    {opt}
                  </option>
                );
              })}
            </select>
          ) : (
            <>
              <input
                id={fieldName}
                className={`${InputFieldStyle.input} `}
                title={title}
                list={`${fieldName}-datalist`}
                onChange={onChange}
                value={Value}
                onBlur={handleBlur}
                type={PasswordVisibility ? "text" : type}
                name={fieldName}
                placeholder={placeholder}
                {...props}
              />
              {datalist && (
                <datalist id={`${fieldName}-datalist`}>
                  {datalist.map((value, index) => (
                    <option key={index} value={value}></option>
                  ))}
                </datalist>
              )}
            </>
          )}
          {type === "password" && (
            <span
              onClick={() => setPasswordVisibility(!PasswordVisibility)}
              className="material-icons-round"
            >
              {PasswordVisibility ? "visibility_off" : "visibility"}
            </span>
          )}
        </span>
      )}
      <span className={InputFieldStyle.errorMessageWarper}>
        {errorMessage}
        {ImageError && (
          <p style={{ color: "red", fontSize: "0.8em" }}>*{ImageError}</p>
        )}
      </span>
    </label>
  );
};

export default InputField;
