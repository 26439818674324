import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import { Edit } from "@mui/icons-material";
import { emptyRemarks } from "assets";
import { database } from "config";
import { BottomShutter } from "core";
import { RemarkForm } from "forms";
import { QueriesForm } from "forms";
import { useAppContext, useIsMounted } from "hooks";
import { useEffect, useState } from "react";

const BookYourService = () => {
  const [BookYourServiceQueries, setBookYourServiceQueries] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const { setAlertMessage } = useAppContext();
  const IsMounted = useIsMounted();
  const [remarkRef, setRemarkRef] = useState("");

  useEffect(() => {
    (async () => {
      try {
        await database
          .ref("BookYourServiceQueries")
          .on("value", async (snapshot) => {
            const data = await snapshot.val();
            if (data) {
              const allBookings = Object.entries(data).map((item) => item[1]);
              IsMounted.current && setBookYourServiceQueries(allBookings);
            }
          });
      } catch (error) {
        console.log(error);
      } finally {
        IsMounted.current && setIsLoading(false);
      }
    })();
  }, [IsMounted]);

  const [ReceiverId, setReceiverId] = useState(false);

  return (
    <div style={{ width: "100%" }}>
      <MaterialTable
        isLoading={isLoading}
        data={BookYourServiceQueries.sort(
          (a, b) => new Date(b?.timestamp) - new Date(a?.timestamp)
        ).map((item, index) => ({
          ...item,
          sl_no: index + 1,
          ageing: `${parseInt(
            new Date(new Date() - new Date(item?.timestamp)).getTime() /
              (1000 * 60 * 60 * 24)
          )} days`,
          QueryStatus: item?.QueryStatus || "Open",
        }))}
        columns={[
          { title: "Sl No", field: "sl_no", editable: "never" },
          { title: "Name", field: "name", editable: "never" },
          { title: "Email", field: "email", editable: "never" },
          { title: "Phone Number", field: "phoneNumber", editable: "never" },
          { title: "Location", field: "location", editable: "never" },
          { title: "Ageing", field: "ageing", editable: "never" },
          {
            title: "Date",
            field: "timestamp",
            render: (rowData) => (
              <p>{new Date(rowData.timestamp).toLocaleString()}</p>
            ),
            editable: "never",
          },
          {
            title: "Status",
            field: "QueryStatus",
            emptyValue: "Open",
            lookup: {
              Open: "Open",
              Successful: "Successful",
              Lost: "Lost",
            },
          },
          {
            title: "Remark",
            field: "remarks",
            editable: "never",
            render: (rowData) => {
              return (
                <Edit
                  color="primary"
                  onClick={() =>
                    setRemarkRef(`BookYourServiceQueries/${rowData.id}`)
                  }
                  className="CP"
                />
              );
            },
          },
        ]}
        title="Book Your Service"
        actions={[
          {
            icon: "send",
            tooltip: " Send Notification",
            onClick: (event, rowData) => setReceiverId(rowData.sendBy),
          },
        ]}
        detailPanel={({ rowData }) => {
          return (
            <div className="boxContainer">
              <b className="boxContainerTitle">Remarks:</b>
              {rowData?.remarks ? (
                <p>{rowData?.remarks}</p>
              ) : (
                <span
                  className="imageContainer"
                  style={{
                    backgroundImage: `url(${emptyRemarks})`,
                    height: "8em",
                  }}
                ></span>
              )}
            </div>
          );
        }}
        cellEditable={{
          onCellEditApproved: async (
            newValue,
            oldValue,
            rowData,
            columnDef
          ) => {
            return new Promise(async (resolve, reject) => {
              try {
                await database
                  .ref(`BookYourServiceQueries/${rowData.id}`)
                  .update({ QueryStatus: newValue });
                setAlertMessage({
                  type: "info",
                  message: "Status Updated Successfully",
                });
                resolve("successful");
              } catch (error) {
                console.log(error);
                reject("failed");
                setAlertMessage({
                  type: "error",
                  message: "Something Went Wrong",
                });
              }
            });
          },
        }}
        options={{
          actionsColumnIndex: -1,
          exportAllData: true,
          exportMenu: [
            {
              label: "Export PDF",
              exportFunc: (cols, datas) =>
                ExportPdf(
                  cols,
                  datas,
                  `All Book Your Service Queries - ${new Date()}`
                ),
            },
            {
              label: "Export CSV",
              exportFunc: (cols, datas) =>
                ExportCsv(
                  cols,
                  datas,
                  `All Book Your Service Queries - ${new Date()}`
                ),
            },
          ],
        }}
      />
      <BottomShutter IsOpen={!!remarkRef} closeFC={() => setRemarkRef(false)}>
        <RemarkForm
          databaseRef={remarkRef}
          postSubmit={() => setRemarkRef(false)}
        />
      </BottomShutter>
      <BottomShutter
        IsOpen={!!ReceiverId}
        closeFC={() => setReceiverId(false)}
        info={ReceiverId}
      >
        <QueriesForm
          title="Book Your Service Support"
          Id={ReceiverId}
          postSubmit={() => setReceiverId(false)}
        />
      </BottomShutter>
    </div>
  );
};

export default BookYourService;
