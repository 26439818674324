import React from "react";
import StepperStyle from "css/core/Stepper.module.css";

const Stepper = ({ children, step }) => {
  return (
    <div className={StepperStyle.container}>
      {/* <div className={StepperStyle.progress}></div> */}
      {children.length > step ? children[step] : children[children.length - 1]}
    </div>
  );
};

export default Stepper;
