import SliderBannerStyle from "css/components/SliderBanner.module.css";
import Button from "core/Button";

const SliderBanner = ({
  image,
  title,
  about,
  clickAction,
  clickText,
  Style,
}) => {
  return (
    <div
      className={SliderBannerStyle.SliderElements}
      style={{
        backgroundImage: `url(${image})`,
        ...Style,
      }}
    >
      <div className={SliderBannerStyle.contentWarper}>
        <aside className={SliderBannerStyle.content}>
          <h1 className={SliderBannerStyle.title}>{title}</h1>
          <b className={SliderBannerStyle.about}>{about}</b>
          {(clickText || clickAction) && (
            <Button onClick={() => clickAction && clickAction()}>
              {clickText}
            </Button>
          )}
        </aside>
      </div>
    </div>
  );
};

export default SliderBanner;
