import ButtonStyle from "css/core/Button.module.css";

const Button = ({
  children,
  design,
  LastIcon,
  className,
  loading,
  color,
  style,
  disabled,
  ...props
}) => {
  switch (design) {
    case 1:
      return (
        <button
          style={{ borderColor: color, ...style, color: color }}
          disabled={loading || disabled}
          className={`${ButtonStyle.ButtonA} ${ButtonStyle.AOne} ${className}`}
          {...props}
        >
          {loading ? <span className="loaderOneWhite"></span> : children}
          {LastIcon}
        </button>
      );
    case 2:
      return (
        <button
          style={{ borderColor: color, ...style, color: color }}
          disabled={loading || disabled}
          className={`${ButtonStyle.ButtonB} ${ButtonStyle.BThree} ${className}`}
          {...props}
        >
          {loading ? <span className="loaderOneWhite"></span> : children}
          {LastIcon}
        </button>
      );
    default:
      return (
        <button
          style={{ borderColor: color, ...style, color: color }}
          disabled={loading || disabled}
          className={`${ButtonStyle.ButtonA} ${ButtonStyle.AFour} ${className}`}
          {...props}
        >
          {loading ? <span className="loaderOneWhite"></span> : children}
          {LastIcon}
        </button>
      );
  }
};

export default Button;
