import BrakeDownServicePageStyle from "css/pages/financePage/FinancePage.module.css";
import { InfoGatherForm } from "forms";
import { Base } from "layout";

const BrakeDownServicePage = () => {
  return (
    <Base>
      <div className={BrakeDownServicePageStyle.Warper}>
        <div className={BrakeDownServicePageStyle.container}>
          <div
            className={BrakeDownServicePageStyle.imageSection}
            style={{
              backgroundImage: `url(https://images.unsplash.com/photo-1626630336933-8646e97e3740?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=718&q=80)`,
            }}
          >
            <h1 className={`${BrakeDownServicePageStyle.title}`}>
              Break Down Service <br /> Enquiry
            </h1>
          </div>
          <div className={BrakeDownServicePageStyle.formSection}>
            <div className={BrakeDownServicePageStyle.formWarper}>
              <h1 className={`STUL ${BrakeDownServicePageStyle.formTitle}`}>
                Break Down Service Enquiry
              </h1>
              <InfoGatherForm />
            </div>
          </div>
        </div>
      </div>
    </Base>
  );
};

export default BrakeDownServicePage;
