import TabDataStyle from "css/core/TabData.module.css";

const TabData = ({ children, headers }) => {
  return (
    <div className={TabDataStyle.container}>
      <header className={TabDataStyle.header}>
        {headers.map(
          (item, index) =>
            item && (
              <span
                key={index}
                onClick={() => (window.location.href = `#${item.key}`)}
                className={TabDataStyle.tabes}
              >
                {item.title}
              </span>
            )
        )}
      </header>
      <div className={TabDataStyle.content}>
        {children.map((item, index) => {
          return (
            <div key={index} id={`${item.key}`}>
              {item}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TabData;
