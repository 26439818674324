import ProgressIndicationStyle from "css/core/ProgressIndication.module.css";

const ProgressIndication = ({ currentStage, totalStage, width }) => {
  return (
    <div
      className={ProgressIndicationStyle.container}
      style={{ width: `${width}%` }}
    >
      {[...Array(totalStage + 1)].map((item, index) => {
        return (
          <span
            className={`${ProgressIndicationStyle.progressPoint} ${
              currentStage - 1 >= index && ProgressIndicationStyle.active
            } ${currentStage === index && ProgressIndicationStyle.pointActive}`}
            key={index}
          >
            {currentStage - 1 >= index ? "✓" : index + 1}
          </span>
        );
      })}
      <span
        className={ProgressIndicationStyle.progress}
        style={{
          width: `${
            (100 / totalStage) *
            (currentStage <= totalStage ? currentStage : totalStage)
          }%`,
        }}
      ></span>
    </div>
  );
};

export default ProgressIndication;
