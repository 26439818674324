import PreLoaderStyle from "css/core/PreLoader.module.css";

const PreLoader = () => {
  return (
    <div className={PreLoaderStyle.container}>
      <span className={PreLoaderStyle.Two}>
        <span className={PreLoaderStyle.One}></span>
      </span>
    </div>
  );
};

export default PreLoader;
