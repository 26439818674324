import BottomNavStyle from "css/components/header/BottomNav.module.css";
import { useAppContext } from "hooks";
import { NavLink } from "react-router-dom";

const BottomNav = () => {
  const { wishlist } = useAppContext();

  return (
    <div className={BottomNavStyle.container}>
      <NavLink
        to="/"
        className={({ isActive }) =>
          `${BottomNavStyle.menuItem} ${isActive && BottomNavStyle.active}`
        }
      >
        <span className="material-icons-round">home</span>
        <p>home</p>
      </NavLink>
      <NavLink
        to="/buy-car"
        className={({ isActive }) =>
          `${BottomNavStyle.menuItem} ${isActive && BottomNavStyle.active}`
        }
      >
        <span className="material-icons-round">directions_car</span>
        <p>cars</p>
      </NavLink>
      <NavLink
        to="/account/wishlist"
        // className={wishlist?.length > 0 ? HeaderStyle.wishListCount : ""}
        className={({ isActive }) =>
          `${BottomNavStyle.menuItem} ${isActive && BottomNavStyle.active} `
        }
      >
        <span
          data-count={wishlist?.length > 0 ? wishlist?.length : ""}
          className={wishlist?.length > 0 ? BottomNavStyle.wishListCount : ""}
        >
          <span className="material-icons-round">favorite</span>
        </span>
        <p>shortlists</p>
      </NavLink>
      <NavLink
        to="/account/account-information"
        className={({ isActive }) =>
          `${BottomNavStyle.menuItem} ${isActive && BottomNavStyle.active}`
        }
      >
        <span className="material-icons-round">account_circle</span>
        <p>account</p>
      </NavLink>
    </div>
  );
};

export default BottomNav;
