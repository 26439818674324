import ProfileCardStyle from "css/components/ProfileCard.module.css";

const ProfileCard = ({ avatar, name }) => {
  return (
    <div className={ProfileCardStyle.container}>
      <div
        className={ProfileCardStyle.avatar}
        style={{ backgroundImage: `url(${avatar})` }}
      ></div>
      <div className={ProfileCardStyle.info}>
        <p>Hello,</p>
        <b className={ProfileCardStyle.title}>{name}</b>
      </div>
    </div>
  );
};

export default ProfileCard;
