import { useAppContext } from "hooks";
import { Navigate } from "react-router-dom";

const Authorized = ({ children }) => {
  const { user } = useAppContext();

  if (user) {
    return children;
  } else {
    return (
      <>
        <Navigate to="/#signin-now" />
      </>
    );
  }
};

export default Authorized;
