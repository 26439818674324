import React from "react";
import CardSectionStyle from "css/pages/admin/dashboard/CardSection.module.css";
import TotalNumberCard from "./TotalNumberCard";
import { useState } from "react";
import { useEffect } from "react";
import { database } from "config";
import { useIsMounted } from "hooks";

const StatusCardSection = () => {
  const [OpenStatus, setOpenStatus] = useState([]);
  const [OpenStatusLoading, setOpenStatusLoading] = useState([]);

  const [SuccessCount, setSuccessCount] = useState([]);
  const [SuccessCountLoading, setSuccessCountLoading] = useState([]);

  const [LostCount, setLostCount] = useState([]);
  const [LostCountLoading, setLostCountLoading] = useState([]);

  const IsMounted = useIsMounted();

  useEffect(() => {
    (async () => {
      let allQueries = [];
      try {
        IsMounted.current &&
          (await database
            .ref("InsuranceQueries")
            .once("value", async (snapshot) => {
              const data = await snapshot.val();
              if (data) {
                const allBookings = Object.entries(data).map((item) => item[1]);
                allQueries = [...allQueries, ...allBookings];
              }
            }));
        IsMounted.current &&
          (await database
            .ref("RegistrationQueries")
            .once("value", async (snapshot) => {
              const data = await snapshot.val();
              if (data) {
                const allBookings = Object.entries(data).map((item) => item[1]);
                allQueries = [...allQueries, ...allBookings];
              }
            }));
        IsMounted.current &&
          (await database
            .ref("FinanceQueries")
            .once("value", async (snapshot) => {
              const data = await snapshot.val();
              if (data) {
                const allBookings = Object.entries(data).map((item) => item[1]);
                allQueries = [...allQueries, ...allBookings];
              }
            }));
        IsMounted.current &&
          (await database
            .ref("BookYourServiceQueries")
            .once("value", async (snapshot) => {
              const data = await snapshot.val();
              if (data) {
                const allBookings = Object.entries(data).map((item) => item[1]);
                allQueries = [...allQueries, ...allBookings];
              }
            }));
        IsMounted.current &&
          (await database
            .ref("BreakDownServiceQueries")
            .once("value", async (snapshot) => {
              const data = await snapshot.val();
              if (data) {
                const allBookings = Object.entries(data).map((item) => item[1]);
                allQueries = [...allQueries, ...allBookings];
              }
            }));
        IsMounted.current &&
          setOpenStatus(
            allQueries.filter(
              (item) => item.QueryStatus === "open" || !item.QueryStatus
            )
          );
        IsMounted.current &&
          setSuccessCount(
            allQueries.filter((item) => item.QueryStatus === "Successful")
          );
        IsMounted.current &&
          setLostCount(
            allQueries.filter((item) => item.QueryStatus === "Lost")
          );
      } catch (error) {
        console.log(error);
      } finally {
        setOpenStatusLoading(false);
        setSuccessCountLoading(false);
        setLostCountLoading(false);
      }
    })();
  }, [IsMounted]);

  return (
    <div className={CardSectionStyle.statusContainer}>
      <TotalNumberCard>
        <span className="material-icons-round md-light">import_contacts</span>
        <p>Total Open Status (leads)</p>
        {OpenStatusLoading ? (
          <div className="loaderOneBlue"></div>
        ) : (
          <h1>{OpenStatus.length}</h1>
        )}
      </TotalNumberCard>
      <TotalNumberCard>
        <span className="material-icons-round md-light">verified</span>
        <p>Total Success Status (leads)</p>
        {SuccessCountLoading ? (
          <div className="loaderOneBlue"></div>
        ) : (
          <h1>{SuccessCount.length}</h1>
        )}
      </TotalNumberCard>
      <TotalNumberCard>
        <span className="material-icons-round md-light">dangerous</span>
        <p>Total Lost Status (leads)</p>
        {LostCountLoading ? (
          <div className="loaderOneBlue"></div>
        ) : (
          <h1>{LostCount.length}</h1>
        )}
      </TotalNumberCard>
    </div>
  );
};

export default StatusCardSection;
