import FooterStyle from "css/components/footer/Footer.module.css";
import BottomSection from "./BottomSection";
import ContactSection from "./ContactSection";
import UsefulLinkSection from "./UsefulLinkSection";
const Footer = () => {
  return (
    <footer className={FooterStyle.warper}>
      <section className={FooterStyle.container}>
        <div className={FooterStyle.usefulLinksSection}>
          <UsefulLinkSection />
        </div>
        <div className={FooterStyle.contactSection}>
          <ContactSection />
        </div>
        <div className={FooterStyle.bottomSection}>
          <BottomSection />
        </div>
      </section>
    </footer>
  );
};

export default Footer;
