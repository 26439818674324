import FinanceForm from "forms/FinanceForm";
import { Base } from "layout";
import FinancePageStyle from "css/pages/financePage/FinancePage.module.css";

const FinancePage = () => {
  return (
    <Base>
      <div className={FinancePageStyle.Warper}>
        <div className={FinancePageStyle.container}>
          <div
            className={FinancePageStyle.imageSection}
            style={{
              backgroundImage: `url(https://images.unsplash.com/photo-1615715073328-39c148b37b23?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8aHl1bmRhaSUyMGNhcnxlbnwwfDF8MHx8&auto=format&fit=crop&w=600&q=60)`,
            }}
          >
            <h1 className={`${FinancePageStyle.title}`}>
              Finance <br /> Enquiry
            </h1>
          </div>
          <div className={FinancePageStyle.formSection}>
            <div className={FinancePageStyle.formWarper}>
              <h1 className={`STUL ${FinancePageStyle.formTitle}`}>
                Finance Enquiry
              </h1>
              <FinanceForm />
            </div>
          </div>
        </div>
      </div>
    </Base>
  );
};

export default FinancePage;
