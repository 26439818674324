import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import { BottomShutter } from "core";
import { QueriesForm } from "forms";
import React, { useEffect, useState } from "react";
import BookingsStyle from "css/pages/admin/bookings/Bookings.module.css";
import { database } from "config";
import { Close, Edit, Visibility } from "@mui/icons-material";
import { useAppContext, useIsMounted } from "hooks";
import {
  AppBar,
  Dialog,
  DialogContent,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ViewCarDetails } from "components";
import { RemarkForm } from "forms";
import { emptyRemarks } from "assets";

const Bookings = () => {
  const [BookingsQueries, setBookingsQueries] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const IsMounted = useIsMounted();
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [details, setDetails] = useState({});
  const [IsDetailsLoading, setIsDetailsLoading] = useState(false);
  const classes = useStyles();
  const { setAlertMessage } = useAppContext();
  const [remarkRef, setRemarkRef] = useState("");

  useEffect(() => {
    (async () => {
      try {
        return await database
          .ref("NewCarEnquire")
          .on("value", async (snapshot) => {
            const data = await snapshot.val();
            if (data) {
              const allBookings = Object.entries(data).map((item) => item[1]);
              IsMounted.current && setBookingsQueries(allBookings);
            }
          });
      } catch (error) {
        console.log(error);
      } finally {
        IsMounted.current && setIsLoading(false);
      }
    })();
  }, [IsMounted]);

  const [ReceiverId, setReceiverId] = useState(false);

  return (
    <div style={{ width: "100%" }}>
      <MaterialTable
        isLoading={isLoading}
        data={BookingsQueries.sort(
          (a, b) => new Date(b?.timestamp) - new Date(a?.timestamp)
        ).map((item, index) => ({
          ...item,
          sl_no: index + 1,
          ageing: `${parseInt(
            new Date(new Date() - new Date(item?.timestamp)).getTime() /
              (1000 * 60 * 60 * 24)
          )} days`,
          QueryStatus: item?.QueryStatus || "Open",
        }))}
        columns={[
          { title: "Sl No", field: "sl_no", editable: "never" },
          { title: "Name", field: "name", editable: "never" },
          { title: "Email", field: "email", editable: "never" },
          { title: "Phone Number", field: "phoneNumber", editable: "never" },
          {
            title: "Address",
            field: "address",
            hidden: true,
            export: true,
            editable: "never",
          },
          { title: "Ageing", field: "ageing", editable: "never" },
          {
            title: "Date",
            field: "timestamp",
            render: (rowData) => (
              <p>{new Date(rowData.timestamp).toLocaleString()}</p>
            ),
            editable: "never",
          },
          {
            title: "Status",
            field: "QueryStatus",
            emptyValue: "Open",
            lookup: {
              Open: "Open",
              Successful: "Successful",
              Lost: "Lost",
            },
          },
          {
            title: "Remark",
            field: "remarks",
            editable: "never",
            render: (rowData) => {
              return (
                <Edit
                  color="primary"
                  onClick={() => setRemarkRef(`NewCarEnquire/${rowData.id}`)}
                  className="CP"
                />
              );
            },
          },
        ]}
        cellEditable={{
          onCellEditApproved: async (
            newValue,
            oldValue,
            rowData,
            columnDef
          ) => {
            return new Promise(async (resolve, reject) => {
              try {
                await database
                  .ref(`NewCarEnquire/${rowData.id}`)
                  .update({ QueryStatus: newValue });
                setAlertMessage({
                  type: "info",
                  message: "Status Updated Successfully",
                });
                resolve("successful");
              } catch (error) {
                console.log(error);
                reject("failed");
                setAlertMessage({
                  type: "error",
                  message: "Something Went Wrong",
                });
              }
            });
          },
        }}
        title="Bookings Queries"
        actions={[
          {
            icon: () =>
              IsDetailsLoading ? (
                <span className="loaderOneBlue"></span>
              ) : (
                <Visibility style={{ color: "#0074a1" }} />
              ),
            tooltip: "View",
            onClick: async (event, rowData) => {
              try {
                IsMounted.current && setIsDetailsLoading(true);
                await database
                  .ref(`NewCars/${rowData?.carId}`)
                  .once("value", (snapshot) => {
                    const data = snapshot.val();
                    if (data) {
                      IsMounted.current && setDetails(data);
                      IsMounted.current && setIsDetailsOpen(true);
                    }
                  });
              } catch (error) {
                console.log(error);
              } finally {
                IsMounted.current && setIsDetailsLoading(false);
              }
            },
          },
          {
            icon: "send",
            tooltip: " Send Notification",
            onClick: (event, rowData) => setReceiverId(rowData.enquireBy),
          },
        ]}
        detailPanel={({ rowData }) => {
          return (
            <>
              <div className="boxContainer">
                <b className="boxContainerTitle">Remarks:</b>
                {rowData?.remarks ? (
                  <p>{rowData?.remarks}</p>
                ) : (
                  <span
                    className="imageContainer"
                    style={{
                      backgroundImage: `url(${emptyRemarks})`,
                      height: "8em",
                    }}
                  ></span>
                )}
              </div>
              <div className={BookingsStyle.detailBookingInfo}>
                <div className={BookingsStyle.DetailsPanel}>
                  <b className={BookingsStyle.title}>Address: </b>
                  <p className={BookingsStyle.address}>
                    {rowData?.address || "-"}
                  </p>
                </div>
                <div className={BookingsStyle.DetailsPanel}>
                  <b className={BookingsStyle.title}>Finance Support: </b>
                  <p className={BookingsStyle.address}>
                    {rowData?.financeSupport || "-"}
                  </p>
                </div>
                <div className={BookingsStyle.DetailsPanel}>
                  <b className={BookingsStyle.title}>Finance Support Type: </b>
                  <p className={BookingsStyle.address}>
                    {rowData?.financeSupportType || "-"}
                  </p>
                </div>
                <div className={BookingsStyle.DetailsPanel}>
                  <b className={BookingsStyle.title}>Finance Preference: </b>
                  <p className={BookingsStyle.address}>
                    {rowData?.financePreference || "-"}
                  </p>
                </div>
                <div className={BookingsStyle.DetailsPanel}>
                  <b className={BookingsStyle.title}>New Car Make: </b>
                  <p className={BookingsStyle.address}>
                    {rowData?.newCarMake || "-"}
                  </p>
                </div>
                <div className={BookingsStyle.DetailsPanel}>
                  <b className={BookingsStyle.title}>New Car Model: </b>
                  <p className={BookingsStyle.address}>
                    {rowData?.newCarModel || "-"}
                  </p>
                </div>
                <div className={BookingsStyle.DetailsPanel}>
                  <b className={BookingsStyle.title}>Old Car Exchange Plan: </b>
                  <p className={BookingsStyle.address}>
                    {rowData?.oldCarExchangePlan || "-"}
                  </p>
                </div>
                <div className={BookingsStyle.DetailsPanel}>
                  <b className={BookingsStyle.title}>Old Car Make: </b>
                  <p className={BookingsStyle.address}>
                    {rowData?.oldCarMake || "-"}
                  </p>
                </div>
                <div className={BookingsStyle.DetailsPanel}>
                  <b className={BookingsStyle.title}>Old Car Model: </b>
                  <p className={BookingsStyle.address}>
                    {rowData?.oldCarModel || "-"}
                  </p>
                </div>
                <div className={BookingsStyle.DetailsPanel}>
                  <b className={BookingsStyle.title}>
                    Old Car Price Expected:{" "}
                  </b>
                  <p className={BookingsStyle.address}>
                    {rowData?.oldCarPriceExpected || "-"}
                  </p>
                </div>
                <div className={BookingsStyle.DetailsPanel}>
                  <b className={BookingsStyle.title}>
                    Old Car Registration Number:{" "}
                  </b>
                  <p className={BookingsStyle.address}>
                    {rowData?.oldCarRegistrationNo || "-"}
                  </p>
                </div>
                <div className={BookingsStyle.DetailsPanel}>
                  <b className={BookingsStyle.title}>Phone Number: </b>
                  <p className={BookingsStyle.address}>
                    {rowData?.phoneNumber || "-"}
                  </p>
                </div>
                <div className={BookingsStyle.DetailsPanel}>
                  <b className={BookingsStyle.title}>Plan TO Purchase: </b>
                  <p className={BookingsStyle.address}>
                    {rowData?.planTOPurchase || "-"}
                  </p>
                </div>
                <div className={BookingsStyle.DetailsPanel}>
                  <b className={BookingsStyle.title}>Age Of Vehicle: </b>
                  <p className={BookingsStyle.address}>
                    {rowData?.ageOfVehicle || "-"}
                  </p>
                </div>
                <div className={BookingsStyle.DetailsPanel}>
                  <b className={BookingsStyle.title}>
                    Any Other Make On Consideration:{" "}
                  </b>
                  <p className={BookingsStyle.address}>
                    {rowData?.anyOtherMakeOnConsideration || "-"}
                  </p>
                </div>
                <div className={BookingsStyle.DetailsPanel}>
                  <b className={BookingsStyle.title}>
                    Any Other Model On Consideration:{" "}
                  </b>
                  <p className={BookingsStyle.address}>
                    {rowData?.anyOtherModelOnConsideration || "-"}
                  </p>
                </div>
                <div className={BookingsStyle.DetailsPanel}>
                  <b className={BookingsStyle.title}>user:</b>
                  <p className={BookingsStyle.address}>
                    {rowData?.user || "-"}
                  </p>
                </div>
              </div>
            </>
          );
        }}
        options={{
          actionsColumnIndex: -1,
          exportAllData: true,
          exportMenu: [
            {
              label: "Export PDF",
              exportFunc: (cols, datas) =>
                ExportPdf(cols, datas, `All Bookings Queries - ${new Date()}`),
            },
            {
              label: "Export CSV",
              exportFunc: (cols, datas) =>
                ExportCsv(cols, datas, `All Bookings Queries - ${new Date()}`),
            },
          ],
        }}
      />
      <BottomShutter IsOpen={!!remarkRef} closeFC={() => setRemarkRef(false)}>
        <RemarkForm
          databaseRef={remarkRef}
          postSubmit={() => setRemarkRef(false)}
        />
      </BottomShutter>
      <BottomShutter
        IsOpen={!!ReceiverId}
        closeFC={() => setReceiverId(false)}
        info={ReceiverId}
      >
        <QueriesForm
          title="Bookings Support"
          Id={ReceiverId}
          postSubmit={() => setReceiverId(false)}
        />
      </BottomShutter>
      <Dialog
        fullScreen
        open={isDetailsOpen}
        onClose={() => IsMounted.current && setIsDetailsOpen(false)}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              View Car Details
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => IsMounted.current && setIsDetailsOpen(false)}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <ViewCarDetails data={details} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    // marginLeft: theme.spacing(2),
    flex: 1,
  },
  table: {
    minWidth: 100,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default Bookings;
