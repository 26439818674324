import React, { useCallback, useEffect, useRef, useState } from "react";
import SliderStyle from "css/core/Slider.module.css";

const Slider = ({ children, auto }) => {
  const isSlide = useRef();
  const Scrollable = useRef();
  const [ScrollPosition, setScrollPosition] = useState(0);
  const ScrollLeft = useCallback(() => {
    if (
      ScrollPosition ===
      Scrollable?.current?.offsetWidth * (children.length - 1)
    ) {
      return Scrollable?.current?.scrollTo(0, 0);
    }
    Scrollable?.current?.scrollBy(Scrollable?.current?.clientWidth, 0);
  }, [ScrollPosition, children]);
  const ScrollRight = () => {
    if (!ScrollPosition) {
      return Scrollable?.current?.scrollTo(Scrollable.current.scrollWidth, 0);
    }
    Scrollable?.current?.scrollBy(-Scrollable?.current?.clientWidth, 0);
  };

  //for auto slide
  useEffect(() => {
    const interval = setInterval(() => {
      ScrollLeft();
    }, auto || 5000);
    return () => clearInterval(interval);
  }, [ScrollLeft, auto]);

  // for mouse listening for slide pause
  const mouseOverHandler = (e) => {
    if (e.type === "mouseover") {
      isSlide.current = true;
    } else if (e.type === "mouseleave") {
      isSlide.current = false;
    }
  };

  //Dots click actions
  const navigateSlider = (e) => {
    const scrollLocation =
      Scrollable?.current?.clientWidth * +e.target.dataset.banner;
    Scrollable?.current?.scrollTo(scrollLocation, 0);
  };

  return (
    <div
      className={SliderStyle.Slider}
      onMouseOver={mouseOverHandler}
      onMouseLeave={mouseOverHandler}
    >
      <div
        onScroll={(e) => setScrollPosition(e.target.scrollLeft)}
        ref={Scrollable}
        id="SlideContainer"
        className={SliderStyle.SlideWarper}
      >
        {children}
        {/* arrows for scroll */}
      </div>
      <span onClick={ScrollRight} className={SliderStyle.leftArrow}>
        <span className="material-icons-round">arrow_back_ios</span>
      </span>
      <span onClick={ScrollLeft} className={SliderStyle.rightArrow}>
        <span className="material-icons-round">arrow_forward_ios</span>
      </span>
      <div className={SliderStyle.DotsWarper}>
        {children?.map((item, index) => {
          return (
            //TODO: Animate the Dotes
            <div
              data-banner={index}
              className={`${SliderStyle.Dots} ${
                ScrollPosition / Scrollable.current?.offsetWidth === index &&
                SliderStyle.activeDots
              }`}
              key={index}
              onClick={navigateSlider}
            ></div>
          );
        })}
      </div>
    </div>
  );
};

export default Slider;
