import { useCallback, useEffect, useState } from "react";
import useIsMounted from "./useIsMounted";

const useScrollReveal = ({ target }) => {
  const [IsReveal, setIsReveal] = useState(false);
  const IsMounted = useIsMounted();

  const revealPoint = 50;

  const GetRevealPosition = useCallback(() => {
    // console.log(document.querySelector(".WebProjects-website-img"));
    let windowHeight = window.innerHeight;
    let revealTop = target && target.getBoundingClientRect().top;

    if (revealTop < windowHeight - revealPoint) {
      IsMounted && setIsReveal(true);
    }
  }, [IsMounted, target]);

  useEffect(() => {
    let windowHeight = window.innerHeight;
    let revealTop = target && target.getBoundingClientRect().top;

    if (revealTop < windowHeight - revealPoint) {
      IsMounted && setIsReveal(true);
    }
    document.addEventListener("scroll", GetRevealPosition);
    return () => {
      document.removeEventListener("scroll", GetRevealPosition);
    };
  }, [GetRevealPosition, IsMounted, revealPoint, target]);

  return { IsReveal };
};

export default useScrollReveal;
