import { database } from "config";
import { useCallback, useEffect, useState } from "react";
import useIsMounted from "./useIsMounted";

const useAllCars = () => {
  const IsMounted = useIsMounted();
  const [isLoading, setIsLoading] = useState(true);

  const [allCars, setAllCars] = useState([]);

  const getAllCars = useCallback(async () => {
    setIsLoading(true);
    // for getting NEW CARS
    setAllCars([]);
    // IsMounted.current &&
    //   (await database.ref(`NewCars`).once("value", (snapshot) => {
    //     const data = snapshot.val();
    //     data &&
    //       IsMounted.current &&
    //       setAllCars((prev) => [
    //         ...prev,
    //         ...Object.entries(data).map((item) => ({
    //           ...item[1],
    //           type: "NEW",
    //         })),
    //       ]);
    //   }));

    // for getting USED CARS
    IsMounted.current &&
      (await database.ref(`InventoryCars`).once("value", async (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const stores = Object.entries(data).map((item) => item[0]);
          stores.forEach(async (storeId) => {
            await database
              .ref(`InventoryCars/${storeId}`)
              .once("value", async (snapshot) => {
                const storeData = snapshot.val();
                if (storeData && IsMounted.current) {
                  setAllCars((prev) => [
                    ...prev,
                    ...Object.entries(storeData).map((item) => ({
                      ...item[1]?.ownerDetails?.inventoryData,
                      type: "USED",
                      id: item[0],
                      storeId: storeId,
                    })),
                  ]);
                  setIsLoading(false);
                }
              });
          });
        }
      }));
  }, [IsMounted]);

  useEffect(() => {
    IsMounted.current && getAllCars();
  }, [IsMounted, getAllCars]);

  return { allCars, isLoading };
};

export default useAllCars;
