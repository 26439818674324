export const DateFormat = (date) => {
  if (date === "0000-00-00") {
    return date;
  }
  return new Date(date).toDateString();
};

export const PercentageConverter = (newNumber, OriginalNumber) => {
  return `${parseInt(((OriginalNumber - newNumber) / OriginalNumber) * 100)}%`;
};

export const isHexColor = (str) =>
  /^#?([0-9a-fA-F]{3}|[0-9a-fA-F]{6})$/.test(str);

export const ImageInfo = (file) => {
  return new Promise((resolve) => {
    const image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = () => {
      resolve(image);
    };
  });
};
