import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import { Close, Delete, Edit, Visibility } from "@mui/icons-material";
import {
  AppBar,
  Dialog,
  DialogContent,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { EditInventory, ViewCarDetails } from "components";
import { storage } from "config";
import { database } from "config";
import { Avatar, Button } from "core";
import { useAppContext, useIsMounted } from "hooks";
import React, { useEffect, useState } from "react";

const ViewInventory = () => {
  const IsMounted = useIsMounted();
  const [inventory, setInventory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleteActive, setIsDeleteActive] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { setAlertMessage } = useAppContext();

  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const classes = useStyles();
  const [details, setDetails] = useState({});

  const [IsEditOpen, setIsEditOpen] = useState(false);

  useEffect(() => {
    (async () => {
      IsMounted &&
        database.ref(`NewCars`).on("value", (snapshot) => {
          const data = snapshot.val();
          if (data && IsMounted) {
            setInventory(Object.entries(data).map((item) => item[1]));
          }
          IsMounted && setIsLoading(false);
        });
    })();
  }, [IsMounted]);

  const handleDelete = async () => {
    try {
      setIsDeleting(true);
      console.log(isDeleteActive);
      await database.ref(`NewCars/${isDeleteActive}`).remove();
      const listFile = await storage.ref(`NewCars/${isDeleteActive}`).listAll();
      await listFile.items.forEach((item) => {
        item.delete();
      });
    } catch (error) {
      console.log(error);
      setAlertMessage({ type: "error", message: "Unable to delete item" });
    } finally {
      setIsDeleteActive(false);
      setIsDeleting(false);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <MaterialTable
        isLoading={isLoading}
        title="View Inventory"
        data={inventory
          .sort((a, b) => new Date(b?.timestamp) - new Date(a?.timestamp))
          .map((item, index) => ({
            ...item,
            sl_no: index + 1,
          }))}
        columns={[
          { title: "Sl No", field: "sl_no", editable: "never" },
          {
            title: "Name",
            field: "CarInformationData.brand",
            export: false,
            render: (rowData) => (
              <Avatar
                variant="right-text"
                text={`${rowData.CarInformationData.brand} ${rowData.CarInformationData.model} ${rowData.CarInformationData.variant}`}
                image={rowData.CarImagesFormData.leftFrontCornerImage.url}
              />
            ),
            editable: "never",
          },
          {
            title: "Featured",
            field: "featured",
            type: "boolean",
          },
          {
            title: "Brand",
            field: "CarInformationData.brand",
            hidden: true,
            export: true,
            editable: "never",
          },
          {
            title: "model",
            field: "CarInformationData.model",
            hidden: true,
            export: true,
            editable: "never",
          },
          {
            title: "variant",
            field: "CarInformationData.variant",
            hidden: true,
            export: true,
            editable: "never",
          },
          {
            title: "variant",
            field: "CarInformationData.variant",
            hidden: true,
            export: true,
            editable: "never",
          },
          {
            title: "Car Color",
            field: "CarInformationData.car_color",
            editable: "never",
          },
          {
            title: "Price",
            field: "PriceFormData.price",
            render: (rowData) => (
              <b>{rowData.PriceFormData.price.toLocaleString()}</b>
            ),
            editable: "never",
          },
          {
            title: "Date",
            field: "timestamp",
            render: (rowData) => (
              <p>{new Date(rowData.timestamp).toLocaleString()}</p>
            ),
            editable: "never",
          },
        ]}
        cellEditable={{
          onCellEditApproved: async (
            newValue,
            oldValue,
            rowData,
            columnDef
          ) => {
            return new Promise(async (resolve, reject) => {
              try {
                const res = await database
                  .ref(`NewCars/${rowData.id}`)
                  .update({ featured: newValue });
                resolve(res);
              } catch (error) {
                reject(error);
              }
            });
          },
        }}
        actions={[
          {
            icon: () => <Visibility style={{ color: "#0074a1" }} />,
            tooltip: "View",
            onClick: (event, rowData) => {
              IsMounted.current && setDetails(rowData);
              IsMounted.current && setIsDetailsOpen(true);
            },
          },
          {
            icon: () => <Edit style={{ color: "#0074a1" }} />,
            tooltip: "Edit",
            onClick: (event, rowData) => {
              IsMounted.current && setDetails(rowData);
              IsMounted.current && setIsEditOpen(true);
            },
          },
          {
            icon: () => <Delete style={{ color: "red" }} />,
            tooltip: "Delete",
            onClick: (event, rowData) => {
              IsMounted.current && setIsDeleteActive(rowData.id);
            },
          },
        ]}
        options={{
          actionsColumnIndex: -1,
          exportAllData: true,
          exportMenu: [
            {
              label: "Export PDF",
              exportFunc: (cols, datas) =>
                ExportPdf(
                  cols,
                  datas,
                  `All ViewInventory Queries - ${new Date()}`
                ),
            },
            {
              label: "Export CSV",
              exportFunc: (cols, datas) =>
                ExportCsv(
                  cols,
                  datas,
                  `All ViewInventory Queries - ${new Date()}`
                ),
            },
          ],
        }}
      />
      <Dialog
        open={!!isDeleteActive}
        onClose={() => IsMounted.current && setIsDeleteActive(false)}
        TransitionComponent={Transition}
      >
        <div className="popupConform">
          <p>Are you sure you want to delete this item?</p>
          <Button design={1} onClick={handleDelete} loading={isDeleting}>
            Confirm
          </Button>
        </div>
      </Dialog>
      <Dialog
        fullScreen
        open={isDetailsOpen}
        onClose={() => IsMounted.current && setIsDetailsOpen(false)}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              View Car Details
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => IsMounted.current && setIsDetailsOpen(false)}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <ViewCarDetails data={details} />
        </DialogContent>
      </Dialog>

      <Dialog
        fullScreen
        open={IsEditOpen}
        onClose={() => IsMounted.current && setIsEditOpen(false)}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Edit Car Details
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => IsMounted.current && setIsEditOpen(false)}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <EditInventory
            data={details}
            postSubmit={() => setIsEditOpen(false)}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    // marginLeft: theme.spacing(2),
    flex: 1,
  },
  table: {
    minWidth: 100,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ViewInventory;
