import { hatchback, sedan, suv } from "assets";
import ProductCard from "components/ProductCard";
import Section from "components/Section";
import CardHolder from "core/CardHolder";
import BodyTypeStyle from "css/pages/home/BodyType.module.css";
import { useAllCars } from "hooks";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const BodyType = () => {
  const [ActiveType, setActiveType] = useState("hatchback");
  const { allCars } = useAllCars();

  const [Hatchback, setHatchback] = useState([]);
  const [Sedan, setSedan] = useState([]);
  const [Suv, setSuv] = useState([]);

  const [CarType, setCarType] = useState(Hatchback);

  useEffect(() => {
    setCarType(
      allCars.filter((item) => item?.ExteriorFormData?.bodyType === "HATCHBACK")
    );
    setHatchback(
      allCars.filter((item) => item?.ExteriorFormData?.bodyType === "HATCHBACK")
    );
    setSedan(
      allCars.filter((item) => item?.ExteriorFormData?.bodyType === "SEDAN")
    );
    setSuv(
      allCars.filter((item) => item?.ExteriorFormData?.bodyType === "SUV")
    );
  }, [allCars]);

  return (
    <Section
      title="popular cars by body type"
      Style={{ alignItems: "center", gap: "1em" }}
    >
      <div className={BodyTypeStyle.container}>
        <span
          onClick={() => {
            setActiveType("hatchback");
            setCarType(Hatchback);
          }}
          className={`${BodyTypeStyle.carTypeHolder} ${
            ActiveType === "hatchback" && BodyTypeStyle.active
          }`}
        >
          {hatchback}
          <p className={BodyTypeStyle.title}>Hatchback</p>
        </span>
        <span
          onClick={() => {
            setActiveType("sedan");
            setCarType(Sedan);
          }}
          className={`${BodyTypeStyle.carTypeHolder} ${
            ActiveType === "sedan" && BodyTypeStyle.active
          }`}
        >
          {sedan}
          <p className={BodyTypeStyle.title}>Sedan</p>
        </span>
        <span
          onClick={() => {
            setActiveType("suv");
            setCarType(Suv);
          }}
          className={`${BodyTypeStyle.carTypeHolder} ${
            ActiveType === "suv" && BodyTypeStyle.active
          }`}
        >
          {suv}
          <p className={BodyTypeStyle.title}>SUV</p>
        </span>
      </div>
      {CarType.length ? (
        <CardHolder gap="1em">
          {CarType?.map((item, index) => {
            return item.type === "NEW" ? (
              <ProductCard
                carType="new"
                sliderImages={Object.entries(item.CarImagesFormData).map(
                  (item) => Object.entries(item)[1][1].url
                )}
                id={item.id}
                storeId={item?.storeId}
                key={index}
                image={item?.CarImagesFormData?.leftFrontCornerImage?.url}
                name={`${item?.CarInformationData?.brand} ${item?.CarInformationData?.model} ${item?.CarInformationData?.variant}`}
                price={item?.PriceFormData?.price}
                kilometers={item?.kilometers}
                mileage={item?.FuelAndPerformanceFormData?.mileage}
                fuelType={item?.FuelAndPerformanceFormData?.fuelType}
                transmission={
                  item?.EngineAndTransmissionFormData?.transmissionType
                }
              />
            ) : (
              item.type === "USED" && (
                <ProductCard
                  carType="used"
                  sliderImages={
                    item.CarImagesFormData &&
                    Object.entries(item.CarImagesFormData).map(
                      (images) => Object.entries(images)[1][1].uri
                    )
                  }
                  storeId={item?.storeId}
                  id={item?.id}
                  key={index}
                  image={
                    item?.CarImagesFormData?.leftFrontCornerImage?.uri ||
                    item?.evaluateFrontImages?.uri ||
                    ""
                  }
                  name={`${item?.carDetails?.brand} ${item?.carDetails?.model} ${item?.carDetails?.variant}`}
                  price={parseInt(item?.priceDetails?.salesPrice)}
                  kilometers={parseInt(item?.lastKms?.inventoryLastKms)}
                  fuelType={item?.FuelAndPerformanceFormData?.fuelType}
                  transmission={
                    item?.EngineAndTransmissionFormData?.transmissionType
                  }
                />
              )
            );
          })}
        </CardHolder>
      ) : (
        <p>No Data</p>
      )}
      <Link
        to={`buy-car?body-type=${ActiveType}`}
        className={BodyTypeStyle.viewMoreBtn}
      >
        <h5 className={BodyTypeStyle.viewMoreTitle}>view all {ActiveType}s</h5>
        <span className="material-icons-round">navigate_next</span>
      </Link>
    </Section>
  );
};

export default BodyType;
