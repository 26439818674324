import { logo } from "assets";
import { BottomShutter, HoverDropDown } from "core";
import HeaderStyle from "css/components/header/Header.module.css";
import { SignInForm, SignUpForm } from "forms";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { BottomNav, SearchBar, SideMenu } from ".";

import { SearchResultCard } from "components";
import { auth } from "config";
import SearchBarStyle from "css/components/header/SearchBar.module.css";
import { useAppContext, useIsMounted } from "hooks";

const Header = () => {
  const { wishlist, user, setAlertMessage, searchResult } = useAppContext();
  const location = useLocation();
  const IsMounted = useIsMounted();
  const [IsMenuOpen, setIsMenuOpen] = useState(false);
  const [IsAuthShutterOpen, setIsAuthShutterOpen] = useState(false);
  const [SearchKeyWord, setSearchKeyWord] = useState("");

  useEffect(() => {
    if (IsMounted.current) {
      location.hash === "#signin-now" && setIsAuthShutterOpen(true);
    }
  }, [IsMounted, location]);

  const SignOutHandler = async () => {
    if (user) {
      try {
        await auth.signOut();
        setAlertMessage({ type: "info", message: "signOut successfully" });
      } catch (error) {
        setAlertMessage({ type: "error", message: error.message });
      }
    }
  };

  return (
    <>
      {SearchKeyWord && (
        <div className={SearchBarStyle.SearchResultsWarperTwo}>
          {searchResult.map((item, index) => (
            <SearchResultCard
              key={index}
              href={`/buy-car/?model=${item?.carDetails?.model}`}
              onClick={() => setSearchKeyWord("")}
            >
              {`${item?.carDetails?.brand} ${item?.carDetails?.model} ${item?.carDetails?.variant}`}
            </SearchResultCard>
          ))}
        </div>
      )}
      <header className={HeaderStyle.warper}>
        <div className={HeaderStyle.container}>
          <div className={HeaderStyle.mobileElement}>
            <div className={HeaderStyle.MobileActionWarper}>
              <span className={HeaderStyle.logoAndMenuWarper}>
                <div
                  className={`${HeaderStyle.mobileOnlyElement} ${
                    HeaderStyle.menuIconBtn
                  } ${IsMenuOpen && HeaderStyle.active}`}
                  onClick={() => setIsMenuOpen((prev) => !prev)}
                >
                  <span className={HeaderStyle.menuIconBars}></span>
                  <span className={HeaderStyle.menuIconBars}></span>
                  <span className={HeaderStyle.menuIconBars}></span>
                </div>
                <Link to="/" style={{ height: "100%" }}>
                  <img src={logo} height="100%" width="auto" alt="CARKAROBAR" />
                </Link>
              </span>
              <span className={`${HeaderStyle.mobileOnlyElement}`}>
                <Link to="/sell-car">
                  <h4>Sell</h4>
                </Link>
                <Link to="/buy-car">
                  <h4>Buy</h4>
                </Link>
              </span>
            </div>
            <SearchBar onText={(e) => setSearchKeyWord(e.target.value)} />
          </div>
          <div className={HeaderStyle.nonMobileElement}>
            <HoverDropDown
              isDropDown={false}
              title={<Link to="/buy-car?carType=USED">Buy Car</Link>}
              SecondIcon={false}
            />
            <HoverDropDown
              isDropDown={false}
              title={<Link to="/sell-car">Sell Car</Link>}
              SecondIcon={false}
            />
            <Link to="/compare-cars">
              <HoverDropDown
                isDropDown={false}
                SecondIcon={false}
                title={<h4>Compare Cars</h4>}
              ></HoverDropDown>
            </Link>
            <Link
              to="/account/wishlist"
              data-count={wishlist?.length > 0 ? wishlist?.length : ""}
              className={wishlist?.length > 0 ? HeaderStyle.wishListCount : ""}
            >
              <span className="material-icons-round">favorite_border</span>
            </Link>
            <HoverDropDown
              isDropDown={true}
              SecondIcon="true"
              title={
                <span className="CP">
                  <p style={{ fontSize: "0.6em" }} className="CP">
                    Hello, {user?.name ? user?.name?.split(" ")[0] : "Sign in"}
                  </p>
                  <h4 className="CP">Account</h4>
                </span>
              }
            >
              <section className={HeaderStyle.MoreMenus}>
                <Link
                  to="/account/booking"
                  className={HeaderStyle.MoreMenusItems}
                >
                  <span className={`material-icons-round ${HeaderStyle.icon}`}>
                    drive_eta
                  </span>
                  <b> Bookings</b>
                </Link>
                <Link to="/support" className={HeaderStyle.MoreMenusItems}>
                  <span className={`material-icons-round ${HeaderStyle.icon}`}>
                    quiz
                  </span>{" "}
                  <b> Help and Supports</b>
                </Link>
                <Link
                  to="/account/account-information"
                  className={HeaderStyle.MoreMenusItems}
                >
                  <span className={`material-icons-round ${HeaderStyle.icon}`}>
                    account_circle
                  </span>
                  <b> Profile Information</b>
                </Link>
                {user ? (
                  <span className={HeaderStyle.MoreMenusItems}>
                    <span
                      className={`material-icons-round ${HeaderStyle.icon}`}
                    >
                      login
                    </span>
                    <b onClick={SignOutHandler}>Log out</b>
                  </span>
                ) : (
                  <span
                    className={HeaderStyle.MoreMenusItems}
                    onClick={() => setIsAuthShutterOpen(true)}
                  >
                    <span
                      className={`material-icons-round ${HeaderStyle.icon}`}
                    >
                      login
                    </span>
                    <b onClick={SignOutHandler}>Login/Sign up</b>
                  </span>
                )}
              </section>
            </HoverDropDown>
            <span
              style={{ color: "var(--colorD)" }}
              className={HeaderStyle.contacts}
            >
              <p style={{ fontSize: "0.6em" }}>Call us at</p>
              <a href="tel:7873999123">
                <h4>7873-9991-23</h4>
              </a>
            </span>
          </div>
        </div>
      </header>
      <BottomNav />
      <SideMenu IsMenuOpen={IsMenuOpen} closeFC={setIsMenuOpen} />
      <BottomShutter
        IsOpen={IsAuthShutterOpen}
        closeFC={() => setIsAuthShutterOpen(false)}
      >
        {location.hash === "#signup" ? (
          <SignUpForm onFinish={() => setIsAuthShutterOpen(false)} />
        ) : (
          <SignInForm onFinish={() => setIsAuthShutterOpen(false)} />
        )}
      </BottomShutter>
    </>
  );
};
export default Header;
