import { Button, InputField } from "core";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import SellCarFormStyle from "css/pages/sellCar/SellCarForm.module.css";

const ExteriorForm = ({
  setPresentStep,
  setFormData,
  formData,
  onNext,
  onBack,
}) => {
  const [sellCareValues, setSellCareValues] = useState({
    wheels: "",
    frontTyres: "",
    rearTyres: "",
    color: "",
    wheelbase: "",
    doors: "",
    bodyType: "",
  });
  //step:1
  const sellCarFormFieldList = [
    {
      name: "wheels",
      fieldName: "wheels",
      type: "text",
      title: "please enter wheels",
      placeholder: "Enter wheels",
    },
    {
      name: "front Tyres",
      fieldName: "frontTyres",
      type: "text",
      title: "please enter front Tyres",
      placeholder: "Enter front Tyres",
    },
    {
      name: "rear Tyres",
      fieldName: "rearTyres",
      type: "text",
      title: "please enter rear Tyres",
      placeholder: "Enter rear Tyres",
    },
    {
      name: "color",
      fieldName: "color",
      type: "text",
      title: "please enter color",
      placeholder: "Enter color",
    },
    {
      name: "wheel base",
      fieldName: "wheelbase",
      type: "number",
      title: "please enter wheel base",
      placeholder: "Enter wheel base",
    },
    {
      name: "doors",
      fieldName: "doors",
      type: "number",
      title: "please enter doors",
      placeholder: "Enter doors",
    },
    {
      name: "body Type",
      fieldName: "bodyType",
      type: "select",
      options: [
        "",
        "HATCHBACK",
        "SEDAN",
        "SUV",
        "MICRO",
        "CUV",
        "MINIVAN",
        "PICKUP",
        "VAN",
      ],
      title: "please enter body type",
      placeholder: "Enter body type",
    },
  ];

  const SellCarFormSchema = Yup.object().shape({
    //step:1 car info
    wheels: Yup.string(),
    frontTyres: Yup.string(),
    rearTyres: Yup.string(),
    color: Yup.string(),
    wheelbase: Yup.number(),
    doors: Yup.number(),
    bodyType: Yup.string(),
  });

  useEffect(() => {
    if (formData.ExteriorFormData) {
      setSellCareValues(formData.ExteriorFormData);
    }
  }, [formData]);

  const submitForm = async (values) => {
    await setFormData((prev) => ({ ...prev, ExteriorFormData: values }));
    onNext && onNext();
    // setPresentStep((prev) => prev + 1);
  };
  return (
    <div style={{ width: "100%" }}>
      <Formik
        initialValues={sellCareValues}
        enableReinitialize
        onSubmit={submitForm}
        validationSchema={SellCarFormSchema}
      >
        {({
          touched,
          errors,
          values,
          setTouched,
          handleChange,
          setFieldValue,
        }) => (
          <Form>
            <span className="FRSB">
              <h1 className="STUL">Exterior</h1>
            </span>
            <div className={SellCarFormStyle.inputWarper}>
              {sellCarFormFieldList.map((field, index) => {
                return (
                  <Field name={field.fieldName} key={index}>
                    {(props) => (
                      <InputField
                        {...props.field}
                        name={field.name}
                        title={field.title}
                        options={field.options}
                        onError={
                          touched[field.fieldName] && errors[field.fieldName]
                        }
                        type={field.type}
                        Icon={field.Icon}
                        fieldName={field.fieldName}
                        Value={values[field.fieldName]}
                        setFieldValue={setFieldValue}
                        onChange={handleChange}
                        placeholder={field.placeholder}
                        inputClassName={SellCarFormStyle.inputClassName}
                        errorMessage={
                          <ErrorMessage
                            name={field.fieldName}
                            render={(msg) => <p className="EMS">{msg}</p>}
                          />
                        }
                      />
                    )}
                  </Field>
                );
              })}
            </div>
            <div className={SellCarFormStyle.btnWarper}>
              {onBack && (
                <Button
                  type="button"
                  style={{ margin: "1em", backgroundColor: "var(--colorA)" }}
                  onClick={onBack}
                >
                  back
                </Button>
              )}
              <Button
                type="submit"
                style={{ margin: "1em" }}
                LastIcon={
                  <span className="material-icons-round">arrow_forward</span>
                }
              >
                save and continue
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ExteriorForm;
