import CardHolderStyle from "css/core/CardHolder.module.css";
import { useRef } from "react";
import PropTypes from "prop-types";
import { useState } from "react";

const CardHolder = ({ title, children, viewMoreLink, gap, scrollStep }) => {
  const [ScrollPosition, setScrollPosition] = useState(0);
  const childWarper = useRef();
  const Scrollable = useRef();
  const ScrollLeft = () => {
    if (scrollStep) {
      const scroll_by =
        childWarper.current.children[0].scrollWidth * scrollStep;
      return Scrollable?.current?.scrollBy(scroll_by, 0);
    }
    Scrollable?.current?.scrollBy(Scrollable?.current?.clientWidth, 0);
  };
  const ScrollRight = () => {
    if (scrollStep) {
      const scroll_by =
        childWarper.current.children[0].scrollWidth * scrollStep;
      return Scrollable?.current?.scrollBy(-scroll_by, 0);
    }
    Scrollable?.current?.scrollBy(-Scrollable?.current?.clientWidth, 0);
  };
  return (
    <div className={CardHolderStyle.layout}>
      <div className={CardHolderStyle.container}>
        {/* for the title and view all button */}
        <span className={CardHolderStyle.titleWarper}>
          {title && <span>{title}</span>}
          {viewMoreLink && <button>View</button>}
        </span>
        {/* for the cards */}
        <div
          ref={Scrollable}
          className={CardHolderStyle.CardWarper}
          onScroll={(e) =>
            setScrollPosition(
              parseInt(e.target.offsetWidth + e.target.scrollLeft)
            )
          }
          id="one"
        >
          {/* children component render place */}
          <span
            style={{ display: "flex", gap: gap || "0" }}
            id="two"
            ref={childWarper}
          >
            {children}
          </span>
          {/* arrows for scroll */}
          {ScrollPosition - Scrollable?.current?.offsetWidth !== 0 &&
            ScrollPosition !== 0 && (
              <span onClick={ScrollRight} className={CardHolderStyle.leftArrow}>
                <span className="material-icons-round md-18">
                  arrow_back_ios
                </span>
              </span>
            )}
          {ScrollPosition !== Scrollable?.current?.scrollWidth && (
            <span onClick={ScrollLeft} className={CardHolderStyle.rightArrow}>
              <span className="material-icons-round md-18">
                arrow_forward_ios
              </span>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

CardHolder.propTypes = {
  title: PropTypes.any,
  viewMoreLink: PropTypes.any,
};

export default CardHolder;
