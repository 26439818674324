import { carSkeleton } from "assets";
import { database } from "config";
import ProductCardStyle from "css/components/ProductCard.module.css";
import {
  useAppContext,
  useImageState,
  useIsMounted,
  useScreenSize,
  useScrollReveal,
} from "hooks";
import { useRef, useState } from "react";
import { Slider } from "react-lalit-slider";
import { useNavigate } from "react-router-dom";

const ProductCard = ({ mobile, carType, ...props }) => {
  const navigate = useNavigate();
  const IsMounted = useIsMounted();
  const { wishlist, user } = useAppContext();
  const [isWishListLoading, setIsWishListLoading] = useState(false);
  const { Width } = useScreenSize();
  const [IsImageLoading] = useImageState(props?.image);
  const [IsImageSliderLoading] = useImageState(
    props?.sliderImages && props?.sliderImages[0]
  );
  const target = useRef();
  const { IsReveal } = useScrollReveal({
    target: target.current,
  });
  const [IsSlideShow, setIsSlideShow] = useState(false);

  const handelWishlist = async () => {
    IsMounted.current && setIsWishListLoading(true);
    //check is Wishlist Already present
    if (
      !wishlist?.find((item) => item.id === props.id && item?.type === carType)
    ) {
      //checking if car type is NEW 🆕
      if (carType.toUpperCase() === "NEW") {
        await database.ref(`Users/${user?.uid}/wishlist`).push({
          type: carType,
          id: props?.id,
        });
      } else if (carType && props?.storeId && props?.id) {
        //check is USED car have All the required fields 🚗
        await database.ref(`Users/${user?.uid}/wishlist`).push({
          type: carType,
          storeId: props?.storeId,
          id: props?.id,
        });
      }
    } else {
      //deleting wishlist from database
      const wishlistId = await wishlist?.find(
        (item) => item.id === props.id && item?.type === carType
      ).wishlistId;
      await database.ref(`Users/${user?.uid}/wishlist/${wishlistId}`).remove();
    }
    IsMounted.current && setIsWishListLoading(false);
    // wishlist?.find((item) => item === props.id)
    //   ? setWishlist((prev) => prev.filter((item) => item !== props.id))
    //   : setWishlist((prev) => [...prev, props.id]);
  };

  return (
    <div
      ref={target}
      className={`${
        !mobile ? ProductCardStyle.container : ProductCardStyle.mobileContainer
      } ${IsReveal ? "reveal-active" : "reveal"}`}
      title={props.name}
      onMouseOver={() => IsMounted.current && setIsSlideShow(true)}
      onMouseLeave={() => IsMounted.current && setIsSlideShow(false)}
      onClick={(e) =>
        !e.target.matches("[data-wishlist]") &&
        navigate(
          `/buy-car/${props.name?.replaceAll(
            "/",
            " or "
          )}?carType=${carType}&id=${props.id}`
        )
      }
    >
      <span
        className={`${
          !mobile
            ? ProductCardStyle.ImageSection
            : ProductCardStyle.mobileImageSection
        } ${IsImageLoading && "animateSkeleton"}`}
        style={{
          backgroundImage: `url(${
            props?.sliderImages ? !IsSlideShow && props?.image : props?.image
          })`,
        }}
      >
        {IsImageLoading ? (
          <img
            src={carSkeleton}
            alt="car skeleton"
            className={ProductCardStyle.carSkeletonImg}
          ></img>
        ) : (
          IsSlideShow &&
          props?.sliderImages &&
          (IsImageSliderLoading ? (
            <span className="loaderOneBlue"></span>
          ) : (
            <div className={ProductCardStyle.ImageSliderWarper}>
              <Slider auto={1600} Arrow={false}>
                <>
                  {props?.sliderImages?.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        backgroundImage: `url(${item})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        height: Width >= 860 ? "10em" : "7em",
                        minWidth: "100%",
                      }}
                    ></div>
                  ))}
                </>
              </Slider>
            </div>
          ))
        )}
      </span>
      <div
        className={`${
          !mobile
            ? ProductCardStyle.contentSection
            : ProductCardStyle.mobileContentSection
        }`}
      >
        <span
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <h5 className={ProductCardStyle?.title} title={props.name}>
            {props.name}
          </h5>
          <span
            data-wishlist
            onClick={handelWishlist}
            className={`material-icons-round ${ProductCardStyle.wishlistIcon} ${
              wishlist?.find(
                (item) => item.id === props.id && item?.type === carType
              ) && ProductCardStyle.active
            }`}
          >
            {isWishListLoading ? (
              <span className="loaderOneBlue"></span>
            ) : wishlist?.find(
                (item) => item.id === props.id && item?.type === carType
              ) ? (
              "favorite"
            ) : (
              "favorite_border"
            )}
          </span>
        </span>
        <span className={ProductCardStyle.metaInfo}>
          {(props?.kilometers || props?.mileage) &&
            (props?.kilometers
              ? `${props?.kilometers?.toLocaleString()} Kms . `
              : `${props?.mileage?.toLocaleString()} Kmpl . `)}
          {props?.fuelType ? `${props?.fuelType} . ` : ""}{" "}
          {props?.transmission ? props?.transmission : ""}
        </span>
        <span>{props?.carNumber}</span>
        <span className="FRSB">
          {/* <b className="TMC">₹{props?.price?.toLocaleString()}</b> */}
          <span
            className={`${ProductCardStyle.carTypeBatch} ${
              carType && ProductCardStyle[carType]
            }`}
          >
            {carType}
          </span>
        </span>
        <div className={ProductCardStyle.etcInfo}>
          <span className={ProductCardStyle.items}>
            <span className={`material-icons ${ProductCardStyle.icon}`}>
              location_on
            </span>
            <p>
              carkarobar hub{" "}
              {props?.carHubLocation && `, ${props?.carHubLocation}`}
            </p>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
