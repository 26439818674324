import { useState } from "react";

const useMail = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(false);

  const sendMail = async ({ to, subject, content }) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/mail/sendMail`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          body: JSON.stringify({ to, subject, content }),
        }
      );
      const resData = await response.json();
      setData(resData);
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return { sendMail, isLoading, error, data };
};

export default useMail;
