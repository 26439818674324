import { database } from "config";
import Button from "core/Button";
import InputField from "core/InputField";
import SigninStyle from "css/components/Signin.module.css";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useAppContext } from "hooks";
import { useState } from "react";
import * as Yup from "yup";

const FinanceQueriesForm = ({ title, postSubmit }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { user, setAlertMessage } = useAppContext();

  const FinanceQueriesValues = {
    name: user?.name || "",
    email: user?.email || "",
    phoneNumber: user?.phoneNumber || "",
    location: "",
    financeType: "",
    preference: "",
  };

  const FinanceQueriesFieldList = [
    {
      name: "name",
      fieldName: "name",
      type: "text",
      title: "Enter your name",
      placeholder: "please enter name",
    },
    {
      name: "email",
      fieldName: "email",
      type: "email",
      title: "enter a email",
      placeholder: "please enter email",
    },
    {
      name: "phone number",
      fieldName: "phoneNumber",
      type: "number",
      title: "Enter your phone number",
      placeholder: "please enter phone number",
    },
    {
      name: "location",
      fieldName: "location",
      type: "text",
      title: "enter a location",
      placeholder: "please enter location",
    },
    {
      name: "Finance Type",
      fieldName: "financeType",
      type: "select",
      options: ["", "Self arrangement", "Support required"],
      title: "enter finance Type",
      placeholder: "Finance Type",
    },
    {
      name: "Preference",
      fieldName: "preference",
      type: "select",
      options: ["", "Nationalized Bank", "Pvt Bank", "NBFC"],
      title: "enter preference",
      placeholder: "Preference",
    },
  ];

  const FinanceQueriesSchema = Yup.object().shape({
    name: Yup.string().required("name is a required field"),
    email: Yup.string()
      .email("Invalid email")
      .required("email is a required field"),
    phoneNumber: Yup.number("phone number must be type of number")
      .test(
        "minlength",
        "phone number must be 10 digits",
        (val) => !val || (val && val.toString().length === 10)
      )
      .required("phone number is a required field"),
    location: Yup.string()
      .min(2, "location must be minimum 2 letter")
      .max(51, "location can not be more then 51 letter")
      .required("location is a required field"),
    financeType: Yup.string().required("field required"),
    preference: Yup.string().required("field required"),
  });

  const SubmitSignInHandler = async (values, { resetForm }) => {
    setIsLoading(true);
    try {
      const ID = Date.now();
      await database
        .ref(`FINANCE_QUERY/${ID}`)
        .set({ ...values, id: ID, timestamp: Date(), sendBy: user.uid });
      const notificationID = Date.now();
      await database.ref(`SpinnyNotification/${notificationID}`).set({
        id: notificationID,
        type: title.toUpperCase().replaceAll(" ", "_"),
        senderData: user,
        timestamp: Date(),
      });
      setAlertMessage({ type: "info", message: "form submitted successfully" });
      postSubmit && postSubmit();
    } catch (error) {
      console.log(error);
      setAlertMessage({ type: "error", message: error });
    } finally {
      resetForm(FinanceQueriesValues);
      setIsLoading(false);
    }
  };
  return (
    <div className={SigninStyle.container}>
      <Formik
        initialValues={FinanceQueriesValues}
        validationSchema={FinanceQueriesSchema}
        onSubmit={SubmitSignInHandler}
      >
        {({ values, handleChange, setTouched, touched, errors }) => (
          <Form>
            {FinanceQueriesFieldList.map((field, index) => {
              return (
                <Field name={field.fieldName} key={index}>
                  {(props) => (
                    <InputField
                      {...props.field}
                      name={field.name}
                      title={field.title}
                      options={field?.options}
                      onError={
                        touched[field.fieldName] && errors[field.fieldName]
                      }
                      type={field.type}
                      Icon={field.Icon}
                      fieldName={field.fieldName}
                      Value={values[field.fieldName]}
                      onChange={handleChange}
                      placeholder={field.placeholder}
                      errorMessage={
                        <ErrorMessage
                          name={field.fieldName}
                          render={(msg) => <p className="EMS">{msg}</p>}
                        />
                      }
                    />
                  )}
                </Field>
              );
            })}
            <Button
              loading={isLoading}
              type="submit"
              style={{
                width: "100%",
                margin: "1.25em 0em",
                backgroundColor: "var(--colorA)",
              }}
            >
              submit
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FinanceQueriesForm;
