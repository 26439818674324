import { BottomShutter, Select } from "core";
import FilterItemStyle from "css/core/FilterItem.module.css";
import { useScreenSize } from "hooks";
import PropTypes from "prop-types";
import { useState } from "react";

const FilterItem = ({
  children,
  filters,
  totalItem,
  onFilterChange,
  ItemWarperClassName,
}) => {
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const { Width } = useScreenSize();

  return (
    <div className={FilterItemStyle.container}>
      <div className={FilterItemStyle.header}>
        <b>{children.length || 0} Results</b>
        <Select
          className={FilterItemStyle.shorting}
          onClick={(e) => {
            onFilterChange && onFilterChange(e.target.value);
          }}
        >
          <option value="low-to-high">Price: Low to High</option>
          <option value="high-to-low">Price: High to Low</option>
          <option value="newest-first">Sort By: Newest First</option>
          <option value="km-driven-low-to-High">KM Driven - Low to High</option>
          <option value="km-driven-high-to-Low">KM Driven - High to Low</option>
          <option value="year-old-to-new">Year - Old to New</option>
          <option value="year-new-to-old">Year - New to Old</option>
          <option value="default">Reset to Initial State</option>
        </Select>
      </div>
      <div className={FilterItemStyle.ContentSection}>
        {filters &&
          (Width > 860 ? (
            <div className={FilterItemStyle.FilterWarper}>{filters}</div>
          ) : (
            <BottomShutter
              IsOpen={isOpenFilter}
              closeFC={() => setIsOpenFilter(false)}
            >
              <div className={FilterItemStyle.FilterWarper}>{filters}</div>
            </BottomShutter>
          ))}
        <div className={`${FilterItemStyle.ItemWarper} ${ItemWarperClassName}`}>
          {children}
        </div>
      </div>
      <button
        className={FilterItemStyle.filterBtn}
        onClick={() => setIsOpenFilter(true)}
      >
        <span className="material-icons-outlined md-blue">filter_alt</span>
      </button>
    </div>
  );
};

FilterItem.propTypes = {
  withFilter: PropTypes.bool,
  totalItem: PropTypes.number,
};

FilterItem.defaultProps = {
  withFilter: true,
};

export default FilterItem;
