import HoverDropDownStyle from "css/core/HoverDropDown.module.css";

const HoverDropDown = ({ title, children, SecondIcon, isDropDown = true }) => {
  return (
    <div className={HoverDropDownStyle.dropdown}>
      <span className={HoverDropDownStyle.MenuTitle}>
        <span className={HoverDropDownStyle.MenuTitleText}>{title}</span>
        {SecondIcon && (
          <span
            className={`material-icons-round ${HoverDropDownStyle.menuIcon}`}
          >
            expand_more
          </span>
        )}
      </span>
      {isDropDown && (
        <span className={HoverDropDownStyle.dropdown_content_left}>
          {children}
        </span>
      )}
    </div>
  );
};

export default HoverDropDown;
