import { Button, InputField } from "core";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import SellCarFormStyle from "css/pages/sellCar/SellCarForm.module.css";

const FuelAndPerformanceForm = ({
  setPresentStep,
  setFormData,
  formData,
  onNext,
  onBack,
}) => {
  const [sellCareValues, setSellCareValues] = useState({
    fuelType: "",
    maxPower: "",
    maxTorque: "",
    mileage: "",
  });
  //step:1
  const sellCarFormFieldList = [
    {
      name: "fuel Type",
      fieldName: "fuelType",
      type: "text",
      title: "please enter fuel Type",
      placeholder: "Enter fuel Type",
    },
    {
      name: "max Power",
      fieldName: "maxPower",
      type: "text",
      title: "please enter max Power",
      placeholder: "Enter max Power",
    },
    {
      name: "max Torque",
      fieldName: "maxTorque",
      type: "text",
      title: "please enter max Torque",
      placeholder: "Enter max Torque",
    },
    {
      name: "mileage",
      fieldName: "mileage",
      type: "number",
      title: "please enter mileage",
      placeholder: "Enter mileage",
    },
  ];

  const SellCarFormSchema = Yup.object().shape({
    //step:1 car info
    fuelType: Yup.string(),
    maxPower: Yup.string(),
    maxTorque: Yup.string(),
    mileage: Yup.number(),
  });
  useEffect(() => {
    if (formData.FuelAndPerformanceFormData) {
      setSellCareValues(formData.FuelAndPerformanceFormData);
    }
  }, [formData]);

  const submitForm = async (values) => {
    await setFormData((prev) => ({
      ...prev,
      FuelAndPerformanceFormData: values,
    }));
    onNext && onNext();
  };
  return (
    <div style={{ width: "100%" }}>
      <Formik
        initialValues={sellCareValues}
        enableReinitialize
        onSubmit={submitForm}
        validationSchema={SellCarFormSchema}
      >
        {({
          touched,
          errors,
          values,
          setTouched,
          handleChange,
          setFieldValue,
        }) => (
          <Form>
            <span className="FRSB">
              <h1 className="STUL">Fuel And Performance</h1>
            </span>
            <div className={SellCarFormStyle.inputWarper}>
              {sellCarFormFieldList.map((field, index) => {
                return (
                  <Field name={field.fieldName} key={index}>
                    {(props) => (
                      <InputField
                        {...props.field}
                        name={field.name}
                        title={field.title}
                        options={field.options}
                        onError={
                          touched[field.fieldName] && errors[field.fieldName]
                        }
                        type={field.type}
                        Icon={field.Icon}
                        fieldName={field.fieldName}
                        Value={values[field.fieldName]}
                        setFieldValue={setFieldValue}
                        onChange={handleChange}
                        placeholder={field.placeholder}
                        inputClassName={SellCarFormStyle.inputClassName}
                        errorMessage={
                          <ErrorMessage
                            name={field.fieldName}
                            render={(msg) => <p className="EMS">{msg}</p>}
                          />
                        }
                      />
                    )}
                  </Field>
                );
              })}
            </div>
            <div className={SellCarFormStyle.btnWarper}>
              {onBack && (
                <Button
                  type="button"
                  style={{ margin: "1em", backgroundColor: "var(--colorA)" }}
                  onClick={onBack}
                >
                  back
                </Button>
              )}
              <Button
                type="submit"
                style={{ margin: "1em" }}
                LastIcon={
                  <span className="material-icons-round">arrow_forward</span>
                }
              >
                save and continue
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FuelAndPerformanceForm;
