import ViewCarDetailsStyle from "css/components/ViewCarDetails.module.css";

const ViewCarDetails = ({ data }) => {
  return (
    <div className={ViewCarDetailsStyle.container}>
      <div className={ViewCarDetailsStyle.section}>
        <h2 className={ViewCarDetailsStyle.title}>Car Information</h2>
        <div className={ViewCarDetailsStyle.itemsWarper}>
          {Object.entries(data.CarInformationData).map((item, index) => (
            <span key={index} className={ViewCarDetailsStyle.items}>
              <h3 className={ViewCarDetailsStyle.itemTitle}>
                {item[0]
                  .replaceAll("_", " ")
                  ?.split(/(?=[A-Z])/)
                  .join(" ")}
              </h3>
              <p>{item[1] || "-"}</p>
            </span>
          ))}
        </div>
      </div>
      {/* <div className={ViewCarDetailsStyle.section}>
        <h2 className={ViewCarDetailsStyle.title}>More Car Information</h2>
        <div className={ViewCarDetailsStyle.itemsWarper}>
          {Object.entries(data.MoreCarInfoFormData).map((item, index) => (
            <span key={index} className={ViewCarDetailsStyle.items}>
              <h2 className={ViewCarDetailsStyle.itemTitle}>
                {item[0].replaceAll("_", " ")?.split(/(?=[A-Z])/).join(" ")}
              </h2>
              <p>{item[1] || "-"}</p>
            </span>
          ))}
        </div>
      </div> */}
      {/* <div className={ViewCarDetailsStyle.section}>
        <h2 className={ViewCarDetailsStyle.title}>Car OverView</h2>
        <div className={ViewCarDetailsStyle.itemsWarper}>
          {Object.entries(data.CarOverViewFormData).map((item, index) => (
            <span key={index} className={ViewCarDetailsStyle.items}>
              <h2 className={ViewCarDetailsStyle.itemTitle}>
                {item[0].replaceAll("_", " ")?.split(/(?=[A-Z])/).join(" ")}
              </h2>
              <p>{item[1] || "-"}</p>
            </span>
          ))}
        </div>
      </div> */}
      <div className={ViewCarDetailsStyle.section}>
        <h2 className={ViewCarDetailsStyle.title}>
          Car Dimensions And Capacity
        </h2>
        <div className={ViewCarDetailsStyle.itemsWarper}>
          {Object.entries(data.DimensionsAndCapacityFormData).map(
            (item, index) => (
              <span key={index} className={ViewCarDetailsStyle.items}>
                <h3 className={ViewCarDetailsStyle.itemTitle}>
                  {item[0]
                    .replaceAll("_", " ")
                    ?.split(/(?=[A-Z])/)
                    .join(" ")}
                </h3>
                <p>{item[1] || "-"}</p>
              </span>
            )
          )}
        </div>
      </div>
      <div className={ViewCarDetailsStyle.section}>
        <h2 className={ViewCarDetailsStyle.title}>
          Car Engine And Transmission
        </h2>
        <div className={ViewCarDetailsStyle.itemsWarper}>
          {Object.entries(data.EngineAndTransmissionFormData).map(
            (item, index) => (
              <span key={index} className={ViewCarDetailsStyle.items}>
                <h3 className={ViewCarDetailsStyle.itemTitle}>
                  {item[0]
                    .replaceAll("_", " ")
                    ?.split(/(?=[A-Z])/)
                    .join(" ")}
                </h3>
                <p>{item[1] || "-"}</p>
              </span>
            )
          )}
        </div>
      </div>
      <div className={ViewCarDetailsStyle.section}>
        <h2 className={ViewCarDetailsStyle.title}>Car Exterior</h2>
        <div className={ViewCarDetailsStyle.itemsWarper}>
          {Object.entries(data.ExteriorFormData).map((item, index) => (
            <span key={index} className={ViewCarDetailsStyle.items}>
              <h3 className={ViewCarDetailsStyle.itemTitle}>
                {item[0]
                  .replaceAll("_", " ")
                  ?.split(/(?=[A-Z])/)
                  .join(" ")}
              </h3>
              <p>{item[1] || "-"}</p>
            </span>
          ))}
        </div>
      </div>
      <div className={ViewCarDetailsStyle.section}>
        <h2 className={ViewCarDetailsStyle.title}>Car Fuel And Performance</h2>
        <div className={ViewCarDetailsStyle.itemsWarper}>
          {Object.entries(data.FuelAndPerformanceFormData).map(
            (item, index) => (
              <span key={index} className={ViewCarDetailsStyle.items}>
                <h3 className={ViewCarDetailsStyle.itemTitle}>
                  {item[0]
                    .replaceAll("_", " ")
                    ?.split(/(?=[A-Z])/)
                    .join(" ")}
                </h3>
                <p>{item[1] || "-"}</p>
              </span>
            )
          )}
        </div>
      </div>
      <div className={ViewCarDetailsStyle.section}>
        <h2 className={ViewCarDetailsStyle.title}>
          Car Suspension And Steering And Brakes
        </h2>
        <div className={ViewCarDetailsStyle.itemsWarper}>
          {Object.entries(data.SuspensionAndSteeringAndBrakesFormData).map(
            (item, index) => (
              <span key={index} className={ViewCarDetailsStyle.items}>
                <h3 className={ViewCarDetailsStyle.itemTitle}>
                  {item[0]
                    .replaceAll("_", " ")
                    ?.split(/(?=[A-Z])/)
                    .join(" ")}
                </h3>
                <p>{item[1] || "-"}</p>
              </span>
            )
          )}
        </div>
      </div>
      <div className={ViewCarDetailsStyle.section}>
        <h2 className={ViewCarDetailsStyle.title}>Car Safety</h2>
        <div className={ViewCarDetailsStyle.itemsWarper}>
          {Object.entries(data.SafetyFormData).map((item, index) => (
            <span key={index} className={ViewCarDetailsStyle.items}>
              <h3 className={ViewCarDetailsStyle.itemTitle}>
                {item[0]
                  .replaceAll("_", " ")
                  ?.split(/(?=[A-Z])/)
                  .join(" ")}
              </h3>
              <p>{item[1] || "-"}</p>
            </span>
          ))}
        </div>
      </div>
      <div className={ViewCarDetailsStyle.section}>
        <h2 className={ViewCarDetailsStyle.title}>Car Price</h2>
        <div className={ViewCarDetailsStyle.itemsWarper}>
          {Object.entries(data.PriceFormData).map((item, index) => (
            <span key={index} className={ViewCarDetailsStyle.items}>
              <h3 className={ViewCarDetailsStyle.itemTitle}>
                {item[0]
                  .replaceAll("_", " ")
                  ?.split(/(?=[A-Z])/)
                  .join(" ")}
              </h3>
              <p>{item[1] || "-"}</p>
            </span>
          ))}
        </div>
      </div>
      <div className={ViewCarDetailsStyle.section}>
        <h2 className={ViewCarDetailsStyle.title}>Car Images</h2>
        <div className={ViewCarDetailsStyle.itemsWarper}>
          {Object.entries(data.CarImagesFormData).map((item, index) => (
            <a
              href={item[1].url}
              target="_blank"
              rel="noreferrer"
              key={index}
              type=""
              className={ViewCarDetailsStyle.items}
            >
              <h3>{item[0]?.split(/(?=[A-Z])/).join(" ")}</h3>
              <img
                height="180em"
                width="auto"
                src={item[1].url}
                alt={item[0]}
              />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ViewCarDetails;
