import LifeStyleCardStyle from "css/components/LifeStyleCard.module.css";
import { useImageState, useIsMounted, useScrollReveal } from "hooks";
import { useRef } from "react";

const LifeStyleCard = ({ image, title, about }) => {
  const target = useRef();
  const IsMounted = useIsMounted();
  const { IsReveal } = useScrollReveal({
    target: target.current,
  });
  const [IsImageLoading] = useImageState(image);
  return (
    <div
      ref={target}
      id="revel"
      style={{ backgroundImage: `url(${image})` }}
      className={`${LifeStyleCardStyle.container} ${
        IsMounted && IsReveal ? "reveal-active" : "reveal"
      }`}
    >
      {IsImageLoading ? (
        <span className="loaderOneBlue"></span>
      ) : (
        <>
          <h2 className={LifeStyleCardStyle.title}>{title}</h2>
          <p className={LifeStyleCardStyle.about}>{about}</p>
        </>
      )}
    </div>
  );
};

export default LifeStyleCard;
