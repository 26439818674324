import SectionStyle from "css/components/Section.module.css";

const Section = ({ children, title, Style }) => {
  return (
    <section className={SectionStyle.container}>
      <h1 className={SectionStyle.header}>{title || "section header"}</h1>
      <div className={SectionStyle.ContentWarper} style={Style}>
        {children}
      </div>
    </section>
  );
};

export default Section;
