import SearchResultCardStyle from "css/components/SearchResultCard.module.css";
import { useNavigate } from "react-router-dom";

const SearchResultCard = ({ children, href, onClick }) => {
  const navigate = useNavigate();
  return (
    <span
      to={href}
      className={SearchResultCardStyle.container}
      onClick={() => {
        onClick && onClick();
        navigate(href);
      }}
    >
      {children}
    </span>
  );
};

export default SearchResultCard;
