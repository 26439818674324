import { database } from "config";
import CardSectionStyle from "css/pages/admin/dashboard/CardSection.module.css";
import { useEffect, useState } from "react";
import TotalNumberCard from "./TotalNumberCard";

const CardSection = () => {
  const [NewCarEnquire, setNewCarEnquire] = useState([]);
  const [NewCarEnquireLoading, setNewCarEnquireLoading] = useState(true);

  const [RegistrationQueries, setRegistrationQueries] = useState([]);
  const [RegistrationQueriesLoading, setRegistrationQueriesLoading] =
    useState(true);

  const [InsuranceQueries, setInsuranceQueries] = useState([]);
  const [InsuranceQueriesLoading, setInsuranceQueriesLoading] = useState(true);

  const [FinanceQueries, setFinanceQueries] = useState([]);
  const [FinanceQueriesLoading, setFinanceQueriesLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        await database.ref("NewCarEnquire").once("value", async (snapshot) => {
          const data = await snapshot.val();
          if (data) {
            const allBookings = Object.entries(data).map((item) => item[1]);
            setNewCarEnquire(allBookings);
          }
        });
      } catch (error) {
        console.log(error);
      } finally {
        setNewCarEnquireLoading(false);
      }

      try {
        await database
          .ref("RegistrationQueries")
          .once("value", async (snapshot) => {
            const data = await snapshot.val();
            if (data) {
              const allBookings = Object.entries(data).map((item) => item[1]);
              setRegistrationQueries(allBookings);
            }
          });
      } catch (error) {
        console.log(error);
      } finally {
        setRegistrationQueriesLoading(false);
      }

      try {
        await database
          .ref("InsuranceQueries")
          .once("value", async (snapshot) => {
            const data = await snapshot.val();
            if (data) {
              const allBookings = Object.entries(data).map((item) => item[1]);
              setInsuranceQueries(allBookings);
            }
          });
      } catch (error) {
        console.log(error);
      } finally {
        setInsuranceQueriesLoading(false);
      }

      try {
        await database.ref("FinanceQueries").once("value", async (snapshot) => {
          const data = await snapshot.val();
          if (data) {
            const allBookings = Object.entries(data).map((item) => item[1]);
            setFinanceQueries(allBookings);
          }
        });
      } catch (error) {
        console.log(error);
      } finally {
        setFinanceQueriesLoading(false);
      }
    })();
  }, []);
  return (
    <div className={CardSectionStyle.container}>
      <TotalNumberCard>
        <span className="material-icons-round md-light">receipt</span>
        <p>Total Car Bookings (leads)</p>
        {NewCarEnquireLoading ? (
          <div className="loaderOneBlue"></div>
        ) : (
          <h1>{NewCarEnquire.length}</h1>
        )}
      </TotalNumberCard>
      <TotalNumberCard>
        <span className="material-icons-round md-light">account_balance</span>
        <p>Total Car Finance (leads)</p>
        {FinanceQueriesLoading ? (
          <div className="loaderOneBlue"></div>
        ) : (
          <h1>{FinanceQueries.length}</h1>
        )}
      </TotalNumberCard>
      <TotalNumberCard>
        <span className="material-icons-round md-light">description</span>
        <p>Total Car Insurance (leads)</p>
        {InsuranceQueriesLoading ? (
          <div className="loaderOneBlue"></div>
        ) : (
          <h1>{InsuranceQueries.length}</h1>
        )}
      </TotalNumberCard>
      <TotalNumberCard>
        <span className="material-icons-round md-light">inventory</span>
        <p>Total Registration (leads)</p>
        {RegistrationQueriesLoading ? (
          <div className="loaderOneBlue"></div>
        ) : (
          <h1>{RegistrationQueries.length}</h1>
        )}
      </TotalNumberCard>
    </div>
  );
};

export default CardSection;
