import SellCarFormStyle from "css/pages/sellCar/SellCarForm.module.css";
import Stepper from "core/Stepper";
import { useEffect, useState } from "react";
import { ProgressIndication } from "core";
import { database } from "config";
import { UploadImage } from "config";
import { useAppContext, useMail } from "hooks";
import MetaDataForm from "./sellCarForms/MetaDataForm";
import AllImagesForm from "./sellCarForms/AllImagesForm";

const SellCarForm = () => {
  const [presentStep, setPresentStep] = useState(0);
  const [IsLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState([]);
  const { setAlertMessage, user, sendNotification } = useAppContext();
  const [progress, setProgress] = useState(0);
  const [storeManagersFCMTokens, setStoreManagersFCMTokens] = useState([]);
  const [storeManagersMails, setStoreManagersMails] = useState([]);
  const { sendMail } = useMail();

  useEffect(() => {
    (async () => {
      try {
        await database.ref(`Users`).on("value", async (snapshot) => {
          const data = await snapshot.val();
          if (data) {
            const allUserFCM = Object.entries(data)
              .map(
                (item) =>
                  item[1].role === "STOREMANAGER" && item[1]?.fcmTokenWeb
              )
              .filter((item) => !!item);
            const allUserEmail = Object.entries(data)
              .map((item) => item[1].role === "STOREMANAGER" && item[1]?.email)
              .filter((item) => !!item);
            setStoreManagersFCMTokens(allUserFCM);
            setStoreManagersMails(allUserEmail);
          }
        });
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const uploadForms = async (data) => {
    console.log(data);
    try {
      setIsLoading(true);
      const Id = Date.now();
      console.log("🆔: ", Id);
      const locationRef = `BiddingCars/${Id}`;
      let CarImagesData = {};
      let CarDocumentsData = {};

      const CarImagesFormData = {
        backViewImage: data?.AllImagesFormData?.backViewImage || "",
        frontViewImage: data?.AllImagesFormData?.frontViewImage || "",
        leftFrontCornerImage:
          data?.AllImagesFormData?.leftFrontCornerImage || "",
        leftRearCornerImage: data?.AllImagesFormData?.leftRearCornerImage || "",
        leftSideViewImage: data?.AllImagesFormData?.leftSideViewImage || "",
        rightFrontCornerImage:
          data?.AllImagesFormData?.rightFrontCornerImage || "",
        rightRearCornerImage:
          data?.AllImagesFormData?.rightRearCornerImage || "",
        rightSideCornerImage:
          data?.AllImagesFormData?.rightSideCornerImage || "",
      };
      //Car Image Uploads 📷
      for (const image of Object.entries(CarImagesFormData)) {
        setProgress((prev) => prev + 1);
        if (image[1]) {
          let imageData = await UploadImage({
            dbReference: locationRef,
            file: image[1],
          });
          CarImagesData = {
            ...CarImagesData,
            [image[0]]: {
              imageRef: imageData.imageRef,
              uri: imageData.imageUrl,
            },
          };
        }
      }
      //Documents Uploads 📃
      const CarDocumentsFormData = {
        insuranceCopyImage: data?.AllImagesFormData?.insuranceCopyImage || "",
        registrationCopyImage:
          data?.AllImagesFormData?.registrationCopyImage || "",
        othersDocumentsImage:
          data?.AllImagesFormData?.othersDocumentsImage || "",
      };
      for (const document of Object.entries(CarDocumentsFormData)) {
        setProgress((prev) => prev + 1);
        if (document[1]) {
          let documentData = await UploadImage({
            dbReference: locationRef,
            file: document[1],
          });
          CarDocumentsData = {
            ...CarDocumentsData,
            [document[0]]: {
              imageRef: documentData.imageRef,
              uri: documentData.imageUrl,
            },
          };
        }
      }
      //storing Form Data On Database
      await database.ref(locationRef).set({
        CarImagesFormData: CarImagesData || "",
        CarDocumentsFormData: CarDocumentsData || "",
        CarInfoFormData: {
          brand: data?.MetaDataFormData?.brand || "",
          model: data?.MetaDataFormData?.model || "",
          variant: data?.MetaDataFormData?.variant || "",
          car_color: data?.MetaDataFormData?.car_color || "",
          car_number: data?.MetaDataFormData?.car_registration_number || "",
          car_registered_city: data?.MetaDataFormData?.car_present_city || "",
          car_present_city: data?.MetaDataFormData?.car_present_city || "",
          car_registered_type: data?.MetaDataFormData?.registered_type || "",
          body_type: data?.MetaDataFormData?.body_type || "",
          kilometers_driven: data?.MetaDataFormData?.kilometers_driven || "",
          month: data?.MetaDataFormData?.registrationMonth || "",
          registrationMonth: data?.MetaDataFormData?.registrationMonth || "",
          registrationYear: data?.MetaDataFormData?.registrationYear || "",
          makeMonth: data?.MetaDataFormData?.makeMonth || "",
          makeYear: data?.MetaDataFormData?.makeYear || "",
          insurance_validity: data?.MetaDataFormData?.insurance_validity || "",
          insurance_policy_number:
            data?.MetaDataFormData?.insurance_policy_number || "",
          insurance_company_name:
            data?.MetaDataFormData?.insurance_company_name || "",
          fuel_type: data?.MetaDataFormData?.fuel_type || "",
          car_registration_number:
            data?.MetaDataFormData?.car_registration_number || "",
          transmission_type: data?.MetaDataFormData?.transmission_type || "",
        },
        CarOverViewFormData: {
          carLocation: data?.MetaDataFormData?.car_present_city || "",
          fuelType: data?.MetaDataFormData?.fuel_type || "",
          insuranceType: "",
          insuranceValidity: data?.MetaDataFormData?.insurance_validity || "",
          kmDriven: data?.MetaDataFormData?.kilometers_driven || "",
          makeYear: data?.MetaDataFormData?.makeYear || "",
          noOfOwner: "",
          registrationYear: data?.MetaDataFormData?.registrationYear || "",
          rto: "",
          transmission: data?.MetaDataFormData?.transmission_type || "",
        },
        carDetails: {
          CertificationNumber: "",
          brand: data?.MetaDataFormData?.brand || "",
          model: data?.MetaDataFormData?.model || "",
          variant: data?.MetaDataFormData?.variant || "",
          certified: "",
          color: data?.MetaDataFormData?.car_color || "",
          dealerCode: "",
          insurance: data?.MetaDataFormData?.insurance_policy_number || "",
          insuranceExpiry: "",
          recommendedWarranty: "",
          vehicleNumber: data?.MetaDataFormData?.car_registration_number || "",
        },
        ownerDetails: {
          city: data?.MetaDataFormData?.car_present_city || "",
          owner: data?.MetaDataFormData?.registered_owner_name || "",
          ownerEmail: data?.MetaDataFormData?.mail_id || "",
          registeredCity: data?.MetaDataFormData?.car_present_city || "",
          registeredMonth: data?.MetaDataFormData?.registrationMonth || "",
          registeredType: data?.MetaDataFormData?.registered_type || "",
        },
        SuspensionAndSteeringAndBrakesFormData: {
          frontBrakeType: "",
          rearBrakeType: "",
          steeringType: "",
          suspensionFront: "",
          suspensionRear: "",
        },
        SafetyFormData: {
          spareWheel: "",
        },
        MoreCarInfoFormData: {
          car_insurance_no:
            data?.MetaDataFormData?.insurance_policy_number || "",
          certified: "",
          city: data?.MetaDataFormData?.car_present_city || "",
          dealer_code: "",
          owner_email: data?.MetaDataFormData?.mail_id || "",
          owner_name: data?.MetaDataFormData?.registered_owner_name || "",
          recommended_warranty: "",
          alternative_contact_number:
            data.MetaDataFormData?.alternative_contact_number || "",
        },
        FuelAndPerformanceFormData: {
          fuelType: data?.MetaDataFormData?.fuel_type || "",
          maxPower: "",
          maxTorque: "",
          mileage: "",
        },
        ExteriorFormData: {
          bodyType: data?.MetaDataFormData?.body_type || "",
          color: data?.MetaDataFormData?.car_color || "",
          doors: "",
          frontTyres: "",
          rearTyres: "",
          wheelbase: "",
          wheels: "",
        },
        EngineAndTransmissionFormData: {
          cylinders: "",
          displacement: "",
          drivetrain: "",
          dualClutch: "",
          engineType: "",
          limitedSlipDifferential: "",
          noOfGears: "",
          transmissionType: "",
          turbochargerOrSupercharger: "",
          turbochargerType: "",
          valveOrCylinder: "",
        },
        DimensionsAndCapacityFormData: {
          fuelTankCapacity: "",
          groundClearance: "",
          height: "",
          length: "",
          noOfSeatingRows: "",
          seatingCapacity: "",
          width: "",
        },
        evaluateBackImages: CarImagesData?.backViewImage || "",
        evaluateFrontImages: CarImagesData?.frontViewImage || "",
        evaluateLeftImages: CarImagesData?.leftSideViewImage || "",
        evaluateRightImages: CarImagesData?.rightSideCornerImage || "",
        evaluateCarRegistration: CarDocumentsData?.registrationCopyImage || "",
        evaluateOtherImages: CarDocumentsData?.othersDocumentsImage || "",
        timestamp: Date(),
        sellBy: user.uid,
        id: Id,
        type: { role: "B2C" },
      });
      setAlertMessage({
        type: "success",
        message: "Form Submitted SuccessFully",
      });
      setFormData([]);
      setPresentStep(0);
      setProgress(0);
      // send Push Notification to all Store Manager
      storeManagersFCMTokens.length &&
        storeManagersFCMTokens.forEach((item) =>
          sendNotification({
            notification: {
              title: `Bidding Started #${Id}`,
              body: `${data?.MetaDataFormData?.brand} ${data?.MetaDataFormData?.model} ${data?.MetaDataFormData?.variant} Is Listed For Bidding`,
            },
            FCMToken: item,
            onclick:
              "https://carkarobar-store.web.app/store-manager-bidding-cars",
          })
        );
      // send Mail to all Store Manager
      storeManagersMails.length &&
        storeManagersMails.forEach((item) =>
          sendMail({
            subject: `Bidding Started #${Id}`,
            content: `${data?.MetaDataFormData?.brand} ${data?.MetaDataFormData?.model} ${data?.MetaDataFormData?.variant} Is Listed For Bidding`,
            to: item,
          })
        );
    } catch (error) {
      console.log(error);
      setAlertMessage({ type: "error", message: error.message });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={SellCarFormStyle.container}>
      <ProgressIndication
        totalStage={1}
        currentStage={presentStep}
        width={10}
      />
      <Stepper step={presentStep}>
        <MetaDataForm
          setPresentStep={setPresentStep}
          setFormData={setFormData}
          formData={formData}
          onNext={() => setPresentStep(1)}
        />
        <AllImagesForm
          setPresentStep={setPresentStep}
          setFormData={setFormData}
          formData={formData}
          totalStage={1}
          isLastItem={true}
          IsLoading={IsLoading}
          onNext={(data) => {
            setPresentStep(1);
            uploadForms(data);
          }}
          onBack={() => setPresentStep(0)}
        />
      </Stepper>
      {!!progress && (
        <div className={SellCarFormStyle.LoadingProgress}>
          {parseInt((progress / 11) * 100)}%
        </div>
      )}
    </div>
  );
};
export default SellCarForm;
