import React from "react";
import ContactSectionStyle from "css/components/footer/ContactSection.module.css";

const ContactSection = () => {
  return (
    <div className={ContactSectionStyle.container}>
      <span className={ContactSectionStyle.titleWarper}>
        <span
          className={`material-icons-round ${ContactSectionStyle.TalkIcon}`}
        >
          phone_in_talk
        </span>
        <h2>Let's Talk</h2>
      </span>
      <a
        href="mailto:contact@carkarobar.com?subject=Hello%20Carkarobar"
        className={`CURALL ${ContactSectionStyle.ContactLink}`}
      >
        <span
          className={`material-icons-round ${ContactSectionStyle.contactIcon}`}
        >
          mail_outline
        </span>
        <span>
          <p>Business inquiries</p>
          <p>contact@carkarobar.com</p>
        </span>
      </a>
      <a
        href="mailto:marketing@carkarobar.com?subject=Marketing Inquiry"
        className={`CURALL ${ContactSectionStyle.ContactLink}`}
      >
        <span
          className={`material-icons-round ${ContactSectionStyle.contactIcon}`}
        >
          send
        </span>
        <span>
          <p>PR or Media</p>
          <p>marketing@carkarobar.com</p>
        </span>
      </a>
    </div>
  );
};

export default ContactSection;
