import QueriesFormStyle from "css/components/Signin.module.css";
import Button from "core/Button";
import InputField from "core/InputField";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useAppContext, useIsMounted, useMail } from "hooks";
import { useEffect, useState } from "react";
import { database } from "config";
const QueriesForm = ({ title, Id, postSubmit }) => {
  const { sendNotification, setAlertMessage } = useAppContext();
  const [senderData, setSenderData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const IsMounted = useIsMounted();
  const { sendMail } = useMail();
  const Queries = {
    title: "",
    message: "",
  };

  useEffect(() => {
    (async () => {
      await database.ref(`Users/${Id}`).once("value", async (snapshot) => {
        const data = await snapshot.val();
        IsMounted.current && setSenderData(data);
      });
    })();
  }, [Id, IsMounted]);

  const QueriesFormList = [
    {
      name: "title",
      fieldName: "title",
      type: "text",
      title: "Enter title",
      placeholder: `e.g: regarding your ${title} query`,
    },
    {
      name: "message",
      fieldName: "message",
      type: "textfield",
      title: "enter message",
      placeholder: "e:g: message..",
    },
  ];

  const QueriesFormSchema = Yup.object().shape({
    title: Yup.string()
      .min(5, "title must be minimum 5 letter")
      .max(51, "title can not be more then 51 letter")
      .required("title is a required field"),
    message: Yup.string()
      .min(11, "message must be minimum 11 letter")
      .max(251, "message can not be more then 251 letter")
      .required("message is a required field"),
  });

  const SubmitSignInHandler = async (values, { resetForm }) => {
    setIsLoading(true);
    try {
      await sendNotification({
        FCMToken: senderData?.fcmTokenWeb,
        notification: {
          title: values.title,
          body: values.message,
        },
      });
      await sendMail({
        to: senderData.email,
        subject: values.title,
        content: values.message,
      });

      setAlertMessage({
        type: "info",
        message: `Successfully Send To ${senderData?.name}`,
      });
      resetForm(Queries);
      postSubmit && postSubmit();
    } catch (error) {
      console.log(error);
      setAlertMessage({
        type: "error",
        message: error,
      });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className={QueriesFormStyle.container}>
      <Formik
        initialValues={Queries}
        validationSchema={QueriesFormSchema}
        onSubmit={SubmitSignInHandler}
      >
        {({ values, handleChange, setTouched, touched, errors }) => (
          <Form>
            <h2 className={`${QueriesFormStyle.title}`}>{title}</h2>
            {QueriesFormList.map((field, index) => {
              return (
                <Field name={field.fieldName} key={index}>
                  {(props) => (
                    <InputField
                      {...props.field}
                      name={field.name}
                      title={field.title}
                      onError={
                        touched[field.fieldName] && errors[field.fieldName]
                      }
                      type={field.type}
                      Icon={field.Icon}
                      fieldName={field.fieldName}
                      Value={values[field.fieldName]}
                      inputClassName={QueriesFormStyle.inputClassName}
                      onChange={(e) => {
                        handleChange(e);
                        setTouched({ ...touched, [field.fieldName]: true });
                      }}
                      placeholder={field.placeholder}
                      errorMessage={
                        <ErrorMessage
                          name={field.fieldName}
                          render={(msg) => <p className="EMS">{msg}</p>}
                        />
                      }
                    />
                  )}
                </Field>
              );
            })}
            <Button
              loading={isLoading}
              type="submit"
              style={{
                width: "100%",
                margin: "1.25em 0em",
                backgroundColor: "var(--colorC)",
              }}
            >
              submit
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default QueriesForm;
