import Section from "components/Section";
import React, { useEffect, useState } from "react";
import FeaturedSectionStyle from "css/pages/home/FeaturedSection.module.css";
import CardHolder from "core/CardHolder";
import ProductCard from "components/ProductCard";
import { useIsMounted, useUsedCarList } from "hooks";

const FeaturedSection = () => {
  const [ActiveType, setActiveType] = useState("newlyAdded");
  const { usedCarList, isLoading } = useUsedCarList();
  const IsMounted = useIsMounted();

  const [NewlyAdded, setNewlyAdded] = useState([]);
  const [MostPopular, setMostPopular] = useState([]);

  const [CarType, setCarType] = useState(NewlyAdded);

  useEffect(() => {
    IsMounted && setCarType(NewlyAdded);
  }, [IsMounted, NewlyAdded]);

  useEffect(() => {
    if (usedCarList.length) {
      setNewlyAdded(usedCarList);

      setMostPopular(
        usedCarList
          .map((item) => item?.featured && item)
          .filter((item) => !!item)
      );
    }
  }, [IsMounted, usedCarList]);

  return (
    <Section
      title="Featured CarKarobar Used Cars"
      Style={{ alignItems: "center", gap: "1em" }}
    >
      <div className={FeaturedSectionStyle.container}>
        <span
          onClick={() => {
            setActiveType("newlyAdded");
            setCarType(NewlyAdded);
          }}
          className={`${FeaturedSectionStyle.carTypeHolder} ${
            ActiveType === "newlyAdded" && FeaturedSectionStyle.active
          }`}
        >
          Newly Added
        </span>
        <span
          onClick={() => {
            setActiveType("mostPopular");
            setCarType(MostPopular);
          }}
          className={`${FeaturedSectionStyle.carTypeHolder} ${
            ActiveType === "mostPopular" && FeaturedSectionStyle.active
          }`}
        >
          Most Popular
        </span>
      </div>
      {isLoading ? (
        <span className="loaderOneBlue"></span>
      ) : Array.isArray(CarType) && CarType[0] ? (
        <CardHolder gap="1em">
          {CarType?.map((item, index) => {
            return (
              <ProductCard
                carType="used"
                sliderImages={
                  item.CarImagesFormData &&
                  Object.entries(item.CarImagesFormData).map(
                    (images) => Object.entries(images)[1][1].uri
                  )
                }
                storeId={item?.storeId}
                id={item?.id}
                key={index}
                image={
                  item?.CarImagesFormData?.leftFrontCornerImage?.uri ||
                  item?.evaluateFrontImages?.uri ||
                  ""
                }
                name={`${item?.carDetails?.brand} ${item?.carDetails?.model} ${item?.carDetails?.variant}`}
                price={parseInt(item?.priceDetails?.salesPrice)}
                kilometers={parseInt(item?.lastKms?.inventoryLastKms)}
                fuelType={item?.FuelAndPerformanceFormData?.fuelType}
                transmission={
                  item?.EngineAndTransmissionFormData?.transmissionType
                }
                carHubLocation={item?.priceDetails?.carHubLocation}
              />
            );
          })}
        </CardHolder>
      ) : (
        <span>no data</span>
      )}
    </Section>
  );
};

export default FeaturedSection;
