import { PreLoader } from "core";
import { AppContextProvider } from "hooks/useAppContext";
import "index.css";
import { Suspense } from "react";
import ReactDOM from "react-dom";
import Routes from "Routes";

ReactDOM.render(
  <AppContextProvider>
    <Suspense fallback={<PreLoader />}>
      <Routes />
    </Suspense>
  </AppContextProvider>,
  document.getElementById("root")
);
