import { SearchResultCard } from "components";
import SearchBarStyle from "css/components/header/SearchBar.module.css";
import { useAllCars, useAppContext } from "hooks";
import { useEffect, useState } from "react";

const SearchBar = ({ onText }) => {
  const [SearchKeyWord, setSearchKeyWord] = useState("");
  const { allCars } = useAllCars();
  const { searchResult, setSearchResult } = useAppContext();

  useEffect(() => {
    setSearchResult(
      allCars.filter((item) =>
        `${item?.carDetails?.brand} ${item?.carDetails?.model} ${item?.carDetails?.variant}`
          ?.toUpperCase()
          ?.match(SearchKeyWord?.toUpperCase())
      )
    );
  }, [allCars, SearchKeyWord, setSearchResult]);

  return (
    <div className={SearchBarStyle.Warper}>
      <div className={SearchBarStyle.container}>
        <input
          className={SearchBarStyle.input}
          type="text"
          value={SearchKeyWord}
          placeholder="Search By Model Type etc."
          onChange={(e) => {
            onText && onText(e);
            setSearchKeyWord(e.target.value);
          }}
        />
        <span className={`material-icons-round ${SearchBarStyle.icon}`}>
          search
        </span>
      </div>
      {SearchKeyWord && (
        <div className={SearchBarStyle.SearchResultsWarper}>
          {searchResult.map((item, index) => (
            <SearchResultCard
              key={index}
              href={`/buy-car/?model=${item?.carDetails?.model}`}
              onClick={() => setSearchKeyWord("")}
            >
              {`${item?.carDetails?.brand} ${item?.carDetails?.model} ${item?.carDetails?.variant}`}
            </SearchResultCard>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchBar;
