import { Button, InputField } from "core";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import SellCarFormStyle from "css/pages/sellCar/SellCarForm.module.css";

const PriceForm = ({
  setPresentStep,
  setFormData,
  formData,
  onBack,
  onNext,
}) => {
  const [sellCareValues, setSellCareValues] = useState({
    price: "",
  });

  //step:1
  const sellCarFormFieldList = [
    {
      name: "Price",
      fieldName: "price",
      type: "number",
      title: "please enter Car Price",
      placeholder: "Enter Car Price",
    },
  ];

  const SellCarFormSchema = Yup.object().shape({
    //step:1 car info
    price: Yup.string(),
  });

  useEffect(() => {
    if (formData.PriceFormData) {
      setSellCareValues(formData.PriceFormData);
    }
  }, [formData]);

  const submitForm = async (values) => {
    await setFormData((prev) => ({ ...prev, PriceFormData: values }));
    onNext && onNext();
    // setPresentStep((prev) => prev + 1);
  };
  return (
    <div style={{ width: "100%" }}>
      <Formik
        initialValues={sellCareValues}
        enableReinitialize
        onSubmit={submitForm}
        validationSchema={SellCarFormSchema}
      >
        {({
          touched,
          errors,
          values,
          setTouched,
          handleChange,
          setFieldValue,
        }) => (
          <Form>
            <span className="FRSB">
              <h1 className="STUL">Price</h1>
            </span>
            <div className={SellCarFormStyle.inputWarper}>
              {sellCarFormFieldList.map((field, index) => {
                return (
                  <Field name={field.fieldName} key={index}>
                    {(props) => (
                      <InputField
                        {...props.field}
                        name={field.name}
                        title={field.title}
                        options={field.options}
                        onError={
                          touched[field.fieldName] && errors[field.fieldName]
                        }
                        type={field.type}
                        Icon={field.Icon}
                        fieldName={field.fieldName}
                        Value={values[field.fieldName]}
                        setFieldValue={setFieldValue}
                        onChange={handleChange}
                        placeholder={field.placeholder}
                        inputClassName={SellCarFormStyle.inputClassName}
                        errorMessage={
                          <ErrorMessage
                            name={field.fieldName}
                            render={(msg) => <p className="EMS">{msg}</p>}
                          />
                        }
                      />
                    )}
                  </Field>
                );
              })}
            </div>
            <div className={SellCarFormStyle.btnWarper}>
              {onBack && (
                <Button
                  type="button"
                  style={{ margin: "1em", backgroundColor: "var(--colorA)" }}
                  onClick={onBack}
                  // setPresentStep((prev) => prev - 1)
                >
                  back
                </Button>
              )}
              <Button
                type="submit"
                style={{ margin: "1em" }}
                LastIcon={
                  <span className="material-icons-round">arrow_forward</span>
                }
              >
                save and continue
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PriceForm;
