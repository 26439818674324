import { lazy } from "react";

export const PanelLayout = lazy(() => import("./PanelLayout"));
export { default as Inventory } from "./inventory/Inventory";
export { default as Notification } from "./notification/Notification";
export { default as Insurance } from "./insurance/Insurance";
export { default as Registration } from "./registration/Registration";
export { default as Finance } from "./finance/Finance";
export { default as Bookings } from "./bookings/Bookings";
export { default as Dashboard } from "./dashboard/Dashboard";
export { default as BookYourService } from "./bookYourService/BookYourService";
export { default as BrakeDownService } from "./brakeDownService/BrakeDownService";
export { default as ViewInventory } from "./viewInventory/ViewInventory";
export { default as Support } from "./support/Support";
