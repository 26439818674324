import { BrowserRouter, useRoutes } from "react-router-dom";
import Home from "pages/home";
import NotFound from "pages/NotFound";
import {
  SellCar,
  BuyCar,
  BuyCarByFilter,
  Faqs,
  Support,
  CompareCars,
} from "pages";
import { Account } from "pages/account";
import {
  AccountInformation,
  BuyBooking,
  SellBooking,
  Wishlist,
} from "pages/account/sections";
import {
  PanelLayout,
  Inventory,
  ViewInventory,
  Notification,
  Insurance,
  Registration,
  Finance,
  Bookings,
  Dashboard,
  BookYourService,
  BrakeDownService,
  Support as AdminSupport,
} from "pages/adminPanel";
import FinancePage from "pages/financePage";
import InsurancePage from "pages/insurancePage";
import RegistrationPage from "pages/registrationPage";
import BookYourServicePage from "pages/bookYourServicePage";
import BrakeDownServicePage from "pages/brakeDownServicePage";
import { useAppContext } from "hooks";
import { AlertPopUp, Button, EmptyPage, PreLoader } from "core";
import { AdminRoute, Authorized } from "customRoutes";
import useIsOnline from "hooks/useIsOnline";
import { noConnection } from "assets";

//all account routes

const accountRoutes = [
  { path: "account-information", element: <AccountInformation /> },
  { path: "wishlist", element: <Wishlist /> },
  { path: "sell-cars", element: <SellBooking /> },
  { path: "booking", element: <BuyBooking /> },
];

const adminRoutes = [
  { path: "inventory", element: <Inventory /> },
  { path: "view-inventory", element: <ViewInventory /> },
  { path: "dashboard", element: <Dashboard /> },
  { path: "insurance", element: <Insurance /> },
  { path: "registration", element: <Registration /> },
  { path: "support", element: <AdminSupport /> },
  { path: "finance", element: <Finance /> },
  { path: "book-Your-service", element: <BookYourService /> },
  { path: "break-down-service", element: <BrakeDownService /> },
  { path: "bookings", element: <Bookings /> },
  { path: "notification", element: <Notification /> },
];

//all the routes
const AllRoutes = () => {
  const routes = useRoutes([
    { path: "/", element: <Home /> },
    {
      path: "sell-car",
      element: (
        <Authorized>
          <SellCar />
        </Authorized>
      ),
    },
    { path: "buy-car", element: <BuyCarByFilter /> },
    { path: "buy-car/:name", element: <BuyCar /> },
    { path: "compare-cars", element: <CompareCars /> },
    {
      path: "account",
      element: (
        <Authorized>
          <Account />
        </Authorized>
      ),
      children: accountRoutes,
    },
    { path: "faqs", element: <Faqs /> },
    {
      path: "finance",
      element: (
        <Authorized>
          <FinancePage />
        </Authorized>
      ),
    },
    {
      path: "insurance",
      element: (
        <Authorized>
          <InsurancePage />
        </Authorized>
      ),
    },
    {
      path: "book-your-service",
      element: (
        <Authorized>
          <BookYourServicePage />
        </Authorized>
      ),
    },
    {
      path: "brake-down-service",
      element: (
        <Authorized>
          <BrakeDownServicePage />
        </Authorized>
      ),
    },
    {
      path: "registration",
      element: (
        <Authorized>
          <RegistrationPage />
        </Authorized>
      ),
    },
    {
      path: "support",
      element: (
        <Authorized>
          <Support />
        </Authorized>
      ),
    },
    {
      path: "admin",
      element: (
        <AdminRoute>
          <PanelLayout />
        </AdminRoute>
      ),
      children: adminRoutes,
    },
    { path: "*", element: <NotFound /> },
  ]);
  return routes;
};

const Routes = () => {
  const { AlertMessage, IsLoading } = useAppContext();
  const IsOnline = useIsOnline();

  //to preload image to use if internet disconnect because it stored in cash
  const preImage = new Image();
  preImage.src = noConnection;

  return (
    <>
      {AlertMessage.type && (
        <AlertPopUp type={AlertMessage.type} text={AlertMessage.message} />
      )}

      {IsOnline ? (
        IsLoading ? (
          <PreLoader />
        ) : (
          <BrowserRouter>
            <AllRoutes />
          </BrowserRouter>
        )
      ) : (
        <EmptyPage image={noConnection}>
          📵 No Internet Connection 🔌
          <br />
          <Button onClick={() => window?.location?.reload()}>Reload</Button>
        </EmptyPage>
      )}
    </>
  );
};

export default Routes;
