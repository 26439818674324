import { NoData } from "assets";
import { EmptyPage } from "core";
import { useAppContext, useIsMounted } from "hooks";
import { useCallback, useEffect, useState } from "react";
import AccountDetailViewSection from "../AccountDetailViewSection";
import WishlistStyle from "css/pages/account/Wishlist.module.css";
import WishlistCard from "components/WishlistCard";
import { database } from "config";

const Wishlist = () => {
  const IsMounted = useIsMounted();
  const { wishlist } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [allCard, setAllCard] = useState([]);

  const getAllWishListCars = useCallback(async () => {
    const allWishItem = [];
    for (const item of wishlist) {
      if (item.type.toUpperCase() === "USED") {
        await database
          .ref(`InventoryCars/${item?.storeId}/${item?.id}`)
          .once("value", async (snapshot) => {
            const carData = snapshot.val();
            carData &&
              IsMounted.current &&
              allWishItem.push({
                ...carData?.ownerDetails?.inventoryData,
                id: item?.id,
                storeId: item?.storeId,
                carType: "USED",
              });
          });
      } else if (item.type.toUpperCase() === "NEW") {
        await database
          .ref(`NewCars/${item?.id}`)
          .once("value", async (snapshot) => {
            const carData = snapshot.val();
            carData &&
              IsMounted.current &&
              allWishItem.push({
                ...carData,
                id: item?.id,
                storeId: item?.storeId,
                carType: "NEW",
              });
          });
      }
    }
    setAllCard(allWishItem);
    setIsLoading(false);
  }, [IsMounted, wishlist]);

  useEffect(() => {
    IsMounted.current && getAllWishListCars();
    IsMounted.current &&
      document.querySelector("#AccountDetailViewSection").scrollIntoView(true);
  }, [IsMounted, getAllWishListCars]);
  return (
    <AccountDetailViewSection title="Wishlist">
      {isLoading ? (
        <span className="loaderOneBlue"></span>
      ) : wishlist.length ? (
        <div className={WishlistStyle.container}>
          {allCard?.map((item, index) => {
            return (
              <WishlistCard
                key={index}
                carType={item?.carType}
                storeId={item?.storeId}
                id={item.id}
                mobile={true}
                image={
                  item?.CarImagesFormData?.rightFrontCornerImage?.url ||
                  item?.CarImagesFormData?.rightFrontCornerImage?.uri ||
                  item?.evaluateFrontImages?.uri
                }
                name={`${
                  item?.CarInformationData?.brand || item?.carDetails?.brand
                } ${
                  item?.CarInformationData?.model || item?.carDetails?.model
                } ${
                  item?.CarInformationData?.variant || item?.carDetails?.variant
                }`}
                price={
                  item?.PriceFormData?.price || item?.priceDetails?.salesPrice
                }
                mileage={item?.FuelAndPerformanceFormData?.mileage}
                kilometers={
                  item?.carEvaluation && item?.carEvaluation[0]?.kilometers
                }
                fuelType={item?.FuelAndPerformanceFormData?.fuelType}
                transmission={
                  item?.EngineAndTransmissionFormData?.transmissionType
                }
              />
            );
          })}
        </div>
      ) : (
        <EmptyPage image={NoData}>wishlist is empty</EmptyPage>
      )}
    </AccountDetailViewSection>
  );
};

export default Wishlist;
