import Section from "components/Section";
import React, { useState } from "react";
import {
  skoda,
  datsun,
  chevrolet,
  kia,
  fiat,
  isuzu,
  maruti_suzuki,
  hyundai,
  ford,
  honda,
  renault,
  volkswagen,
  tata,
  mahindra,
  toyota,
  nissan,
} from "assets";
import BrandCard from "components/BrandCard";
import { useScreenSize } from "hooks";
import { Link } from "react-router-dom";

const BrandSection = () => {
  const [Brands] = useState([
    { name: "skoda", image: skoda, count: 16 },
    { name: "datsun", image: datsun, count: 16 },
    { name: "chevrolet", image: chevrolet, count: 16 },
    { name: "kia", image: kia, count: 16 },
    { name: "fiat", image: fiat, count: 16 },
    { name: "isuzu", image: isuzu, count: 16 },
    { name: "maruti suzuki", image: maruti_suzuki, count: 16 },
    { name: "hyundai", image: hyundai, count: 16 },
    { name: "ford", image: ford, count: 16 },
    { name: "honda", image: honda, count: 16 },
    { name: "renault", image: renault, count: 16 },
    { name: "volkswagen", image: volkswagen, count: 16 },
    { name: "tata", image: tata, count: 16 },
    { name: "mahindra", image: mahindra, count: 16 },
    { name: "toyota", image: toyota, count: 16 },
    { name: "nissan", image: nissan, count: 16 },
  ]);
  const [IsShowAll, setIsShowAll] = useState(false);
  const { Width } = useScreenSize();

  //Show more toggle checker function
  const CheckIsShowAll = () =>
    IsShowAll ? Brands.length : Width < 850 ? 7 : 11;
  return (
    <Section
      title="popular brands"
      Style={{
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
      }}
    >
      {Brands?.map((item, index) => {
        return (
          index < CheckIsShowAll() && (
            <Link key={index} to={`/buy-car/?brand=${item.name}`}>
              <BrandCard image={item.image} name={item.name} />
            </Link>
          )
        );
      })}
      <BrandCard
        onClick={() => setIsShowAll((prev) => !prev)}
        name={
          IsShowAll ? (
            <>
              <span className="material-icons-round">expand_less</span>
              <h4>view less brands</h4>
            </>
          ) : (
            <>
              <h4>view more brands</h4>
              <br />
              <span className="material-icons-round">expand_more</span>
            </>
          )
        }
      />
    </Section>
  );
};

export default BrandSection;
