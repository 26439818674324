import { Alert, AlertTitle } from "@mui/material";
import { undrawNotify } from "assets";
import { database } from "config";
import { EmptyPage, ListItems } from "core";
import { useIsMounted } from "hooks";
import React, { useEffect } from "react";
import { useState } from "react";

const Notification = () => {
  const [Notifications, setNotifications] = useState([]);
  const [IsLoading, setIsLoading] = useState(true);
  const IsMounted = useIsMounted();

  useEffect(() => {
    (async () => {
      try {
        await database
          .ref("SpinnyNotification")
          .on("value", async (snapshot) => {
            const data = await snapshot.val();
            if (data) {
              const allBookings = Object.entries(data).map((item) => item[1]);
              IsMounted.current && setNotifications(allBookings);
            } else {
              IsMounted.current && setNotifications([]);
            }
          });
      } catch (error) {
        console.log(error);
      } finally {
        IsMounted.current && setIsLoading(false);
      }
    })();
  }, [IsMounted]);

  const clearAll = async () => {
    try {
      await database.ref(`SpinnyNotification`).remove();
    } catch (error) {
      console.log(error);
    }
  };

  const clearMe = async (e) => {
    let notificationId = e.target.closest("[data-id]").dataset.id;
    try {
      if (notificationId) {
        await database.ref(`SpinnyNotification/${notificationId}`).remove();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handelIconType = (type) => {
    switch (type) {
      case "CAR_BOOKED":
        return "🚗";
      case "FINANCE_QUERY":
        return "📑";
      case "REGISTRATION":
        return "📝";
      case "INSURANCE":
        return "📋";
      case "BOOK_YOUR_SERVICE":
        return "📚";
      case "BREAK_DOWN_SERVICE":
        return "💥";
      default:
        return "🔔";
    }
  };

  return (
    <div style={{ backgroundColor: "white", borderRadius: "0.5em" }}>
      {IsLoading ? (
        <span className="loaderOneBlue"></span>
      ) : Notifications.length ? (
        <div style={{ width: "100%", maxWidth: "var(--layoutWidth)" }}>
          <ListItems
            title="Notification"
            clickBtn="clean all"
            onClickFC={clearAll}
          >
            {Notifications?.map((item, index) => {
              return (
                <div key={index} style={{ width: "100%" }}>
                  <Alert
                    severity="info"
                    icon={handelIconType(item?.type)}
                    onClose={clearMe}
                    key={index}
                    data-id={item?.id}
                  >
                    <AlertTitle>{item.title}</AlertTitle>
                    {/* "Insurance Quark By Manjeet Jitendra Kulkarni " */}
                    {`${item?.type
                      ?.replaceAll("_", " ")
                      .toLowerCase()
                      .replace(/\w\S*/g, (w) =>
                        w.replace(/^\w/, (c) => c.toUpperCase())
                      )} By ${item?.senderData?.name}`}
                  </Alert>
                </div>
              );
            })}
          </ListItems>
        </div>
      ) : (
        <EmptyPage image={undrawNotify}>🔔No Notification Found</EmptyPage>
      )}
    </div>
  );
};

export default Notification;
