import Button from "core/Button";
import InputField from "core/InputField";
import { ErrorMessage, Field, Formik } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import SigninStyle from "css/components/Signin.module.css";
import { auth, database } from "config";
import { useAppContext } from "hooks";
import { useState } from "react";

const SignUpForm = ({ onFinish }) => {
  const { setAlertMessage } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const SignUpValue = {
    fullName: "",
    email: "",
    password: "",
  };
  const SignUpFieldList = [
    {
      name: "Full Name",
      fieldName: "fullName",
      type: "text",
      title: "please enter your name",
      Icon: <span className="material-icons-round md-dark-light">badge</span>,
      placeholder: "please enter name",
    },
    {
      name: "email",
      fieldName: "email",
      type: "email",
      title: "please enter your email",
      Icon: <span className="material-icons-round md-dark-light">mail</span>,
      placeholder: "please enter email",
    },
    {
      name: "password",
      fieldName: "password",
      type: "password",
      title: "please enter a password",
      Icon: <span className="material-icons-round md-dark-light">key</span>,
      placeholder: "please enter password",
    },
  ];
  const SignUpSchema = Yup.object().shape({
    fullName: Yup.string()
      .max(25, "name must be less then 25 character")
      .required("name is a required field"),
    email: Yup.string()
      .email("Invalid email")
      .required("email is a required field"),
    password: Yup.string()
      .min(5, "password must be minimum 6 letter")
      .required("password is a required field"),
  });
  const SubmitSignUpHandler = async (values, { resetForm }) => {
    try {
      setIsLoading(true);
      //firebase New User SignUp
      const newUser = await auth.createUserWithEmailAndPassword(
        values.email,
        values.password
      );
      newUser.user.updateProfile({ displayName: values.fullName });
      //storing User Data On Database
      database.ref(`Users/${newUser?.user?.uid}`).set({
        id: newUser?.user?.uid,
        name: values.fullName,
        email: values.email,
        createdAt: Date(),
        password: values.password,
      });
      setAlertMessage({ type: "success", message: "Signup SuccessFully" });
      resetForm(SignUpValue);
      onFinish && onFinish();
    } catch (error) {
      console.log(error);
      setAlertMessage({ type: "error", message: error.message });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className={SigninStyle.container}>
      {console.log("➡️")}
      <Formik
        initialValues={SignUpValue}
        validationSchema={SignUpSchema}
        onSubmit={SubmitSignUpHandler}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setErrors,
          handleReset,
          setTouched,
          touched,
          errors,
        }) => (
          <form onSubmit={handleSubmit}>
            <h1 className={SigninStyle.title}>SignUp</h1>
            {SignUpFieldList.map((field, index) => {
              return (
                <Field name={field.fieldName} key={index}>
                  {(props) => (
                    <InputField
                      {...props.field}
                      backGroundColor="rgb(245,245,245)"
                      name={field.name}
                      title={field.title}
                      onError={
                        touched[field.fieldName] && errors[field.fieldName]
                      }
                      type={field.type}
                      Icon={field.Icon}
                      fieldName={field.fieldName}
                      Value={values[field.fieldName]}
                      onChange={(e) => {
                        handleChange(e);
                        setTouched({ ...touched, [field.fieldName]: true });
                      }}
                      placeholder={field.placeholder}
                      errorMessage={
                        <ErrorMessage
                          name={field.fieldName}
                          render={(msg) => <p className="EMS">{msg}</p>}
                        />
                      }
                    />
                  )}
                </Field>
              );
            })}
            <Button
              loading={isLoading}
              type="submit"
              style={{
                width: "100%",
                margin: "1.25em 0em",
                backgroundColor: "var(--colorA)",
              }}
            >
              submit
            </Button>
            <span style={{ width: "100%" }}>
              <Link to="#signin" style={{ float: "right", cursor: "pointer" }}>
                Already an user ?
              </Link>
            </span>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default SignUpForm;
