import { useNavigate } from "react-router-dom";
import HeaderStyle from "css/components/header/Header.module.css";

const Base = ({ children }) => {
  const navigate = useNavigate();
  return (
    <div className={`${HeaderStyle.serviceLinks} `}>
      <span
        className={`CP ${HeaderStyle.MoreMenusItems}`}
        onClick={() => navigate("/finance")}
      >
        <span className={`material-icons-round ${HeaderStyle.icon}`}>
          request_quote
        </span>
        <b> Finance</b>
      </span>
      <span
        className={`CP ${HeaderStyle.MoreMenusItems}`}
        onClick={() => navigate("/insurance")}
      >
        <span className={`material-icons-round ${HeaderStyle.icon}`}>
          health_and_safety
        </span>
        <b> Insurance</b>
      </span>
      <span
        className={`CP ${HeaderStyle.MoreMenusItems}`}
        onClick={() => navigate("/registration")}
      >
        <span className={`material-icons-round ${HeaderStyle.icon}`}>
          how_to_reg
        </span>
        <b> Registration</b>
      </span>
      <span
        className={`CP ${HeaderStyle.MoreMenusItems}`}
        onClick={() => navigate("/book-your-service")}
      >
        <span className={`material-icons-round ${HeaderStyle.icon}`}>
          menu_book
        </span>
        <b> Book Your Service</b>
      </span>
      <span
        className={`CP ${HeaderStyle.MoreMenusItems}`}
        onClick={() => navigate("/brake-down-service")}
      >
        <span className={`material-icons-round ${HeaderStyle.icon}`}>
          settings
        </span>
        <b> Break Down Service</b>
      </span>
    </div>
  );
};

export default Base;
