import SupportFormStyle from "css/forms/SupportForm.module.css";
import Button from "core/Button";
import InputField from "core/InputField";
import { ErrorMessage, Field, Formik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { useAppContext } from "hooks";
import { database } from "config";
const SupportForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { user, setAlertMessage } = useAppContext();

  const Support = {
    name: user?.name || "",
    email: user?.email || "",
    query: "",
  };

  const SupportFieldList = [
    {
      name: "name",
      fieldName: "name",
      type: "text",
      title: "Enter your name",
      placeholder: "please enter name",
    },
    {
      name: "email",
      fieldName: "email",
      type: "email",
      title: "enter a email",
      placeholder: "please enter email",
    },
    {
      name: "please enter the details of your query",
      fieldName: "query",
      type: "textfield",
      title: "enter a query",
      placeholder: "please enter query",
    },
  ];

  const SupportSchema = Yup.object().shape({
    name: Yup.string().required("name is a required field"),
    email: Yup.string()
      .email("Invalid email")
      .required("email is a required field"),
    query: Yup.string()
      .min(11, "query must be minimum 11 letter")
      .max(251, "query can not be more then 251 letter")
      .required("query is a required field"),
  });

  const SubmitSignInHandler = async (values, { resetForm }) => {
    setIsLoading(true);
    try {
      const ID = Date.now();
      await database
        .ref(`SupportQueries/${ID}`)
        .set({ ...values, id: ID, timestamp: Date(), sendBy: user.uid });
      setAlertMessage({ type: "info", message: "form submitted successfully" });
    } catch (error) {
      console.log(error);
      setAlertMessage({ type: "error", message: error });
    } finally {
      resetForm(Support);
      setIsLoading(false);
    }
  };
  return (
    <div className={SupportFormStyle.container}>
      <Formik
        initialValues={Support}
        validationSchema={SupportSchema}
        onSubmit={SubmitSignInHandler}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          setTouched,
          touched,
          errors,
        }) => (
          <form onSubmit={handleSubmit}>
            <h1 className={`${SupportFormStyle.title}`}>Need Support?</h1>
            {SupportFieldList.map((field, index) => {
              return (
                <Field name={field.fieldName} key={index}>
                  {(props) => (
                    <InputField
                      {...props.field}
                      name={field.name}
                      title={field.title}
                      onError={
                        touched[field.fieldName] && errors[field.fieldName]
                      }
                      type={field.type}
                      Icon={field.Icon}
                      fieldName={field.fieldName}
                      Value={values[field.fieldName]}
                      inputClassName={SupportFormStyle.inputClassName}
                      onChange={(e) => {
                        handleChange(e);
                        setTouched({ ...touched, [field.fieldName]: true });
                      }}
                      placeholder={field.placeholder}
                      errorMessage={
                        <ErrorMessage
                          name={field.fieldName}
                          render={(msg) => <p className="EMS">{msg}</p>}
                        />
                      }
                    />
                  )}
                </Field>
              );
            })}
            <Button
              loading={isLoading}
              type="submit"
              style={{
                width: "100%",
                margin: "1.25em 0em",
                backgroundColor: "var(--colorC)",
              }}
            >
              submit
            </Button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default SupportForm;
