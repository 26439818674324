import { Base } from "layout";
import BookYourServicePageStyle from "css/pages/financePage/FinancePage.module.css";
import { InfoGatherForm } from "forms";

const BookYourServicePage = () => {
  return (
    <Base>
      <div className={BookYourServicePageStyle.Warper}>
        <div className={BookYourServicePageStyle.container}>
          <div
            className={BookYourServicePageStyle.imageSection}
            style={{
              backgroundImage: `url(https://images.unsplash.com/photo-1618353482480-61ca5a9a7879?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80)`,
            }}
          >
            <h1 className={`${BookYourServicePageStyle.title}`}>
              Book Your Service <br /> Enquiry
            </h1>
          </div>
          <div className={BookYourServicePageStyle.formSection}>
            <div className={BookYourServicePageStyle.formWarper}>
              <h1 className={`STUL ${BookYourServicePageStyle.formTitle}`}>
                Book Your Service Enquiry
              </h1>
              <InfoGatherForm />
            </div>
          </div>
        </div>
      </div>
    </Base>
  );
};

export default BookYourServicePage;
