import RemarkFormStyle from "css/components/Signin.module.css";
import Button from "core/Button";
import InputField from "core/InputField";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useAppContext, useIsMounted } from "hooks";
import { useState } from "react";
import { database } from "config";
const RemarkForm = ({ databaseRef, postSubmit }) => {
  const { setAlertMessage } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const IsMounted = useIsMounted();
  const Queries = {
    remarks: "",
  };

  const RemarkFormList = [
    {
      name: "Remarks",
      fieldName: "remarks",
      type: "textfield",
      title: "Enter Your Remarks",
      placeholder: "Type your remarks",
    },
  ];

  const RemarkFormSchema = Yup.object().shape({
    remarks: Yup.string()
      .trim()
      .min(11, "remarks must be minimum 11 letter")
      .max(251, "remarks can not be more then 251 letter")
      .required("remarks is a required field"),
  });

  const SubmitSignInHandler = async (values, { resetForm }) => {
    setIsLoading(true);
    try {
      await database.ref(databaseRef).update(values);
      setAlertMessage({
        type: "info",
        message: `Successfully Added`,
      });
      resetForm(Queries);
      postSubmit && postSubmit();
    } catch (error) {
      console.log(error);
      setAlertMessage({
        type: "error",
        message: error,
      });
    } finally {
      IsMounted.current && setIsLoading(false);
    }
  };
  return (
    <div className={RemarkFormStyle.container}>
      <Formik
        initialValues={Queries}
        validationSchema={RemarkFormSchema}
        onSubmit={SubmitSignInHandler}
      >
        {({ values, handleChange, setTouched, touched, errors }) => (
          <Form>
            <h2 className={`${RemarkFormStyle.title}`}>Add Remarks</h2>
            {RemarkFormList.map((field, index) => {
              return (
                <Field name={field.fieldName} key={index}>
                  {(props) => (
                    <InputField
                      {...props.field}
                      name={field.name}
                      title={field.title}
                      onError={
                        touched[field.fieldName] && errors[field.fieldName]
                      }
                      type={field.type}
                      Icon={field.Icon}
                      fieldName={field.fieldName}
                      Value={values[field.fieldName]}
                      inputClassName={RemarkFormStyle.inputClassName}
                      onChange={(e) => {
                        handleChange(e);
                        setTouched({ ...touched, [field.fieldName]: true });
                      }}
                      placeholder={field.placeholder}
                      errorMessage={
                        <ErrorMessage
                          name={field.fieldName}
                          render={(msg) => <p className="EMS">{msg}</p>}
                        />
                      }
                    />
                  )}
                </Field>
              );
            })}
            <Button
              loading={isLoading}
              type="submit"
              style={{
                width: "100%",
                margin: "1.25em 0em",
                backgroundColor: "var(--colorC)",
              }}
            >
              submit
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RemarkForm;
