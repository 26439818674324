import AccountDetailViewSectionStyle from "css/pages/account/AccountDetailViewSection.module.css";

const AccountDetailViewSection = ({ children, title }) => {
  return (
    <div
      className={AccountDetailViewSectionStyle.warper}
      id="AccountDetailViewSection"
    >
      <h2 className="PD05 STUL">{title}</h2>
      <div className={AccountDetailViewSectionStyle.container}>{children}</div>
    </div>
  );
};

export default AccountDetailViewSection;
