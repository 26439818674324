import { defaultAvatar } from "assets";
import { database, UploadImage } from "config";
import Button from "core/Button";
import InputField from "core/InputField";
import AccountFormStyle from "css/forms/AccountForm.module.css";
import { ErrorMessage, Field, Formik } from "formik";
import { useAppContext, useIsMounted } from "hooks";
import { useEffect, useState } from "react";
import * as Yup from "yup";

const AccountForm = () => {
  const IsMounted = useIsMounted();
  const [isLoading, setIsLoading] = useState(false);
  const [ISFormEditable, setIsFormEditable] = useState(false);
  const { user, setAlertMessage } = useAppContext();
  const [AccountFormValue, setAccountFormValue] = useState({
    name: "",
    phoneNumber: "",
    avatar: "",
  });

  useEffect(() => {
    IsMounted.current &&
      setAccountFormValue((prev) => ({
        ...prev,
        name: user?.name || "",
        avatar: user?.avatar?.imageUrl || defaultAvatar,
        phoneNumber: user?.phoneNumber || "",
      }));
  }, [IsMounted, setAccountFormValue, user]);

  const AccountFormFieldList = [
    {
      name: "name",
      fieldName: "name",
      type: "text",
      title: "please enter your name",
      placeholder: "Enter Your Name",
    },
    {
      name: "phone Number",
      fieldName: "phoneNumber",
      type: "number",
      title: "please enter your phone Number",
      placeholder: "Enter Your Phone Number",
    },
    {
      name: "avatar",
      fieldName: "avatar",
      type: "file",
      title: "please enter your avatar",
      placeholder: "Enter Your Avatar",
    },
  ];

  const AccountFormSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "name must be minimum 2 letter")
      .max(51, "name must be less then 51 letter")
      .required("name is a required field"),
    phoneNumber: Yup.number("phone number must be type of number")
      .test(
        "minlength",
        "phone number must be 10 digits",
        (val) => !val || (val && val.toString().length === 10)
      )
      .required("Phone number is required"),
    avatar: Yup.mixed().required("profile image is required"),
  });

  const SubmitAccountFormHandler = async (values, { resetForm }) => {
    if (user?.uid) {
      setIsLoading(true);
      try {
        const locationRef = `Avatar/${user.uid}}`;
        const imageData =
          values?.avatar &&
          (await UploadImage({
            dbReference: locationRef,
            file: values.avatar,
          }));
        IsMounted.current &&
          (await database.ref(`Users/${user.uid}`).update({
            name: values.name,
            phoneNumber: values.phoneNumber,
            avatar: imageData || user.avatar || "",
          }));
        IsMounted.current && resetForm(AccountFormValue);
        IsMounted.current && setIsFormEditable(false);
        setIsLoading(false);
        setAlertMessage({
          type: "info",
          message: "Account info successfully updated",
        });
      } catch (error) {
        setIsLoading(false);
        setAlertMessage({
          type: "error",
          message: error,
        });
      }
    }
  };

  return (
    <div className={AccountFormStyle.container}>
      <Formik
        initialValues={AccountFormValue}
        enableReinitialize
        validationSchema={AccountFormSchema}
        onSubmit={SubmitAccountFormHandler}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          setTouched,
          touched,
          errors,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            {AccountFormFieldList.map((field, index) => {
              return (
                <Field name={field.fieldName} key={index}>
                  {(props) => (
                    <InputField
                      // {...props.field}
                      disabled={!ISFormEditable}
                      name={field.name}
                      title={field.title}
                      onTouched={touched[field.fieldName]}
                      initialImage={values[field.name]}
                      onError={
                        touched[field.fieldName] && errors[field.fieldName]
                      }
                      type={field.type}
                      Icon={field.Icon}
                      fieldName={field.fieldName}
                      Value={values[field.fieldName]}
                      setFieldValue={setFieldValue}
                      onChange={(e) => {
                        handleChange(e);
                        setTouched({ ...touched, [field.fieldName]: true });
                      }}
                      placeholder={field.placeholder}
                      inputClassName={AccountFormStyle.inputClassName}
                      errorMessage={
                        <ErrorMessage
                          name={field.fieldName}
                          render={(msg) => <p className="EMS">{msg}</p>}
                        />
                      }
                    />
                  )}
                </Field>
              );
            })}
            {ISFormEditable && (
              <Button
                loading={isLoading}
                type="submit"
                style={{
                  width: "100%",
                  backgroundColor: "var(--colorA)",
                }}
              >
                save all changes
              </Button>
            )}
            {!ISFormEditable && (
              <Button
                onClick={() => setIsFormEditable(true)}
                type="button"
                style={{
                  width: "100%",
                  backgroundColor: "var(--colorA)",
                }}
              >
                edit details
              </Button>
            )}
          </form>
        )}
      </Formik>
    </div>
  );
};

export default AccountForm;
