import { Towing } from "assets";
import { Button } from "core";
import NotFoundStyle from "css/pages/NotFound.module.css";
import { Link } from "react-router-dom";

const NotFound = ({ image }) => {
  return (
    <div className={NotFoundStyle.container}>
      <div
        className={NotFoundStyle.imageSection}
        style={{ backgroundImage: `url(${image || Towing})` }}
      ></div>

      <Link to="/">
        <Button>
          <span>
            <h1>404 Not Found</h1>
            <p>click me to go back</p>
          </span>
        </Button>
      </Link>
    </div>
  );
};

export default NotFound;
