import ExpandCollapseStyle from "css/core/ExpandCollapse.module.css";
import { useState } from "react";
import { CSSTransition } from "react-transition-group";

const ExpandCollapse = ({ title, children }) => {
  const [IsExpand, setIsExpand] = useState(false);
  return (
    <div
      className={`${ExpandCollapseStyle.container} ${
        IsExpand ? ExpandCollapseStyle.Expand : ExpandCollapseStyle.Collapse
      }`}
    >
      <span className={ExpandCollapseStyle.titleWarper}>
        <b>{title || "untitled"}</b>
        <span
          onClick={() => setIsExpand((prev) => !prev)}
          className="material-icons-round md-36"
        >
          {IsExpand ? "highlight_off" : "expand_more"}
        </span>
      </span>
      <CSSTransition
        in={IsExpand}
        timeout={0}
        unmountOnExit
        classNames={{
          enterActive: ExpandCollapseStyle.myNodeEnterActive,
          enterDone: ExpandCollapseStyle.myNodeEnterDone,
          exitActive: ExpandCollapseStyle.myNodeExitActive,
          exitDone: ExpandCollapseStyle.myNodeExitDone,
        }}
      >
        <span className={ExpandCollapseStyle.Contents}>{children}</span>
      </CSSTransition>
    </div>
  );
};

export default ExpandCollapse;
