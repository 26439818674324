import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import SideMenuStyle from "css/components/header/SideMenu.module.css";
import { BottomShutter } from "core";
import { SignInForm, SignUpForm } from "forms";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "hooks";
import { auth } from "config";

const SideMenu = ({ IsMenuOpen, closeFC }) => {
  const location = useLocation();
  const [IsShutterOpen, setIsShutterOpen] = useState(false);
  const { user, setAlertMessage } = useAppContext();
  const navigate = useNavigate();

  const SignOutHandler = async () => {
    try {
      await auth.signOut();
      setAlertMessage({ type: "info", message: "signOut successfully" });
    } catch (error) {
      setAlertMessage({ type: "error", message: error.message });
    }
  };

  return (
    <>
      <CSSTransition
        in={IsMenuOpen}
        timeout={250}
        unmountOnExit
        classNames={{
          enterActive: SideMenuStyle.myNodeEnterActive,
          enterDone: SideMenuStyle.myNodeEnterDone,
          exitActive: SideMenuStyle.myNodeExitActive,
          exitDone: SideMenuStyle.myNodeExitDone,
        }}
      >
        <div
          onClick={(e) =>
            e.target.matches("[data-menu]") && closeFC && closeFC(false)
          }
          data-menu
          className={SideMenuStyle.SideMenu}
        >
          <nav className={SideMenuStyle.container}>
            {!user?.fullName ? (
              <header
                onClick={() => setIsShutterOpen(true)}
                className={SideMenuStyle.header}
              >
                <span className="material-icons-round">account_circle</span>
                <h2>Login/Signup</h2>
                <span className="material-icons-round">chevron_right</span>
              </header>
            ) : (
              <Link to="/account/account-information">
                <header className={SideMenuStyle.header}>
                  <span className="material-icons-round">account_circle</span>
                  <h2>hello, {user?.fullName?.split(" ")[0]}</h2>
                  <span className="material-icons-round">chevron_right</span>
                </header>
              </Link>
            )}
            <section className={SideMenuStyle.menusSection}>
              <div className={SideMenuStyle.menusItem}>
                <span
                  className={`material-icons-outlined md18 ${SideMenuStyle.menuItemIcon}`}
                >
                  car_rental
                </span>
                <Link to="/sell-car">
                  <h5 className={SideMenuStyle.menusItemTitle}>
                    sell your car
                  </h5>
                </Link>
              </div>
              <Link to="/compare-cars" className={SideMenuStyle.menusItem}>
                <span
                  className={`material-icons-outlined md18 ${SideMenuStyle.menuItemIcon}`}
                >
                  compare_arrows
                </span>
                <h5 className={SideMenuStyle.menusItemTitle}>Compare Cars</h5>
              </Link>
              <div
                className={SideMenuStyle.menusItem}
                onClick={() => navigate("/finance")}
              >
                <span
                  className={`material-icons-outlined md18 ${SideMenuStyle.menuItemIcon}`}
                >
                  request_quote
                </span>
                <h5 className={SideMenuStyle.menusItemTitle}>Finance</h5>
              </div>
              <div
                className={SideMenuStyle.menusItem}
                onClick={() => navigate("/insurance")}
              >
                <span
                  className={`material-icons-outlined md18 ${SideMenuStyle.menuItemIcon}`}
                >
                  health_and_safety
                </span>
                <h5 className={SideMenuStyle.menusItemTitle}>Insurance</h5>
              </div>
              <div
                className={SideMenuStyle.menusItem}
                onClick={() => navigate("/registration")}
              >
                <span
                  className={`material-icons-outlined md18 ${SideMenuStyle.menuItemIcon}`}
                >
                  how_to_reg
                </span>
                <h5 className={SideMenuStyle.menusItemTitle}>Registration</h5>
              </div>
              <div
                className={SideMenuStyle.menusItem}
                onClick={() => navigate("/book-your-service")}
              >
                <span
                  className={`material-icons-outlined md18 ${SideMenuStyle.menuItemIcon}`}
                >
                  menu_book
                </span>
                <h5 className={SideMenuStyle.menusItemTitle}>
                  Book Your Service
                </h5>
              </div>
              <div
                className={SideMenuStyle.menusItem}
                onClick={() => navigate("/brake-down-service")}
              >
                <span
                  className={`material-icons-outlined md18 ${SideMenuStyle.menuItemIcon}`}
                >
                  settings
                </span>
                <h5 className={SideMenuStyle.menusItemTitle}>
                  Break Down Service
                </h5>
              </div>
              <div className={SideMenuStyle.menusItem}>
                <span
                  className={`material-icons-outlined md18 ${SideMenuStyle.menuItemIcon}`}
                >
                  article
                </span>
                <h5 className={SideMenuStyle.menusItemTitle}>blog</h5>
              </div>
              <div className={SideMenuStyle.menusItem}>
                <span
                  className={`material-icons-outlined md18 ${SideMenuStyle.menuItemIcon}`}
                >
                  reviews
                </span>
                <h5 className={SideMenuStyle.menusItemTitle}>
                  Customer Reviews
                </h5>
              </div>
              <Link to="/faqs" className={SideMenuStyle.menusItem}>
                <span
                  className={`material-icons-outlined md18 ${SideMenuStyle.menuItemIcon}`}
                >
                  quiz
                </span>
                <h5 className={SideMenuStyle.menusItemTitle}>FAQs</h5>
              </Link>
              {user && (
                <span
                  className={SideMenuStyle.menusItem}
                  onClick={SignOutHandler}
                >
                  <span
                    className={`material-icons-outlined md18 ${SideMenuStyle.menuItemIcon}`}
                  >
                    logout
                  </span>
                  <h5 className={SideMenuStyle.menusItemTitle}>Log Out</h5>
                </span>
              )}
            </section>
          </nav>
          {/* menu btn */}
          <div className={SideMenuStyle.closeBtn}>
            <div
              className={`${SideMenuStyle.mobileOnlyElement} ${
                SideMenuStyle.menuIconBtn
              } ${IsMenuOpen && SideMenuStyle.active}`}
              onClick={() => closeFC && closeFC(false)}
            >
              <span className={SideMenuStyle.menuIconBars}></span>
              <span className={SideMenuStyle.menuIconBars}></span>
              <span className={SideMenuStyle.menuIconBars}></span>
            </div>
          </div>
        </div>
      </CSSTransition>
      <BottomShutter IsOpen={IsShutterOpen} closeFC={setIsShutterOpen}>
        {location.hash === "#signup" ? (
          <SignUpForm onFinish={() => setIsShutterOpen(false)} />
        ) : (
          <SignInForm onFinish={() => setIsShutterOpen(false)} />
        )}
      </BottomShutter>
    </>
  );
};

export default SideMenu;
