import React from "react";
import AlertPopUpStyle from "css/core/AlertPopUp.module.css";
import { useEffect } from "react";
import { useAppContext } from "hooks";
import { Alert } from "@mui/material";

const AlertPopUp = ({ type, text }) => {
  const { setAlertMessage } = useAppContext();
  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertMessage({ type: "", text: "" });
    }, 5000);
    return () => clearTimeout(timer);
  }, [setAlertMessage]);
  return (
    <div className={AlertPopUpStyle.container}>
      <Alert
        variant="filled"
        severity={type}
        onClose={() => setAlertMessage({ type: "", text: "" })}
      >
        {text}
      </Alert>
    </div>
  );
};

export default AlertPopUp;
