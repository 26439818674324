import CompareSearchCardStyle from "css/components/CompareSearchCard.module.css";
import { useSearchParams } from "react-router-dom";

const CompareSearchCard = ({ src, name, id, comparePosition, onSelect }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div
      className={CompareSearchCardStyle.searchItem}
      onClick={() => {
        //TODO: Make A Hooks To Set Search Params In Filter Car Section
        searchParams.set(comparePosition, id);
        setSearchParams(searchParams.toString());
        onSelect && onSelect(comparePosition, id);
      }}
    >
      <span
        className={CompareSearchCardStyle.searchItemImageSection}
        style={{
          backgroundImage: `url(${src})`,
        }}
      ></span>
      <span className={CompareSearchCardStyle.searchItemContent}>{name}</span>
    </div>
  );
};

export default CompareSearchCard;
