import { Base } from "layout";
import AccountStyle from "css/pages/account/Account.module.css";
import AccountMenuSection from "./AccountMenuSection";
import { Outlet } from "react-router-dom";

const Account = () => {
  return (
    <Base>
      <div className={AccountStyle.warper}>
        <div className={AccountStyle.container}>
          <aside className={AccountStyle.AccountMenuSectionWarper}>
            <AccountMenuSection />
          </aside>
          <aside className={AccountStyle.AccountDetailViewSectionWarper}>
            <Outlet />
          </aside>
        </div>
      </div>
    </Base>
  );
};

export default Account;
