import React from "react";
import ListItemsStyle from "css/core/ListItems.module.css";
import PropTypes from "prop-types";

const ListItems = ({
  title,
  clickBtn,
  onClickFC,
  children,
  IsPadding,
  IsLoading,
}) => {
  return (
    <div
      className={ListItemsStyle.container}
      style={{ padding: !IsPadding && "0px" }}
    >
      <div
        className={ListItemsStyle.headerSection}
        style={{ padding: !IsPadding && "0px" }}
      >
        <h2>{title}</h2>
        {clickBtn && (
          <button
            className="CP"
            disabled={IsLoading}
            onClick={() => onClickFC && onClickFC()}
          >
            {IsLoading ? <span className="loaderOneWhite"></span> : clickBtn}
          </button>
        )}
      </div>
      <div className={ListItemsStyle.items}>{children}</div>
    </div>
  );
};

ListItems.propTypes = {
  title: PropTypes.string,
  IsPadding: PropTypes.bool,
  clickBtn: PropTypes.string,
  onClickFC: PropTypes.func,
  children: PropTypes.any,
};
ListItems.defaultProps = {
  IsPadding: true,
};

export default ListItems;
