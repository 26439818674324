import { database } from "config";
import { useCallback, useEffect, useState } from "react";
import useIsMounted from "./useIsMounted";

const useUsedCarList = () => {
  const IsMounted = useIsMounted();
  const [isLoading, setIsLoading] = useState(true);

  const [usedCarList, setUsedCarList] = useState([]);

  const getUsedCarLIst = useCallback(async () => {
    IsMounted.current && setIsLoading(true);
    let allCars = [];
    IsMounted.current &&
      (await database.ref(`InventoryCars`).once("value", async (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const stores = Object.entries(data).map((item) => item[0]);
          for (const storeId of stores) {
            await database
              .ref(`InventoryCars/${storeId}`)
              .once("value", async (snapshot) => {
                const storeData = snapshot.val();
                storeData &&
                  IsMounted.current &&
                  allCars.push(
                    ...Object.entries(storeData).map((item) => ({
                      ...item[1]?.ownerDetails?.inventoryData,
                      id: item[0],
                      storeId: storeId,
                    }))
                  );
              });
          }
          IsMounted.current &&
            setUsedCarList(
              allCars.sort(
                (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
              )
            );
          IsMounted.current && setIsLoading(false);
        }
      }));
  }, [IsMounted]);

  useEffect(() => {
    getUsedCarLIst();
  }, [getUsedCarLIst]);

  return { usedCarList, isLoading };
};

export default useUsedCarList;
