import { Button, InputField } from "core";
import SellCarFormStyle from "css/pages/sellCar/SellCarForm.module.css";
import { ErrorMessage, Field, Formik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";

const MetaDataForm = ({
  setPresentStep,
  setFormData,
  formData,
  onNext,
  onBack,
}) => {
  const [sellCareValues, setSellCareValues] = useState({
    brand: "",
    model: "",
    variant: "",
    makeMonth: "",
    makeYear: "",
    registrationMonth: "",
    registrationYear: "",
    registered_type: "",
    car_registration_number: "",
    kilometers_driven: "",
    car_present_city: "",
    registered_owner_name: "",
    mail_id: "",
    alternative_contact_number: "",
    car_color: "",
    body_type: "",
    fuel_type: "",
    transmission_type: "",
    insurance_validity: "",
    insurance_company_name: "",
    insurance_policy_number: "",
  });
  //step:1
  const sellCarFormFieldList = [
    {
      name: "brand",
      fieldName: "brand",
      type: "select",
      options: [
        "",
        "MFG",
        "ASHOK LEYLAND",
        "CITROEN",
        "FORCE",
        "HONDA",
        "HYUNDAI",
        "JEEP",
        "KIA",
        "MAHINDRA",
        "MARUTI SUZUKI",
        "NISSAN",
        "RENAULT",
        "SKODA",
        "TATA",
        "TOYOTA",
        "VW",
        "CHEVROLET",
        "HINDUSTAN",
        "AUDI",
        "MERCEDES BENZ",
        "BMW",
        "JAGUAR",
        "VOLVO",
        "FORD",
        "FIAT",
        "PORSCHE",
        "GM",
        "DAEWOO",
      ],
      title: "please enter your brand",
      placeholder: "Enter your car brand",
    },
    {
      name: "model",
      fieldName: "model",
      type: "text",
      title: "please enter your model",
      placeholder: "Enter your car model",
    },
    {
      name: "variant",
      fieldName: "variant",
      type: "text",
      title: "please enter your variant",
      placeholder: "Enter your car variant",
    },
    {
      name: "Make Month",
      fieldName: "makeMonth",
      type: "select",
      options: [
        "",
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      title: "please enter your make month",
      placeholder: "Enter month make month",
    },
    {
      name: "Make Year",
      fieldName: "makeYear",
      type: "select",
      options: [
        "",
        new Date().getFullYear(),
        new Date().getFullYear() - 1,
        new Date().getFullYear() - 2,
        new Date().getFullYear() - 3,
        new Date().getFullYear() - 4,
        new Date().getFullYear() - 5,
        new Date().getFullYear() - 6,
        new Date().getFullYear() - 7,
        new Date().getFullYear() - 8,
        new Date().getFullYear() - 9,
        new Date().getFullYear() - 10,
        new Date().getFullYear() - 11,
        new Date().getFullYear() - 12,
        new Date().getFullYear() - 13,
        new Date().getFullYear() - 14,
        new Date().getFullYear() - 15,
      ],
      title: "please enter your make year",
      placeholder: "Enter make year",
    },
    {
      name: "Registration Month",
      fieldName: "registrationMonth",
      type: "select",
      options: [
        "",
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      title: "please enter your registration month",
      placeholder: "Enter registration month",
    },
    {
      name: "Registration Year",
      fieldName: "registrationYear",
      type: "select",
      options: [
        "",
        new Date().getFullYear(),
        new Date().getFullYear() - 1,
        new Date().getFullYear() - 2,
        new Date().getFullYear() - 3,
        new Date().getFullYear() - 4,
        new Date().getFullYear() - 5,
        new Date().getFullYear() - 6,
        new Date().getFullYear() - 7,
        new Date().getFullYear() - 8,
        new Date().getFullYear() - 9,
        new Date().getFullYear() - 10,
        new Date().getFullYear() - 11,
        new Date().getFullYear() - 12,
        new Date().getFullYear() - 13,
        new Date().getFullYear() - 14,
        new Date().getFullYear() - 15,
      ],
      title: "please enter your registration year",
      placeholder: "Enter registration year",
    },
    // {
    //   name: "car registered city",
    //   fieldName: "car_registered_city",
    //   type: "text",
    //   title: "please enter your car registered city",
    //   placeholder: "Enter your car registered city",
    // },
    {
      name: "registration type",
      fieldName: "registered_type",
      type: "select",
      options: ["", "PRIVATE", "COMMERCIAL"],
      title: "please enter your registered type",
      placeholder: "Enter your registered type",
    },

    {
      name: "car registration number",
      fieldName: "car_registration_number",
      type: "text",
      title: "please enter your car registration number",
      placeholder: "Enter your car registration number",
    },
    {
      name: "kilometers driven",
      fieldName: "kilometers_driven",
      type: "number",
      title: "please enter your kilometers driven",
      placeholder: "Enter car kilometers driven",
    },
    {
      name: "car present city",
      fieldName: "car_present_city",
      datalist: [
        "Agra",
        "Ahmedabad",
        "Bangalore",
        "Chennai",
        "Delhi",
        "Hyderabad",
        "Jaipur",
        "Kolkata",
        "Mumbai",
        "Pune",
        "Rajkot",
        "Surat",
        "Abiramam",
        "Abohar",
        "Adampur",
        "Adilabad",
        "Adimaly",
        "Agartala",
        "Ahmednagar",
        "Ajmer",
        "Akola",
        "Alappuzha",
        "Aligarh",
        "Allahabad",
        "Alwar",
        "Ambala",
        "Ambedkar-Nagar",
        "Amravati",
        "Amreli",
        "Amritsar",
        "Anakapalli",
        "Anand",
        "Anantapur",
        "Anantpur",
        "Angul",
        "Ankleshwar",
        "Arani",
        "Araria",
        "Ariyalur",
        "Armoor",
        "Arrah",
        "Aruppukottai",
        "Auraiya",
        "Aurangabad",
        "Azamgarh",
        "Badaun",
        "Bagalkot",
        "Bahraich",
        "Balangir",
        "Balasore",
        "Ballia",
        "Bambolim",
        "Banaskantha",
        "Banda",
        "Banka",
        "Barabanki",
        "Bardez",
        "Bareilly",
        "Bargarh",
        "Barkatpura",
        "Barpeta",
        "Bastar",
        "Basti",
        "Beed",
        "Begusarai",
        "Belgaum",
        "Bellary",
        "Bhadohi",
        "Bhadrak",
        "Bhagalpur",
        "Bhandara",
        "Bharuch",
        "Bhatkal",
        "Bhavani",
        "Bhavnagar",
        "Bhiwani",
        "Bhojpur",
        "Bhopal",
        "Bhubhneshwar",
        "Bhuj",
        "Bhusawal",
        "Bicholim",
        "Bidar",
        "Bijapur",
        "Bijnor",
        "Bikaner",
        "Bilaspur",
        "Bolangir",
        "Bonda",
        "Bongaigaon",
        "Budaun",
        "Bulandshahar",
        "Buldana",
        "Buldhana",
        "Buvanagiri",
        "Buxar",
        "Byasanagar",
        "Cachar",
        "Cambay",
        "Canacona",
        "Chamaraja-Nagar",
        "Chandauli",
        "Chandigarh",
        "Chandrapur",
        "Changlang",
        "Chaziabad",
        "Chickaballapur",
        "Chikmagalur",
        "Chingalpattu",
        "Chingannur",
        "Chitradurga",
        "Chitrakoot",
        "Chittoor",
        "Choondal",
        "Coimbatore",
        "Coorg",
        "Cuddalore",
        "Cuddapah",
        "Curchorem",
        "Cuttack",
        "Dahod",
        "Dakshina-Kannada",
        "Darbhanga",
        "Darrang",
        "Davangere",
        "Dehradun",
        "Deoband",
        "Deogarh",
        "Deoria",
        "Dhamtari",
        "Dhanbad",
        "Dharapuram",
        "Dharmapuri",
        "Dharwad",
        "Dhemaji",
        "Dhenkanal",
        "Dhubri",
        "Dhule",
        "Dibrugarh",
        "Dindigul",
        "Dodballapur",
        "Donapaula",
        "Duliajan",
        "Durg",
        "East-Champaran",
        "East-Godavary",
        "Edayaranmula",
        "Ernakulam",
        "Erode",
        "Eruvadi",
        "Etah",
        "Etawah",
        "Faizabad",
        "Faridabad",
        "Farrukhabad",
        "Fatehabad",
        "Fatehpur",
        "Fatorda",
        "Firozabad",
        "Gadag",
        "Gadchiroli",
        "Gandhinagar",
        "Gangtok",
        "Ganjam",
        "Gautam-Budh-Nagar",
        "Gaya",
        "Ghaziabad",
        "Ghazipur",
        "Goa",
        "Goalpara",
        "Golaghat",
        "Gonda",
        "Gondia",
        "Gopalganj",
        "Gorakhpur",
        "Gulbarga",
        "Guntur",
        "Gurgaon",
        "Gurugram",
        "Guwahati",
        "Hailakandi",
        "Hamirpur",
        "Hardoi",
        "Harihar",
        "Hassan",
        "Haveri",
        "Hingoli",
        "Hissar",
        "Hobur",
        "Hosur",
        "Hubli",
        "Idukki",
        "Ilhas",
        "Imphal",
        "Indore",
        "Itanagar",
        "Jabalpur",
        "Jagadhri",
        "Jagatsinghpur",
        "Jaggayyapet",
        "Jahanabad",
        "Jajpur",
        "Jalaun",
        "Jalgaon",
        "Jalna",
        "Jammu",
        "Jamnagar",
        "Jamshedpur",
        "Jamui",
        "Janjgir-Champa",
        "Jashpur",
        "Jaunpur",
        "Jayamkondam",
        "Jehanabad",
        "Jhajjar",
        "Jhansi",
        "Jharsuguda",
        "Jind",
        "Jorhat",
        "Jp-Nagar",
        "Junagadh",
        "Kadag",
        "Kaimur",
        "Kaira",
        "Kaithal",
        "Kakinda",
        "Kalahandi",
        "Kalavad",
        "Kalka",
        "Kalol",
        "Kamarajar",
        "Kanathur",
        "Kanchipuram",
        "Kanker",
        "Kannauj",
        "Kannur",
        "Kanpur",
        "Kanyakumari",
        "Karad",
        "Karnal",
        "Karnataka",
        "Karur",
        "Kasargod",
        "Katihar",
        "Kaushambi",
        "Kendrapada",
        "Keonjhar",
        "Keshod",
        "Khagaria",
        "Khambhaliya",
        "Kheda",
        "Khurda",
        "Kishanganj",
        "Kochi",
        "Kodagu",
        "Kohima",
        "Kolar",
        "Kolhapur",
        "Kollam",
        "Kollidam",
        "Komarapalayam",
        "Komlloor",
        "Koppa",
        "Koppal",
        "Koraput",
        "Korba",
        "Kota",
        "Kottayam",
        "Kozhikode",
        "Kozhikode",
        "Krishnagiri",
        "Kurukshetra",
        "Kushinagar",
        "Kutch",
        "Kuttippuram",
        "Lakhimpur-Kheri",
        "Lakhissarai",
        "Lalitpur",
        "Latur",
        "Lower-Dibang-Valley",
        "Lower-Subansiri",
        "Lucknow",
        "Ludhiana",
        "Madhepura",
        "Madhubani",
        "Madurai",
        "Maharajanagar",
        "Maharajganj",
        "Mahasamund",
        "Mahendragarh",
        "Mahesana",
        "Mainpuri",
        "Malappuram",
        "Mandya",
        "Mangalore",
        "Mansa",
        "Mapusa",
        "Marcela",
        "Mardol",
        "Margao",
        "Margoa",
        "Mathura",
        "Mau",
        "Maunathbanjan",
        "Mayurbhanj",
        "Meerut",
        "Merces",
        "Mettupalayam",
        "Miraj",
        "Mirzapur",
        "Modasa",
        "Mohali",
        "Moodabidri",
        "Moothakunnam",
        "Moradabad",
        "Mughalsarai",
        "Munger",
        "Muzaffarnagar",
        "Muzaffarpur",
        "Mysore",
        "Nabarangpur",
        "Nagai",
        "Nagapattinam",
        "Nagercoil",
        "Nagpur",
        "Nalanda",
        "Namakkal",
        "Nanded",
        "Nandurbar",
        "Naruaul",
        "Nashik",
        "Navalgund",
        "Navsari",
        "Nawada",
        "Nayagarh",
        "Nellai",
        "Nilgiris",
        "Noida",
        "North-Arcot",
        "North-Chalakudy",
        "North-Goa",
        "North-Kannada",
        "North-Kodagu",
        "Nuapada",
        "Osmanabad",
        "Palakkad",
        "Palani",
        "Palanpur",
        "Pallakkad",
        "Pallipat",
        "Palmal",
        "Pampady",
        "Panaji",
        "Panchkula",
        "Panchmahal",
        "Panipat",
        "Panjim",
        "Papumpare",
        "Parbhani",
        "Paschim-Champaran",
        "Patan",
        "Pathanamthitta",
        "Patna",
        "Pattinam",
        "Pattukkottai",
        "Perambalur",
        "Peravoor",
        "Periyakulam",
        "Phulbani",
        "Pilibhit",
        "Pinjore",
        "Ponda",
        "Pondicherry",
        "Porbandar",
        "Porkulam",
        "Porvorim",
        "Prabudhnagar",
        "Pratapgarh",
        "Pudukkottai",
        "Purba-Champaran",
        "Puri",
        "Purnea",
        "Quepem",
        "Rae-Bareilly",
        "Raichur",
        "Raigad",
        "Raigarh",
        "Raipur",
        "Rajnandgaon",
        "Ramanagaram",
        "Ramanathapuram",
        "Ramnad",
        "Rampur",
        "Ranchi",
        "Ranibennur",
        "Ratnagiri",
        "Rayagada",
        "Rewari",
        "Rohtak",
        "Rohtas",
        "Ron",
        "Rourkela",
        "Sabarkantha",
        "Saharanpur",
        "Saharsa",
        "Salcette",
        "Salem",
        "Samastipur",
        "Sambalpur",
        "Sangli",
        "Sanquelim",
        "Saran",
        "Satara",
        "Saundattp",
        "Secundarabad",
        "Shahjahanpur",
        "Sheikhpura",
        "Shillong",
        "Shimla",
        "Shimoga",
        "Shivamogga",
        "Siang",
        "Siddarth-Nagar",
        "Sindhudurg",
        "Sirsa",
        "Sitamarhi",
        "Sitapur",
        "Sivaganga",
        "Siwan",
        "Solapur",
        "Somwarpet",
        "Sonbhadra",
        "Sonepat",
        "Sonepur",
        "South-Arcot",
        "South-Kodagu",
        "Srinagar",
        "Subansiri",
        "Sultanpur",
        "Sundargarh",
        "Supaul",
        "Surendranagar",
        "Surguja",
        "Talaja",
        "Taleigao",
        "Tapi",
        "Thane",
        "Thanjavur",
        "The-Nilgiri",
        "Theni",
        "Thiruvalla",
        "Thiruvallore",
        "Thiruvananthapuram",
        "Thiruvannamalai",
        "Thiruvarur",
        "Thrissur",
        "Tiruchirappalli",
        "Tirunelveli",
        "Tirupur",
        "Tiruvalla",
        "Tiruvallur",
        "Tiruvarur",
        "Trichy",
        "Trivendrum",
        "Tumkur",
        "Tuticorin",
        "Udaipur",
        "Udupi",
        "Unjha",
        "Unnao",
        "Uppinangady",
        "Uttar-Kannada",
        "Vadakkuthu",
        "Vadalur",
        "Vadodara",
        "Vaishali",
        "Valsad",
        "Vapi",
        "Varanasi",
        "Vasco-Da-Gama",
        "Vedaraniam",
        "Vellore",
        "Venmony",
        "Vidyanagar",
        "Vijaywada",
        "Villupuram",
        "Viramgam",
        "Virudhunagar",
        "Vishakhapatnam",
        "Visnagar",
        "Wadhwan",
        "Wardha",
        "Washim",
        "Wayanad",
        "West-Champaran",
        "Xeldem-Tilamol",
        "Yadgir",
        "Yamuna-Nagar",
        "Yavatmal",
        "Yerraguntla",
        "Zahirabad",
        "Zirakpur",
      ],
      title: "please enter your car's present city",
      placeholder: "Enter car's present city",
    },
    {
      name: "Registered Owner Name",
      fieldName: "registered_owner_name",
      type: "text",
      title: "please enter owner name",
      placeholder: "Enter car owner name",
    },
    {
      name: "Mail Id",
      fieldName: "mail_id",
      type: "email",
      title: "please enter mail id",
      placeholder: "Enter mail id",
    },
    {
      name: "alternative contact number",
      fieldName: "alternative_contact_number",
      type: "number",
      title: "please enter alternative contact number if any",
      placeholder: "Enter alternative contact number if any",
    },
    {
      name: "car Color",
      fieldName: "car_color",
      type: "text",
      title: "please enter car color",
      placeholder: "Enter car color",
    },
    {
      name: "Category",
      fieldName: "body_type",
      type: "select",
      options: ["", "SUV", "MUV", "Sedan", "Hatchback", "LifeStyle", "Cargo"],
      title: "please enter Category",
      placeholder: "Enter Category",
    },
    {
      name: "transmission Type",
      fieldName: "transmission_type",
      type: "select",
      options: ["", "Manual", "Automatic"],
      title: "please enter Category",
      placeholder: "Enter Category",
    },
    {
      name: "fuel type",
      fieldName: "fuel_type",
      type: "select",
      options: ["", "Petrol", "Diesel", "CNG", "Electric"],
      title: "please enter fule type",
      placeholder: "Enter fule type",
    },
    {
      name: "Insurance validity",
      fieldName: "insurance_validity",
      type: "select",
      options: ["", "Yes", "No"],
      title: "please enter insurance validity",
      placeholder: "Enter insurance validity",
    },
    {
      name: "Insurance Company Name",
      fieldName: "insurance_company_name",
      type: "text",
      title: "please enter insurance company name",
      placeholder: "Enter insurance company name",
    },
    {
      name: "Insurance Policy Number",
      fieldName: "insurance_policy_number",
      type: "text",
      title: "please enter insurance policy number",
      placeholder: "Enter insurance policy number",
    },
  ];

  const SellCarFormSchema = Yup.object().shape({
    //step:1 car info
    brand: Yup.string().required("brand is required"),
    model: Yup.string().required("model is required"),
    variant: Yup.string().required("variant is required"),
    makeMonth: Yup.string().required("make month is required"),
    makeYear: Yup.string().required("make year is required"),
    registrationMonth: Yup.string().required("registration month is required"),
    registrationYear: Yup.string().required("registration year is required"),
    registered_type: Yup.string().required("car registered type is required"),
    car_registration_number: Yup.string()
      .trim()
      .matches(
        /(([A-Za-z]){2,3}(|-)(?:[0-9]){1,2}(|-)(?:[A-Za-z]){2}(|-)([0-9]){1,4})|(([A-Za-z]){2,3}(|-)([0-9]){1,4})/,
        "car number is not in correct format"
      )
      .required("car number is required"),
    kilometers_driven: Yup.number().required("kilometers driven is required"),
    car_present_city: Yup.string().required("car present city is required"),
    registered_owner_name: Yup.string().required(
      "registered owner name required"
    ),
    mail_id: Yup.string()
      .email("enter a valid email")
      .required("mail id required"),
    alternative_contact_number: Yup.string(),
    car_color: Yup.string(),
    body_type: Yup.string().required("car category is required"),
    fuel_type: Yup.string().required("fuel type is required"),
    transmission_type: Yup.string().required("transmission type is required"),
    insurance_validity: Yup.string().required("insurance validity is required"),
    insurance_company_name: Yup.string().required(
      "insurance company_name is required"
    ),
    insurance_policy_number: Yup.string().required(
      "insurance policy number is required"
    ),
  });

  useEffect(() => {
    if (formData.MetaDataFormData) {
      setSellCareValues(formData.MetaDataFormData);
    }
  }, [formData]);

  const submitForm = async (values) => {
    await setFormData((prev) => ({ ...prev, MetaDataFormData: values }));
    onNext && onNext();
  };

  return (
    <div style={{ width: "100%" }}>
      <Formik
        initialValues={sellCareValues}
        enableReinitialize
        onSubmit={submitForm}
        validationSchema={SellCarFormSchema}
      >
        {({
          handleSubmit,
          touched,
          errors,
          values,
          setTouched,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <span className="FRSB">
              <h1 className="STUL">Add Car Info</h1>
            </span>
            <div className={SellCarFormStyle.inputWarper}>
              {sellCarFormFieldList.map((field, index) => {
                return (
                  <Field name={field.fieldName} key={index}>
                    {(props) => (
                      <InputField
                        {...props.field}
                        name={field.name}
                        datalist={field.datalist}
                        title={field.title}
                        options={field.options}
                        onError={
                          touched[field.fieldName] && errors[field.fieldName]
                        }
                        type={field.type}
                        Icon={field.Icon}
                        fieldName={field.fieldName}
                        Value={values[field.fieldName]}
                        setFieldValue={setFieldValue}
                        onChange={handleChange}
                        placeholder={field.placeholder}
                        inputClassName={SellCarFormStyle.inputClassName}
                        errorMessage={
                          <ErrorMessage
                            name={field.fieldName}
                            render={(msg) => <p className="EMS">{msg}</p>}
                          />
                        }
                      />
                    )}
                  </Field>
                );
              })}
            </div>
            <div className={SellCarFormStyle.btnWarper}>
              {onBack && (
                <Button
                  type="button"
                  style={{ margin: "1em", backgroundColor: "var(--colorA)" }}
                  onClick={onBack}
                >
                  back
                </Button>
              )}
              <Button
                type="submit"
                style={{ margin: "1em" }}
                LastIcon={
                  <span className="material-icons-round">arrow_forward</span>
                }
              >
                save and continue
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default MetaDataForm;
