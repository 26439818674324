import FileInputStyle from "css/core/inputField/FileInput.module.css";
import { useState } from "react";
import { ImageInfo } from "helper";

const FileInput = ({
  initialImage,
  fieldName,
  placeholder,
  setFieldValue,
  accept,
  ValidateImage,
  type,
  setImageError,
  onError,
  inputClassName,
  ...props
}) => {
  const [imageLink, setImageLink] = useState(initialImage);

  const onFileChange = async (e) => {
    console.log(ValidateImage);
    if (ValidateImage) {
      console.log(ValidateImage.width);
      console.log(ValidateImage.height);
      const image = await ImageInfo(e.target.files[0]);
      if (
        image.width === ValidateImage.width &&
        image.height === ValidateImage.height
      ) {
        setImageLink(URL.createObjectURL(e.target.files[0]));
        setImageError("");
        return true;
      } else {
        setImageError(`image width must be 325px and height must be 96px.`);
        return false;
      }
    } else {
      setImageLink(URL.createObjectURL(e.target.files[0]));
      return true;
    }
  };

  return (
    <label
      className={`${FileInputStyle.customFileInput_A_wrap} ${inputClassName} ${
        FileInputStyle.inputWarper
      } ${onError && FileInputStyle.wrongInput}`}
      style={{ backgroundImage: `url(${imageLink || initialImage})` }}
    >
      <span className={`${FileInputStyle.customFileInput_A_span} `}>
        {!imageLink && !initialImage && (
          <span className="material-icons-round">panorama</span>
        )}
        {!imageLink && !initialImage && placeholder}
      </span>
      {(imageLink || initialImage) && (
        <span className={FileInputStyle.actionWarper}>
          <span
            onClick={() => setImageLink("")}
            className={`material-icons-round ${FileInputStyle.clearBtn}`}
          >
            edit
          </span>
        </span>
      )}
      <input
        onChange={(e) => {
          onFileChange(e) && setFieldValue(fieldName, e.currentTarget.files[0]);
        }}
        accept={accept}
        type={type}
        className={`${FileInputStyle.input} ${FileInputStyle.customFileInput_A_input}`}
        name={fieldName}
        {...props}
      />
    </label>
  );
};

export default FileInput;
