import React, { useState } from "react";
import SelectStyle from "css/core/Select.module.css";

const Select = ({
  children,
  className,
  StyleContainer,
  StyleItemWarper,
  StyleItem,
  ...props
}) => {
  const [activeValue, setActiveValue] = useState(children[0]);

  return (
    <div
      {...props}
      className={`${SelectStyle.container} ${className}`}
      style={{ ...StyleContainer, ...props.style }}
    >
      {React.cloneElement(activeValue)}
      <div className={SelectStyle.optionWarper} style={{ ...StyleItemWarper }}>
        {children.map((child, index) => {
          return React.cloneElement(child, {
            onClick: (e) => setActiveValue(children[index]),
            index,
            style: { ...StyleItem },
            key: index,
            className: `${SelectStyle.option} ${className}`,
          });
        })}
      </div>
    </div>
  );
};

export default Select;
