import { Button, InputField } from "core";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import SellCarFormStyle from "css/pages/sellCar/SellCarForm.module.css";

const DimensionsAndCapacityForm = ({
  setPresentStep,
  setFormData,
  formData,
  onNext,
  onBack,
}) => {
  const [sellCareValues, setSellCareValues] = useState({
    length: "",
    width: "",
    height: "",
    seatingCapacity: "",
    groundClearance: "",
    noOfSeatingRows: "",
    fuelTankCapacity: "",
  });
  //step:1
  const sellCarFormFieldList = [
    {
      name: "length",
      fieldName: "length",
      type: "number",
      title: "please enter length",
      placeholder: "Enter length",
    },
    {
      name: "width",
      fieldName: "width",
      type: "number",
      title: "please enter width",
      placeholder: "Enter width",
    },
    {
      name: "height",
      fieldName: "height",
      type: "number",
      title: "please enter height",
      placeholder: "Enter height",
    },
    {
      name: "seating Capacity",
      fieldName: "seatingCapacity",
      type: "select",
      options: ["", 2, 5, 7, 8, 9, 10],
      title: "please enter seating Capacity",
      placeholder: "Enter seating Capacity",
    },
    {
      name: "ground Clearance",
      fieldName: "groundClearance",
      type: "number",
      title: "please enter ground Clearance",
      placeholder: "Enter ground Clearance",
    },
    {
      name: "no Of Seating Rows",
      fieldName: "noOfSeatingRows",
      type: "select",
      options: ["", 1, 2, 3],
      title: "please enter no Of Seating Rows",
      placeholder: "Enter no Of Seating Rows",
    },
    {
      name: "fuel Tank Capacity",
      fieldName: "fuelTankCapacity",
      type: "number",
      title: "please enter fuel Tank Capacity",
      placeholder: "Enter fuel Tank Capacity",
    },
  ];

  const SellCarFormSchema = Yup.object().shape({
    //step:1 car info
    length: Yup.number(),
    width: Yup.number(),
    height: Yup.number(),
    seatingCapacity: Yup.number(),
    groundClearance: Yup.number(),
    noOfSeatingRows: Yup.number(),
    fuelTankCapacity: Yup.number(),
  });

  useEffect(() => {
    if (formData.DimensionsAndCapacityFormData) {
      setSellCareValues(formData.DimensionsAndCapacityFormData);
    }
  }, [formData]);

  const submitForm = async (values) => {
    await setFormData((prev) => ({
      ...prev,
      DimensionsAndCapacityFormData: values,
    }));
    onNext && onNext();
  };
  return (
    <div style={{ width: "100%" }}>
      <Formik
        initialValues={sellCareValues}
        enableReinitialize
        onSubmit={submitForm}
        validationSchema={SellCarFormSchema}
      >
        {({
          touched,
          errors,
          values,
          setTouched,
          handleChange,
          setFieldValue,
        }) => (
          <Form>
            <span className="FRSB">
              <h1 className="STUL">Dimension And Capacity</h1>
            </span>
            <div className={SellCarFormStyle.inputWarper}>
              {sellCarFormFieldList.map((field, index) => {
                return (
                  <Field name={field.fieldName} key={index}>
                    {(props) => (
                      <InputField
                        {...props.field}
                        name={field.name}
                        title={field.title}
                        options={field.options}
                        onError={
                          touched[field.fieldName] && errors[field.fieldName]
                        }
                        type={field.type}
                        Icon={field.Icon}
                        fieldName={field.fieldName}
                        Value={values[field.fieldName]}
                        setFieldValue={setFieldValue}
                        onChange={handleChange}
                        placeholder={field.placeholder}
                        inputClassName={SellCarFormStyle.inputClassName}
                        errorMessage={
                          <ErrorMessage
                            name={field.fieldName}
                            render={(msg) => <p className="EMS">{msg}</p>}
                          />
                        }
                      />
                    )}
                  </Field>
                );
              })}
            </div>
            <div className={SellCarFormStyle.btnWarper}>
              {onBack && (
                <Button
                  type="button"
                  style={{ margin: "1em", backgroundColor: "var(--colorA)" }}
                  onClick={onBack}
                >
                  back
                </Button>
              )}
              <Button
                type="submit"
                style={{ margin: "1em" }}
                LastIcon={
                  <span className="material-icons-round">arrow_forward</span>
                }
              >
                save and continue
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default DimensionsAndCapacityForm;
