import { database } from "config";
import { Button, InputField } from "core";
import NewCarBookFormStyle from "css/components/Signin.module.css";
import { ErrorMessage, Field, Formik } from "formik";
import { useAppContext, useIsMounted, useMail } from "hooks";
import { Fragment, useState } from "react";
import * as Yup from "yup";

const NewCarBookForm = ({ postSubmit, carId }) => {
  const { user, setAlertMessage } = useAppContext();
  const [IsLoading, setIsLoading] = useState(false);
  const { sendMail } = useMail();
  const IsMounted = useIsMounted();

  const initialState = {
    name: user?.name || "",
    address: "",
    phoneNumber: user?.phoneNumber || "",
    email: user?.email || "",
    type: "",
    user: "",

    financeSupport: "",
    financeSupportType: "",
    financePreference: "",

    newCarMake: "",
    newCarModel: "",
    anyOtherMakeOnConsideration: "",
    anyOtherModelOnConsideration: "",

    planTOPurchase: "",

    oldCarExchangePlan: "",
    ageOfVehicle: "",
    oldCarMake: "",
    oldCarModel: "",
    oldCarRegistrationNo: "",
    oldCarPriceExpected: "",
  };

  const fieldList = [
    {
      name: "name",
      fieldName: "name",
      type: "text",
      title: "please enter your name",
      placeholder: "Enter name",
    },
    {
      name: "address",
      fieldName: "address",
      type: "textfield",
      title: "please enter your address",
      placeholder: "Enter address",
    },
    {
      name: "phone Number",
      fieldName: "phoneNumber",
      type: "number",
      title: "please enter your phone Number",
      placeholder: "Enter phone Number",
    },
    {
      name: "email",
      fieldName: "email",
      type: "email",
      title: "please enter your email",
      placeholder: "Enter email",
    },
    {
      name: "type",
      fieldName: "type",
      type: "select",
      options: [
        "",
        "Individual",
        "Corporate",
        "Captive",
        "Leasing",
        "Traveler",
      ],
      title: "please enter your type",
      placeholder: "Enter type",
    },
    {
      name: "user",
      fieldName: "user",
      type: "select",
      options: ["", "Self", "Business"],
      title: "please enter your user",
      placeholder: "Enter user",
    },
    {
      name: "finance Support",
      fieldName: "financeSupport",
      type: "select",
      options: ["", "Yes", "No"],
      title: "please enter finance Support",
      placeholder: "Enter finance Support",
    },
    {
      name: "finance Support Type",
      fieldName: "financeSupportType",
      type: "select",
      options: ["", "Self arrangement", "Support required"],
      title: "Finance Support Type",
      placeholder: "Finance support type",
    },
    {
      name: "Finance Preference",
      fieldName: "financePreference",
      type: "select",
      options: ["", "Nationalized Bank", "Pvt Bank", " NBFC"],
      title: "Please Enter Finance preference",
      placeholder: "Finance preference",
    },
    {
      name: "New Car Make",
      fieldName: "newCarMake",
      type: "text",
      title: "Please Enter New Car Make",
      placeholder: "New car make",
    },
    {
      name: "New Car Model",
      fieldName: "newCarModel",
      type: "text",
      title: "Please Enter New Car Model",
      placeholder: "New car model",
    },
    {
      name: "Any Other Make On Consideration",
      fieldName: "anyOtherMakeOnConsideration",
      type: "text",
      title: "Please Enter Any Other Make On Consideration",
      placeholder: "Make on consideration",
    },
    {
      name: "Any Other Model On Consideration",
      fieldName: "anyOtherModelOnConsideration",
      type: "text",
      title: "Please Enter Any Other Model On Consideration",
      placeholder: "Model on consideration",
    },
    {
      name: "Plan To Purchase",
      fieldName: "planTOPurchase",
      type: "select",
      options: ["", "1 month", "2 month", "3 month"],
      title: "Please Enter Plan To Purchase",
      placeholder: "Enter Plan To Purchase",
    },
    {
      name: "Old Car Exchange Plan",
      fieldName: "oldCarExchangePlan",
      type: "select",
      options: ["", "Yes", "No"],
      title: "Please Enter Old Car Exchange Plan",
      placeholder: "Enter Old Car Exchange Plan",
    },
    {
      name: "Age Of Vehicle",
      fieldName: "ageOfVehicle",
      type: "text",
      title: "Please Enter Age Of Vehicle",
      placeholder: "Enter Age Of Vehicle",
    },
    {
      name: "Old Car Make",
      fieldName: "oldCarMake",
      type: "text",
      title: "Please Enter Old Car Make",
      placeholder: "Enter Old Car Make",
    },
    {
      name: "Old Car Model",
      fieldName: "oldCarModel",
      type: "text",
      title: "Please Enter Old Car Model",
      placeholder: "Enter Old Car Model",
    },
    {
      name: "Old Car Registration Number",
      fieldName: "oldCarRegistrationNo",
      type: "text",
      title: "Please Enter Old Car Registration Number",
      placeholder: "Enter Old Car Registration Number",
    },
    {
      name: "Old Car Price Expected",
      fieldName: "oldCarPriceExpected",
      type: "text",
      title: "Please Enter Old Car Price Expected",
      placeholder: "Enter Old Car Price Expected",
    },
  ];

  const NewCarBookFormSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "name must be more then 51 characters.")
      .max(60, "name must be less then2550 characters.")
      .required("name is required"),
    email: Yup.string()
      .email("please enter a valid email")
      .required("email is required"),
    phoneNumber: Yup.number("phone number must be type of number")
      .test(
        "minlength",
        "phone number must be 10 digits",
        (val) => !val || (val && val.toString().length === 10)
      )
      .required("Phone number is required"),
    address: Yup.string()
      .min(11, "address must be more then 11 characters.")
      .max(250, "address must be less then2550 characters.")
      .required("address is required"),
    type: Yup.string().required("field is required"),
    user: Yup.string().required("field is required"),

    financeSupport: Yup.string().required("Finance Support Field Is Required"),
    financeSupportType: Yup.string().when("financeSupport", {
      is: (val) => val === "yes",
      then: Yup.string().required("Finance Support Type Is Required"),
    }),
    financePreference: Yup.string().when("financeSupport", {
      is: (val) => val === "yes",
      then: Yup.string().required("Finance Preference Is Required"),
    }),

    oldCarExchangePlan: Yup.string().required(
      "Old Car Exchange Plan Field Is Required"
    ),
    ageOfVehicle: Yup.string().when("oldCarExchangePlan", {
      is: (val) => val === "yes",
      then: Yup.string().required("Age Of Vehicle is required"),
    }),
    oldCarMake: Yup.string().when("oldCarExchangePlan", {
      is: (val) => val === "yes",
      then: Yup.string().required("Old Car Mae is required"),
    }),
    oldCarModel: Yup.string().when("oldCarExchangePlan", {
      is: (val) => val === "yes",
      then: Yup.string().required("Old Car Model is required"),
    }),
    oldCarRegistrationNo: Yup.string().when("oldCarExchangePlan", {
      is: (val) => val === "yes",
      then: Yup.string().required("Old Car Registration Number is required"),
    }),
    oldCarPriceExpected: Yup.string().when("oldCarExchangePlan", {
      is: (val) => val === "yes",
      then: Yup.string().required("Old Car Price Expected is required"),
    }),
  });

  const SubmitHandler = async (values, { resetForm }) => {
    IsMounted.current && setIsLoading(true);
    try {
      const ID = Date.now();
      await database.ref(`NewCarEnquire/${ID}`).set({
        ...values,
        id: ID,
        timestamp: Date(),
        enquireBy: user?.uid,
        carId: carId,
      });
      const BookingID = Date.now();
      await database.ref(`Users/${user.uid}/Bookings/${BookingID}`).set({
        carId: carId,
        status: "INFO SEND",
        timestamp: Date(),
        id: BookingID,
      });
      await database.ref(`SpinnyNotification/${ID}`).set({
        id: ID,
        type: "CAR_BOOKED",
        senderData: user,
        timestamp: Date(),
      });
      sendMail({
        to: user.email,
        subject: `Enquire #${ID} Is confirmed`,
        content: `
      your enquire is confirmed to view your enquires visit https://carkarobar.netlify.app/account/booking`,
      });
      IsMounted.current &&
        setAlertMessage({
          type: "info",
          message: "Form submitted successfully",
        });
      postSubmit && postSubmit();
    } catch (error) {
      IsMounted.current && setAlertMessage({ type: "error", message: error });
    } finally {
      IsMounted.current && setIsLoading(false);
    }
  };

  return (
    <div className={NewCarBookFormStyle.container}>
      <Formik
        initialValues={initialState}
        validationSchema={NewCarBookFormSchema}
        onSubmit={SubmitHandler}
      >
        {({
          values,
          errors,
          touched,
          setTouched,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <h1 className={NewCarBookFormStyle.title}>Enter details</h1>
            {fieldList.map((field, index) => (
              <Fragment key={index}>
                {field.fieldName === "financeSupport" && (
                  <h2 className="STUL">About Finance:</h2>
                )}
                {field.fieldName === "newCarMake" && (
                  <h2 className="STUL">About Product preference:</h2>
                )}
                {field.fieldName === "planTOPurchase" && (
                  <h2 className="STUL">Purchase Plan:</h2>
                )}
                {field.fieldName === "oldCarExchangePlan" && (
                  <h2 className="STUL">Exchange:</h2>
                )}

                {field.fieldName === "financeSupportType" ||
                field.fieldName === "financePreference" ? (
                  values.financeSupport === "Yes" && (
                    <Field name={field.fieldName} key={index}>
                      {(props) => (
                        <InputField
                          {...props.field}
                          name={field.name}
                          title={field.title}
                          options={field.options}
                          type={field.type}
                          onError={
                            touched[field.fieldName] && errors[field.fieldName]
                          }
                          fieldName={field.fieldName}
                          Value={values[field.fieldName]}
                          onChange={handleChange}
                          placeholder={field.placeholder}
                          errorMessage={
                            <ErrorMessage
                              name={field.fieldName}
                              render={(msg) => <p className="EMS">{msg}</p>}
                            />
                          }
                        />
                      )}
                    </Field>
                  )
                ) : field.fieldName === "ageOfVehicle" ||
                  field.fieldName === "oldCarMake" ||
                  field.fieldName === "oldCarModel" ||
                  field.fieldName === "oldCarRegistrationNo" ||
                  field.fieldName === "oldCarPriceExpected" ? (
                  values.oldCarExchangePlan === "Yes" && (
                    <Field name={field.fieldName} key={index}>
                      {(props) => (
                        <InputField
                          {...props.field}
                          name={field.name}
                          title={field.title}
                          options={field.options}
                          type={field.type}
                          onError={
                            touched[field.fieldName] && errors[field.fieldName]
                          }
                          fieldName={field.fieldName}
                          Value={values[field.fieldName]}
                          onChange={handleChange}
                          placeholder={field.placeholder}
                          errorMessage={
                            <ErrorMessage
                              name={field.fieldName}
                              render={(msg) => <p className="EMS">{msg}</p>}
                            />
                          }
                        />
                      )}
                    </Field>
                  )
                ) : (
                  <Field name={field.fieldName} key={index}>
                    {(props) => (
                      <InputField
                        {...props.field}
                        key={index}
                        name={field.name}
                        title={field.title}
                        options={field.options}
                        type={field.type}
                        onError={
                          touched[field.fieldName] && errors[field.fieldName]
                        }
                        fieldName={field.fieldName}
                        Value={values[field.fieldName]}
                        onChange={handleChange}
                        placeholder={field.placeholder}
                        errorMessage={
                          <ErrorMessage
                            name={field.fieldName}
                            render={(msg) => <p className="EMS">{msg}</p>}
                          />
                        }
                      />
                    )}
                  </Field>
                )}
              </Fragment>
            ))}
            <Button loading={IsLoading} type="submit">
              Submit
            </Button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default NewCarBookForm;
