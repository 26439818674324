import Button from "core/Button";
import InputField from "core/InputField";
import { ErrorMessage, Field, Formik } from "formik";

import * as Yup from "yup";
import SigninStyle from "css/components/Signin.module.css";
import { Link } from "react-router-dom";
import { auth } from "config";
import { useState } from "react";
import { useAppContext } from "hooks";
import firebase from "firebase";

const SignInForm = ({ onFinish }) => {
  const { setAlertMessage } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [IsResetPassword, setIsResetPassword] = useState(false);

  const SignInValue = {
    email: "",
    password: "",
  };

  const SignInFieldList = [
    {
      name: "email",
      fieldName: "email",
      type: "email",
      title: "please enter your email",
      Icon: <span className="material-icons-round md-dark-light">mail</span>,
      placeholder: "please enter email",
    },
    {
      name: "password",
      fieldName: "password",
      type: "password",
      title: "please enter a password",
      Icon: <span className="material-icons-round md-dark-light">key</span>,
      placeholder: "please enter password",
    },
  ];

  const SignInSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email")
      .required("email is a required field"),
    password: Yup.string()
      .min(5, "password must be minimum 6 letter")
      .when("email", {
        is: (val) => !IsResetPassword,
        then: Yup.string().required("password is a required field"),
      }),
  });

  const SubmitSignInHandler = async (values, { resetForm }) => {
    try {
      if (!IsResetPassword) {
        setIsLoading(true);
        //firebase New User SignIn
        await auth.signInWithEmailAndPassword(values.email, values.password);
        resetForm(SignInValue);
        setAlertMessage({ type: "success", message: "SignIn SuccessFully" });
        resetForm(SignInValue);
        onFinish && onFinish();
      } else {
        firebase
          .auth()
          .sendPasswordResetEmail(values?.email)
          .then(() => {
            setAlertMessage({
              type: "info",
              message: "Reset Password Email Send Successfully.",
            });
          })
          .catch((error) => {
            console.log(error);
            setAlertMessage({ type: "error", message: error.message });
          })
          .finally(() => {
            onFinish && onFinish();
          });
      }
    } catch (error) {
      console.log(error);
      setAlertMessage({ type: "error", message: error.message });
    } finally {
      setIsLoading(false);
    }
  };

  const handelForgotPassword = () => {
    setIsResetPassword(true);
  };

  return (
    <div className={SigninStyle.container}>
      <Formik
        initialValues={SignInValue}
        validationSchema={SignInSchema}
        onSubmit={SubmitSignInHandler}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          setTouched,
          touched,
          errors,
        }) => (
          <form onSubmit={handleSubmit}>
            <h1 className={SigninStyle.title}>
              {IsResetPassword ? "Reset Password" : "SignIn"}
            </h1>
            {SignInFieldList.map((field, index) => {
              return field.fieldName === "password" ? (
                !IsResetPassword && (
                  <Field name={field.fieldName} key={index}>
                    {(props) => (
                      <InputField
                        key={index}
                        name={field.name}
                        title={field.title}
                        onError={
                          touched[field.fieldName] && errors[field.fieldName]
                        }
                        type={field.type}
                        Icon={field.Icon}
                        fieldName={field.fieldName}
                        Value={values[field.fieldName]}
                        onChange={(e) => {
                          handleChange(e);
                          setTouched({ ...touched, [field.fieldName]: true });
                        }}
                        placeholder={field.placeholder}
                        errorMessage={
                          <ErrorMessage
                            name={field.fieldName}
                            render={(msg) => <p className="EMS">{msg}</p>}
                          />
                        }
                        {...props.field}
                      />
                    )}
                  </Field>
                )
              ) : (
                <Field name={field.fieldName} key={index}>
                  {(props) => (
                    <InputField
                      {...props.field}
                      name={field.name}
                      title={field.title}
                      onError={
                        touched[field.fieldName] && errors[field.fieldName]
                      }
                      type={field.type}
                      Icon={field.Icon}
                      fieldName={field.fieldName}
                      Value={values[field.fieldName]}
                      onChange={(e) => {
                        handleChange(e);
                        setTouched({ ...touched, [field.fieldName]: true });
                      }}
                      placeholder={field.placeholder}
                      errorMessage={
                        <ErrorMessage
                          name={field.fieldName}
                          render={(msg) => <p className="EMS">{msg}</p>}
                        />
                      }
                    />
                  )}
                </Field>
              );
            })}
            <Button
              loading={isLoading}
              type="submit"
              style={{
                width: "100%",
                margin: "1.25em 0em",
                backgroundColor: "var(--colorA)",
              }}
            >
              submit
            </Button>
            <span style={{ width: "100%" }}>
              {!IsResetPassword && (
                <Link
                  to="#signup"
                  style={{ float: "right", cursor: "pointer" }}
                >
                  Not an user?
                </Link>
              )}
              {!IsResetPassword && (
                <p onClick={handelForgotPassword} className="CP">
                  Forgot Password?
                </p>
              )}
            </span>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default SignInForm;
