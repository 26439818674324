import BottomShutterStyle from "css/core/BottomShutter.module.css";
import { CSSTransition } from "react-transition-group";
import ReactDOM from "react-dom";

const BottomShutter = ({ children, IsOpen, closeFC }) => {
  return ReactDOM.createPortal(
    <CSSTransition
      in={IsOpen}
      timeout={250}
      unmountOnExit
      classNames={{
        enterActive: BottomShutterStyle.myNodeEnterActive,
        enterDone: BottomShutterStyle.myNodeEnterDone,
        exitActive: BottomShutterStyle.myNodeExitActive,
        exitDone: BottomShutterStyle.myNodeExitDone,
      }}
    >
      <div
        className={BottomShutterStyle.container}
        onClick={(e) =>
          e.target.matches("[data-shutter]") && closeFC && closeFC(false)
        }
        data-shutter
      >
        <div className={BottomShutterStyle.contentWarper}>
          <div className={BottomShutterStyle.content}>{children}</div>
        </div>
      </div>
    </CSSTransition>,
    document.getElementById("root")
  );
};

export default BottomShutter;
