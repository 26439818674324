import { CompareSearchCard } from "components";
import CarSearchSectionStyle from "css/pages/compareCars/CarSearchSection.module.css";
import { useEffect, useState } from "react";
// import { useState } from "react";

const CarSearchSection = ({ data, comparePosition, onSelect }) => {
  const [searchKeyWord, setSearchKeyWord] = useState("");

  const handelSearch = (e) => {
    if (e.target.value.trim()) {
      setSearchKeyWord(e.target.value);
    }
  };
  const [searchedData, setSearchedData] = useState([]);
  useEffect(() => {
    setSearchedData([
      ...data?.filter((item) =>
        `${item?.carDetails?.brand} ${item?.carDetails?.model} ${item?.carDetails?.variant}`
          ?.toUpperCase()
          ?.match(searchKeyWord?.toUpperCase())
      ),
    ]);
  }, [data, searchKeyWord]);

  return (
    <div className={CarSearchSectionStyle.container}>
      <b>
        Select{" "}
        {(comparePosition === "C1" && "Compare 1") ||
          (comparePosition === "C2" && "Compare 2") ||
          (comparePosition === "C3" && "Compare 3") ||
          (comparePosition === "C4" && "Compare 4")}{" "}
        Car
      </b>
      <div className={CarSearchSectionStyle.searchItemWarper}>
        <input
          type="text"
          placeholder="Type To Select Car"
          className={CarSearchSectionStyle.searchInput}
          onChange={handelSearch}
        />
        {/* {console.log(
          data?.filter((item) =>
            item?.CarInformationData?.brand.indexOf(searchKeyWord)
          )
        )} */}
        {/* data
          ?.filter((item) =>
            item?.CarInformationData?.brand.match(searchKeyWord)
          ) */}
        {searchedData?.map((item, index) => (
          <CompareSearchCard
            key={index}
            onSelect={onSelect}
            comparePosition={comparePosition}
            src={
              item?.CarImagesFormData?.leftFrontCornerImage?.url ||
              item?.CarImagesFormData?.leftFrontCornerImage?.uri ||
              item?.evaluateFrontImages?.uri ||
              ""
            }
            name={`${
              item?.CarInformationData?.brand || item?.carDetails?.brand
            } ${item?.CarInformationData?.model || item?.carDetails?.model} ${
              item?.CarInformationData?.variant || item?.carDetails?.variant
            }`}
            id={item?.id}
          />
        ))}
      </div>
    </div>
  );
};

export default CarSearchSection;
