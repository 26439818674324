import UsefulLinkSectionStyle from "css/components/footer/UsefulLinkSection.module.css";
import { Link } from "react-router-dom";

const UsefulLinkSection = () => {
  const links = [
    {
      title: "Company",
      value: [
        { title: "About Us", link: "#" },
        { title: "Join Us", link: "#" },
      ],
    },
    {
      title: "Help",
      value: [
        { title: "Contact Us", link: "#" },
        { title: "Support", link: "/support" },
      ],
    },
    {
      title: "Explore",
      value: [
        { title: "Customer Reviews", link: "#" },
        { title: "Features", link: "#" },
      ],
    },
    {
      title: "Resources",
      value: [
        { title: "Terms & Conditions", link: "#" },
        { title: "Privacy Policy", link: "#" },
      ],
    },
  ];
  return (
    <div className={UsefulLinkSectionStyle.container}>
      {links.map((item, index) => (
        <div key={index} className={UsefulLinkSectionStyle.sectionWarper}>
          <b className="STUL">{item.title}</b>
          <span>
            {item?.value.map((value, index) => (
              <Link
                to={`${value.link}`}
                className={UsefulLinkSectionStyle.links}
                key={index}
              >
                <span
                  className={`material-icons-round ${UsefulLinkSectionStyle.Icon}`}
                >
                  chevron_right
                </span>
                <p>{value.title}</p>
              </Link>
            ))}
          </span>
        </div>
      ))}
    </div>
  );
};

export default UsefulLinkSection;
