import { Base } from "layout";
import InsurancePageStyle from "css/pages/financePage/FinancePage.module.css";
import { InfoGatherForm } from "forms";

const InsurancePage = () => {
  return (
    <Base>
      <div className={InsurancePageStyle.Warper}>
        <div className={InsurancePageStyle.container}>
          <div
            className={InsurancePageStyle.imageSection}
            style={{
              backgroundImage: `url(https://4x4motoring.com/wp-content/uploads/2019/03/mahindra-has-fitted-the-thar-adventure-series-with-accessories-523053-scaled.jpg)`,
            }}
          >
            <h1 className={`${InsurancePageStyle.title}`}>
              Insurance <br /> Enquiry
            </h1>
          </div>
          <div className={InsurancePageStyle.formSection}>
            <div className={InsurancePageStyle.formWarper}>
              <h1 className={`STUL ${InsurancePageStyle.formTitle}`}>
                Insurance Enquiry
              </h1>
              <InfoGatherForm />
            </div>
          </div>
        </div>
      </div>
    </Base>
  );
};

export default InsurancePage;
