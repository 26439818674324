import BrandCardStyle from "css/components/BrandCard.module.css";
import { useImageState, useScrollReveal } from "hooks";
import { useRef } from "react";

const BrandCard = ({ image, name, count, onClick }) => {
  const [IsImageLoading] = useImageState(image);
  const target = useRef();
  const { IsReveal } = useScrollReveal({
    target: target.current,
  });
  return (
    <div
      ref={target}
      className={`${BrandCardStyle.container} ${
        IsReveal ? "reveal-active" : "reveal"
      }`}
      onClick={() => onClick && onClick()}
    >
      {IsImageLoading ? (
        <span className="loaderOneBlue"></span>
      ) : (
        image && <img className={BrandCardStyle.image} src={image} alt={name} />
      )}
      {name && <span className={BrandCardStyle.name}>{name}</span>}
      {count && <p className={BrandCardStyle.count}>{count} cars</p>}
    </div>
  );
};

export default BrandCard;
