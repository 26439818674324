import React from "react";
import AvatarStyle from "css/core/Avatar.module.css";

const Avatar = ({ image, height, width, borderRadios, variant, text }) => {
  switch (variant) {
    case "under-text":
      return (
        <div
          className={`${AvatarStyle.container} ${AvatarStyle.under_text}`}
          style
        >
          <div
            className={AvatarStyle.avatarWarper}
            style={{
              height: height,
              backgroundImage: `url(${image})`,
              width: width,
              borderRadius: borderRadios,
            }}
          ></div>
          {text}
        </div>
      );
    case "right-text":
      return (
        <div className={`${AvatarStyle.container} ${AvatarStyle.right_text}`}>
          <div
            className={AvatarStyle.avatarWarper}
            style={{
              height: height,
              width: width,
              backgroundImage: `url(${image})`,
              borderRadius: borderRadios,
            }}
          ></div>
          {text}
        </div>
      );
    default:
      return (
        <div
          className={AvatarStyle.avatarWarper}
          style={{
            height: height,
            width: width,
            backgroundImage: `url(${image})`,
            borderRadius: borderRadios,
          }}
        ></div>
      );
  }
};

export default Avatar;
