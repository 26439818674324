import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import { database } from "config";
import { BottomShutter } from "core";
import { QueriesForm } from "forms";
import { useEffect, useState } from "react";
import BookingsStyle from "css/pages/admin/bookings/Bookings.module.css";
import { useIsMounted } from "hooks";

const Support = () => {
  const [SupportQueries, setSupportQueries] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const IsMounted = useIsMounted();

  useEffect(() => {
    (async () => {
      try {
        await database.ref("SupportQueries").once("value", async (snapshot) => {
          const data = await snapshot.val();
          if (data) {
            const allBookings = Object.entries(data).map((item) => item[1]);
            IsMounted.current && setSupportQueries(allBookings);
          }
        });
      } catch (error) {
        console.log(error);
      } finally {
        IsMounted.current && setIsLoading(false);
      }
    })();
  }, [IsMounted]);

  const [ReceiverId, setReceiverId] = useState(false);

  return (
    <div style={{ width: "100%" }}>
      <MaterialTable
        isLoading={isLoading}
        data={SupportQueries.sort(
          (a, b) => new Date(b?.timestamp) - new Date(a?.timestamp)
        ).map((item, index) => ({
          ...item,
          sl_no: index + 1,
        }))}
        columns={[
          { title: "Sl No", field: "sl_no" },
          { title: "Name", field: "name" },
          { title: "Email", field: "email" },
          {
            title: "Date",
            field: "timestamp",
            render: (rowData) => (
              <p>{new Date(rowData.timestamp).toLocaleString()}</p>
            ),
            editable: "never",
          },
        ]}
        detailPanel={({ rowData }) => {
          return (
            <div className="boxContainer">
              <b className="boxContainerTitle">Query: </b>
              <p className={BookingsStyle.address}>{rowData.query}</p>
            </div>
          );
        }}
        title="Support Queries"
        actions={[
          {
            icon: "send",
            tooltip: " Send Notification",
            onClick: (event, rowData) => setReceiverId(rowData.sendBy),
          },
        ]}
        options={{
          actionsColumnIndex: -1,
          exportAllData: true,
          exportMenu: [
            {
              label: "Export PDF",
              exportFunc: (cols, datas) =>
                ExportPdf(cols, datas, `All Support Queries - ${new Date()}`),
            },
            {
              label: "Export CSV",
              exportFunc: (cols, datas) =>
                ExportCsv(cols, datas, `All Support Queries - ${new Date()}`),
            },
          ],
        }}
      />
      <BottomShutter
        IsOpen={!!ReceiverId}
        closeFC={() => setReceiverId(false)}
        info={ReceiverId}
      >
        <QueriesForm
          title="Support Support"
          Id={ReceiverId}
          postSubmit={() => setReceiverId(false)}
        />
      </BottomShutter>
    </div>
  );
};

export default Support;
